import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import _ from "lodash";
import moment from 'moment';
import { LoadingPurple } from "components/Loading";
import { Section } from '../styles';
import { PrimaryTitle, HR } from "components/new/Title";
import ProductService, { IProduct, IProductPack } from 'services/ProductService';

interface ClientParams {
	clientId?: string;
}

const ClientProducts: React.FC<{}> = ({}) => {
	const { clientId } = useParams<ClientParams>();
	const [packs, setPacks] = useState<IProductPack[]>([]);
	const [loading, setLoading] = useState(false)

	const getData = useCallback(async () => {
		try {
			setLoading(true)
			const packs = await ProductService.getAllPacks(clientId);
			setPacks(packs);
			setLoading(false)
		} catch {
			Swal.fire('Erro ao obter dados', '', 'error')
		}
		
	}, [clientId]);

	useEffect(() => {
		getData()
	}, [getData])

	const getContractValue = useCallback((pack: IProductPack) => {
		if (!pack.products.length) return 0;
		return pack.products.reduce((a, current) => a + ((current.quantity * current.value) || 0), 0)
	}, []);

	const onSubmit = useCallback(
        async (packId, productId) => {
          try {

						//@ts-ignore
						const dateElem = document.getElementById(`date.${packId}.${productId}`)
						const date = moment(dateElem.value).format("DD/MM/YYYY");

						Swal.fire({
							title: 'Salvando',
							text: 'Em progresso...',
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading()
							}
						})

						await ProductService.addFinished(packId, productId, date)
						getData()
						await Swal.fire({
							title: 'Dados salvos com sucesso',
							icon: 'success',
							didOpen: () => {
								Swal.hideLoading()
							}
						});

          } catch (errors) {
            Swal.fire('Erro ao salvar', '', 'error')
          }
        },
        [getData]
	);

	const onDelete = useCallback(
        async (packId) => {
          try {

			const result = await Swal.fire({
				title: "Deseja realmente excluir?",
				text: "Você não poderá desfazer esta ação!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "var(--success)",
				cancelButtonColor: "var(--danger)",
				confirmButtonText: "Sim, excluir!",
				cancelButtonText: "Desistir",
			});

			if (!result.isConfirmed) {
				return;
			}

      Swal.fire({
				title: 'Excluindo',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			})

			await ProductService.deletePack(packId)
			getData()
            await Swal.fire({
				title: 'Pacote excluído com sucesso',
				icon: 'success',
				didOpen: () => {
					Swal.hideLoading()
				}
			});

          } catch (errors) {
            Swal.fire('Erro ao excluir', '', 'error')
          }
        },
        [getData]
	);

	const onDeleteDate = useCallback(
        async (packId, productId, dateId) => {
          try {

			const result = await Swal.fire({
				title: "Deseja realmente excluir?",
				text: "Você não poderá desfazer esta ação!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "var(--success)",
				cancelButtonColor: "var(--danger)",
				confirmButtonText: "Sim, excluir!",
				cancelButtonText: "Desistir",
			});

			if (!result.isConfirmed) {
				return;
			}

            Swal.fire({
				title: 'Excluindo',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			})

			await ProductService.removeFinished(packId, productId, dateId)

			getData()
			
            await Swal.fire({
				title: 'Data excluída com sucesso',
				icon: 'success',
				didOpen: () => {
					Swal.hideLoading()
				}
			});

          } catch (errors) {
            Swal.fire('Erro ao excluir', '', 'error')
          }
        },
        [getData]
	);

	if (loading) {
		return (
		  <div
			style={{
			  display: "flex",
			  justifyContent: "center",
			}}
		  >
			<LoadingPurple style={{ height: 100 }} />
		  </div>
		);
	  }

	return (
		<div>
			<Section>
				<PrimaryTitle>Produtos contratados</PrimaryTitle>
				<HR />

				<div className='grid grid-flow-row gap-3.5'>
					{packs.map((pack) => (
						<div key={pack._id} className='rounded bg-gray-100 p-4'>
							<p className='font-bold text-2xl text-gray-500'>{moment(pack.createdAt).format("DD/MM/YYYY [às] HH:mm")}</p>
							<p className='font-bold text-sm text-gray-400'>Valor do contrato: {getContractValue(pack).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
							{pack.paymentMethod && <p className='font-bold text-sm text-gray-400'>Método de pagamento: {pack.paymentMethod}</p>}
							{pack.observations && <p className='font-bold text-sm text-gray-400'>Observações: {pack.observations}</p>}
							<ul className='list list-disc text-xl pl-4 mt-8'>
								{pack.products.map((product) => {

									const productUses = (product.uses ?? []).filter((p) => p.type  === product.product.type)
									const [ lastUse ] = productUses.reverse()
									productUses.reverse()

									return (
										<li>
											{product.product.title}:&nbsp;
											<div className='grid grid-cols-5 gap-3 text-gray-400 text-sm mb-2 relative'>
												<div className='col-span-1'>
													Em atendimento por: <br /><strong>{lastUse?.byName ?? "-"}</strong>
												</div>
												<div className='col-span-1'>
													Quantidade: {product.quantity}
												</div>
												<div className='col-span-1'>
													Valor unitário:<br />{product.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
												</div>
												<div className='col-span-2 text-purple-900 cursor-pointer peer'>
													Utilizações: {productUses.length || 0}
													<div className="flex justify-between mb-1">
														<span className="text-sm font-medium">Progresso de utilização</span>
														<span className="text-sm font-medium">{Math.min(((productUses.length || 0) / product.quantity * 100), 100).toFixed(0)}%</span>
													</div>
													<div className="w-full bg-gray-200 rounded-full h-2.5">
														<div className="bg-purple-900 h-2.5 rounded-full" style={{width: `${Math.min(((productUses.length || 0) / product.quantity * 100), 100)}%`}}></div>
													</div>
												</div>
												<div className='absolute text-purple-900 right-0 top-0 w-52 rounded p-2 hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-50 overflow-y-scroll'>
													Utilizações do produto
	
													<ul className='text-gray-400 p-4 list-decimal w-full'>
														{productUses.map((use, index) => (
															<li key={index} className="list-item w-full">
																{`${moment(use.date).format("DD/MM/YYYY [às] HH:mm")} por ${use.byName}`}
															</li>
														))}
													</ul>
	
													{!productUses.length && <p className='text-gray-400 py-4'>Nenhuma Utilização registrada</p>}
												</div>
											</div>
										</li>
									)
								})}
							</ul>

							<div className='col-span-1 flex items-end'>
								<button
									type="button"
									className="text-gray-400 hover:bg-red-400 hover:text-white p-4 py-3 flex items-center rounded transition-all"
									onClick={() => { onDelete(pack._id)}}
									>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-4">
										<path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
									</svg>
									Excluir pacote contratado
								</button>
							</div>
						</div>
					))}
				</div>
				
				{_.isEmpty(packs) && <p className='font-bold text-2xl mb-4 text-gray-400'>Nenhum produto contratado pelo cliente</p>}
			</Section>
		</div>
	);
}

const Index: React.FC<{}> = ({}) => {
	const history = useHistory();
	const { clientId } = useParams<ClientParams>();

	return (
		<div className='p-8'>
			<ClientProducts />
			<button onClick={() => history.push(`/client/products/${clientId}/new`)} className="w-full transition-all text-white bg-[#33b6ae] hover:bg-[#1f6d68] font-semibold py-2 px-4 rounded" >
				Adicionar novo contrato
			</button>
		</div>
	);
}

export default Index;