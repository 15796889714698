import React, { useCallback, useContext, useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { selectUser } from "redux/ducks/Auth/selectors";
import {
  FormProvider,
  useForm,
  useWatch,
  Control,
  UseFormSetValue,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useHistory } from "react-router-dom";
import { LoadingOrange } from "../../../components/Loading";
import { NumberInput } from "../../../components/3.0";
import { TextInput, TextArea, Select } from "atoms";
import { TechTitle, HR } from "../../../components/new/Title";
import SaveBottom from "../../../components/SaveBottom";
import { Section } from "./styles";
import MuscleSVG from "../../../assets/images/muscles.svg";
import ClientContext from "../../../context/ClientContext";
import AnamnesisService from "../../../services/AnamnesisService";
import _ from "lodash";
import FileUploads from "./fileUploads";
import FileCenter from "components/3.0/FileCenter";
import TechSchema, { ITech } from "./schema";

interface MethodsProps {
  control: Control<ITech>;
  setValue: UseFormSetValue<ITech>;
}

const ConsultationDate: React.FC<MethodsProps> = (props) => {
  const { downloadReport } = useContext(ClientContext);

  return (
    <Section>
      <TechTitle>Consulta atual</TechTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12">
        <div className="col-span-8">
          <TextInput
            name="consultationDate"
            label="Data da Consulta"
            type="date"
          />
        </div>
        <div className="col-span-8 flex">
          <button
            className="flex transition-all text-white bg-[#EA5448] hover:bg-[#EA9999] border-none outline-none border font-medium rounded-lg text-sm text-center mr-2 mb-2 p-2"
            type="button"
            onClick={(e) => {
              downloadReport();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            Baixar laudo
          </button>

          <button
            className="flex transition-all text-white bg-[#EA5448] hover:bg-[#EA9999] border-none outline-none border font-medium rounded-lg text-sm text-center mr-2 mb-2 p-2"
            type="button"
            onClick={(e) => {
              downloadReport("reportTemplate");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            Baixar COM Scanner
          </button>

          <button
            className="flex transition-all text-white bg-[#EA5448] hover:bg-[#EA9999] border-none outline-none border font-medium rounded-lg text-sm text-center mr-2 mb-2 p-2"
            type="button"
            onClick={(e) => {
              downloadReport("reportTemplateMin");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            Baixar SEM Scanner
          </button>
        </div>
      </div>
    </Section>
  );
};

const Antropometria: React.FC<MethodsProps> = (props) => {
  const user = useSelector(selectUser);
  const { control, setValue } = props;
  const height = useWatch({
    control,
    name: "bodyComposition.height",
  });
  const weight = useWatch({
    control,
    name: "bodyComposition.weight",
  });
  const muscleMass = useWatch({
    control,
    name: "bodyComposition.muscleMass",
  });
  const muscleMassIndex = useWatch({
    control,
    name: "bodyComposition.muscleMassIndex",
  });
  const imc = useWatch({
    control,
    name: "bodyComposition.imc",
  });

  useEffect(() => {
    if (height && weight) {
      setValue("bodyComposition.imc", weight / (height * height));
    }
  }, [height, weight, setValue]);

  useEffect(() => {
    if (height && muscleMass) {
      setValue(
        "bodyComposition.muscleMassIndex",
        muscleMass / (height * height)
      );
    }
  }, [height, muscleMass, setValue]);

  return (
    <Section>
      <TechTitle>
        Antropometria e Avaliação da Composição Corporal e da Força Muscular
      </TechTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
        <div className="col-span-2">
          <NumberInput
            name="bodyComposition.bloodPressure"
            label="Pressão Arterial"
            format="###/### mmHg"
            suffix=" mmHg"
          />
        </div>
        <div className="col-span-2">
          <NumberInput
            name="bodyComposition.handGrip"
            label="Hand Grip"
            suffix=" kg"
          />
        </div>
        <div className="col-span-2">
          <NumberInput
            label="Altura"
            suffix=" m"
            name="bodyComposition.height"
          />
        </div>
        <div className="col-span-2">
          <NumberInput
            label="Peso"
            suffix=" kg"
            name="bodyComposition.weight"
          />
        </div>
        <div className="col-span-3">
          <NumberInput
            name="bodyComposition.visceralFatLevel"
            label="Nível de Gordura Visceral"
          />
          <Select
            name="bodyComposition.references.visceralFatLevel"
            label="Classificação da Gordura Visceral"
            defaultValue={"Adequado"}
            options={[
              { label: "Adequado", value: "Adequado" },
              { label: "Risco médio", value: "Risco médio" },
              { label: "Risco elevado", value: "Risco elevado" },
            ]}
            compact
          />
        </div>
        <div className="col-span-3">
          <NumberInput
            name="bodyComposition.basalMetabolicRate"
            label="Taxa Metabólica"
            suffix=" kcal/dia"
          />
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.basalMetabolicRate.min"
                label="Min"
                suffix=" kcal/dia"
                compact
              />
            </div>
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.basalMetabolicRate.max"
                label="Máx"
                suffix=" kcal/dia"
                compact
              />
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <p>
            <strong>
              IMC{" "}
              {Intl.NumberFormat("pt-BR", {
                maximumFractionDigits: 1,
              }).format(imc)}
            </strong>
          </p>
        </div>
        <div className="col-span-4">
          <NumberInput
            name="bodyComposition.fatMass"
            label="Massa de Gordura"
            suffix=" kg"
          />
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.fatMass.min"
                label="Min"
                suffix=" kg"
                compact
              />
            </div>
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.fatMass.max"
                label="Máx"
                suffix=" kg"
                compact
              />
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <NumberInput
            name="bodyComposition.bodyWater"
            label="Água Corporal Total"
            suffix=" litros"
          />
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.bodyWater.min"
                label="Min"
                suffix=" litros"
                compact
              />
            </div>
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.bodyWater.max"
                label="Máx"
                suffix=" litros"
                compact
              />
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <NumberInput
            name="bodyComposition.freeMass"
            label="Massa Livre de Gordura"
            suffix=" kg"
          />
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.freeMass.min"
                label="Min"
                suffix=" kg"
                compact
              />
            </div>
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.freeMass.max"
                label="Máx"
                suffix=" kg"
                compact
              />
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <NumberInput
            name="bodyComposition.bodyFatPercentage"
            label="% de Gordura"
            suffix=" %"
          />
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.bodyFatPercentage.min"
                label="Min"
                suffix=" %"
                compact
              />
            </div>
            <div className="col-span-4">
              <NumberInput
                name="bodyComposition.references.bodyFatPercentage.max"
                label="Máx"
                suffix=" %"
                compact
              />
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <NumberInput
            name="bodyComposition.muscleMass"
            label="Massa Muscular Esquelética"
            suffix=" kg"
          />
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
            <div className="col-span-8">
              <Select
                name="bodyComposition.references.muscleMass"
                label="Classificação"
                defaultValue={"Abaixo"}
                options={[
                  { label: "Abaixo", value: "Abaixo" },
                  { label: "Adequado", value: "Adequado" },
                ]}
                compact
              />
            </div>
          </div>
        </div>
        <div className="col-span-5" />
        {muscleMassIndex && (
          <div className="col-span-8">
            <p>
              IMME{" "}
              {Intl.NumberFormat("pt-BR", {
                maximumFractionDigits: 1,
              }).format(muscleMassIndex)}
            </p>
          </div>
        )}
      </div>

      <Tabs defaultValue="leanMass" className="w-full pt-12 mb-4">
        <TabsList>
          <TabsTrigger value="leanMass">Análise de Massa Magra</TabsTrigger>
          <TabsTrigger value="fatMass">
            Análise de Gordura Segmentar
          </TabsTrigger>
          <TabsTrigger value="circunferences">Circunferências</TabsTrigger>
        </TabsList>
        <TabsContent value="leanMass">
          <div className="grid grid-rows-1 grid-cols-2 grid-flow-row-dense gap-8 pt-12">
            <p
              className="col-span-2"
              style={{
                color: "var(--tech)",
                textTransform: "uppercase",
                marginBottom: -40,
              }}
            >
              <strong>Análise de Massa Magra:</strong>{" "}
            </p>
            <div
              className="col-span-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../../assets/images/massa-magra-segmentar.png")}
                style={{ height: 300 }}
              />
            </div>
            <div className="col-span-1 grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalMuscleMass.upperLeft"
                  label="Esquerda Superior"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalMuscleMass.upperRight"
                  label="Direita Superior"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-2" />
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalMuscleMass.center"
                  label="Centro"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-2" />
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalMuscleMass.bottomLeft"
                  label="Esquerda Inferior"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalMuscleMass.bottomRight"
                  label="Direita Inferior"
                />
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="fatMass">
          <div className="grid grid-rows-1 grid-cols-2 grid-flow-row-dense gap-8 pt-12">
            <p
              className="col-span-2"
              style={{
                color: "var(--tech)",
                textTransform: "uppercase",
                marginBottom: -40,
              }}
            >
              <strong>Análise de Gordura Segmentar:</strong>{" "}
            </p>
            <div
              className="col-span-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../../assets/images/massa-magra-segmentar.png")}
                style={{ height: 300 }}
              />
            </div>
            <div className="col-span-1 grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalFatMass.upperLeft"
                  label="Esquerda Superior"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalFatMass.upperRight"
                  label="Direita Superior"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-2" />
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalFatMass.center"
                  label="Centro"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-2" />
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalFatMass.bottomLeft"
                  label="Esquerda Inferior"
                  placeholder={"0,00Kg\n000,0%\nNormal"}
                />
              </div>
              <div className="col-span-4">
                <TextArea
                  name="bodyComposition.references.segmentalFatMass.bottomRight"
                  label="Direita Inferior"
                />
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="circunferences">
          <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pt-12">
            <div className="col-span-2 space-y-5">
              <label className="font-bold uppercase text-gray-700">
                Circunferências dos Braços
              </label>
              <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
                <div className="col-span-4">
                  <NumberInput
                    name="bodyComposition.bicepLeft"
                    label="Esquerdo"
                    suffix=" cm"
                    compact
                  />
                </div>
                <div className="col-span-4">
                  <NumberInput
                    name="bodyComposition.bicepRight"
                    label="Direito"
                    suffix=" cm"
                    compact
                  />
                </div>
              </div>
              <div style={{ marginTop: 80 }}>
                <label className="font-bold uppercase text-gray-700">
                  Circunferências das Coxas
                </label>
              </div>
              <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
                <div className="col-span-4">
                  <NumberInput
                    name="bodyComposition.midThighLeft"
                    label="Esquerda"
                    suffix=" cm"
                    compact
                  />
                </div>
                <div className="col-span-4">
                  <NumberInput
                    name="bodyComposition.midThighRight"
                    label="Direita"
                    suffix=" cm"
                    compact
                  />
                </div>
              </div>

              <div style={{ marginTop: 80 }}>
                <label className="font-bold uppercase text-gray-700">
                  Circunferências das Panturrilhas
                </label>
              </div>
              <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
                <div className="col-span-4">
                  <NumberInput
                    name="bodyComposition.calfLeft"
                    label="Esquerda"
                    suffix=" cm"
                    compact
                  />
                </div>
                <div className="col-span-4">
                  <NumberInput
                    name="bodyComposition.calfRight"
                    label="Direita"
                    suffix=" cm"
                    compact
                  />
                </div>
              </div>
            </div>

            <div className="col-span-3">
              <img src={MuscleSVG} alt="Muscles" className="musclesImg" />
            </div>

            <div className="col-span-2 space-y-5">
              <NumberInput
                name="bodyComposition.torax"
                label="Circunferência Torácica"
                suffix=" cm"
              />
              <div style={{ marginTop: 80 }}>
                <label className="font-bold uppercase text-gray-700">
                  Circunferência Abdominal
                </label>
              </div>
              <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
                {!user.profileType.includes("Medical") && (
                  <div className="col-span-4">
                    <NumberInput
                      name="bodyComposition.waistAbdominalStyku"
                      label="Scanner"
                      suffix=" cm"
                      compact
                    />
                  </div>
                )}
                <div className="col-span-4">
                  <NumberInput
                    name="bodyComposition.waistAbdominal"
                    label="Manual"
                    suffix=" cm"
                    compact
                  />
                </div>
              </div>
              <div style={{ marginTop: 80 }}>
                <NumberInput
                  name="bodyComposition.hip"
                  label="Circunferência do Quadril"
                  suffix=" cm"
                />
              </div>
            </div>

            <div className="col-span-8" style={{ marginTop: 80 }}>
              <TextArea
                name="bodyComposition.circumferencesObservations"
                label="Observações sobre as circunferências"
                placeholder="Digite aqui"
              />
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </Section>
  );
};

const Observations: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <TechTitle>Observações</TechTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
        <div className="col-span-8">
          <TextArea name="observations" label="Observações" />
        </div>
        <div className="col-span-8">
          <TextArea
            name="hauxApproach"
            label="Como você gostaria de ser abordado caso a equipe perceba que você não está seguindo o programa?"
          />
        </div>
      </div>
    </Section>
  );
};

const Metas: React.FC<MethodsProps> = (props) => {
  return (
    <>
      <Section>
        <TechTitle>Metas</TechTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className="col-span-2">
            <NumberInput
              name="goals.weight"
              label="Meta de Peso"
              suffix=" kg"
            />
          </div>
          <div className="col-span-3">
            <TextInput
              name="goals.goalDate"
              label="Data para atingir"
              type="date"
            />
          </div>
          <div className="col-span-3">
            <Select
              name="goals.caloriesRange"
              options={[
                [1000, 1200],
                [1300, 1500],
                [1600, 1800],
                [1900, 2100],
                [2200, 2400],
                [2500, 2700],
                [2800, 3000],
                [3100, 3300],
                [3400, 3600],
                [3700, 3900],
              ].map(([min, max]) => ({
                label: `${min}-${max} Kcal`,
                value: `${min},${max}`,
              }))}
              label="Consumo calórico"
            />
          </div>
          <div className="col-span-8">
            <TextArea name="goals.observations" label="Observações das metas" />
          </div>
        </div>
      </Section>
    </>
  );
};

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
}

const Anamnesis: React.FC = () => {
  const methods = useForm<ITech>({ resolver: yupResolver(TechSchema) });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { reset, control, handleSubmit, formState, register } = methods;
  const data = useWatch({ control });
  const finished = useWatch({ control, name: "finish" });
  const createdAt = useWatch({ control, name: "createdAt" });
  const history = useHistory();
  const { clientId, anamnesisId } = useParams<ClientParams>();
  const { client } = useContext(ClientContext);

  const getAnamnesisData = useCallback(async () => {
    if (anamnesisId) {
      setLoading(true);
      try {
        const response = await AnamnesisService.getById("tech", anamnesisId);
        const item = _.get(response, ["data", "data"]);
        if (item) {
          reset({ ...item, client: clientId });
        } else {
          throw new Error();
        }
      } catch (e) {
        NotificationManager.error("Erro ao obter dados da anamnese");
        history.push(`/client/${clientId}/tech`);
      }
      setLoading(false);
    }
  }, [clientId, anamnesisId, reset, setLoading, history]);

  useEffect(() => {
    getAnamnesisData();
  }, [getAnamnesisData]);

  const onSave = useCallback(
    async (data, published = false) => {
      setSaving(true);
      try {
        const values = { ...data, finish: published };
        await AnamnesisService.updateAnamnesis("tech", anamnesisId, values);
        NotificationManager.success(
          `Ficha ${published ? "salva" : "publicada"} com sucesso`
        );
      } catch (e) {
        NotificationManager.error("Erro ao salvar ficha");
      }
      setSaving(false);
    },
    [setSaving, anamnesisId]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await onSave(data, true);
        getAnamnesisData();
      } catch (errors) {
        NotificationManager.error("Erro inesperado");
      }
    },
    [onSave, getAnamnesisData]
  );

  const onError = () => {
    NotificationManager.error(
      "Preencha todos os campos obrigatórios para publicar"
    );

    setTimeout(() => {
      //@ts-ignore
      const [firstError]: [HTMLElement] = document.getElementsByClassName(
        "field-error"
      );
      //@ts-ignore
      firstError.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 1000);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingOrange style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ paddingBottom: 50 }}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {anamnesisId && (
          <>
            <ConsultationDate {...methods} />
            <Antropometria {...methods} />
            <FileUploads franchise={client.franchise} />
            <FileCenter
              clientId={clientId}
              color={"var(--tech)"}
              title="Arquivos da ficha Tech"
              preffix={`tech/${(createdAt ?? "")}`}
            />
            <Metas {...methods} />
            <Observations {...methods} />
          </>
        )}
        <SaveBottom
          saving={saving}
          touched={formState.isDirty || !finished}
          showPublish={!finished}
          color={"tech"}
          onSave={() => {
            onSave(data, data.finish);
          }}
          published={finished}
        />
      </form>
    </FormProvider>
  );
};

export default Anamnesis;
