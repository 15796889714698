import React, { useCallback, useEffect, useState, useRef, useContext } from "react";
import {
    FormProvider,
    useForm,
  } from "react-hook-form";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import EmojiPicker, { EmojiStyle, Categories }from 'emoji-picker-react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/ducks/Auth/selectors";
import { NotificationManager } from "react-notifications";
import { Tab } from '@headlessui/react'
import Swal from "sweetalert2";
import { TextArea } from "atoms";
import { Modal } from "../../components/3.0";
import moment from "moment";
import DiaryService from '../../services/Diary';
import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface ClientParams {
    clientId?: string;
}

interface IComment {
    _id: string;
    author: string;
    client: string;
    user: string;
    createdAt: string;
    message: string;
    updatedAt: string;
}

export interface IMeal {
    _id: string;
    client: string;
    createdAt: string;
    date: string;
    description: string;
    hour: string;
    image: string;
    month: string;
    title: string;
    updatedAt: string;
    comments: IComment[];
}

export interface IDiaryComments {
    meal?: IMeal;
    title?: string;
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
    onSave?: Function;
}

export const DiaryComments:React.FC<IDiaryComments> = ({
    meal,
    title,
    onClose,
    onSave
}) => {
    const user = useSelector(selectUser);
    const methods = useForm();
    const { reset, handleSubmit } = methods;
    const [saving, setSaving] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);


    const deleteComment = useCallback(async (id) => {
        Swal.fire({
            title: "Deseja realmente excluir?",
            text: "Você não poderá desfazer esta ação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "var(--success)",
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Desistir",
            preConfirm: async () =>  {
                Swal.showLoading();
                try {
                    await DiaryService.deleteComment(meal?._id, id)
                    onSave?.()
                } catch (e) {
                    NotificationManager.error("Erro ao deletar comentário");
                }
                Swal.hideLoading();
            },
          });
    }, [meal, onSave])

    const onSubmit = useCallback(
        async (values) => {
            setSaving(true)
            try {
                const data = { ...values };
                await DiaryService.addComment(meal?._id, data);
                reset({message: ''});
                await onSave?.()
                if (null !== scrollRef.current) {
                    //@ts-ignore
                    scrollRef.current.scrollTop = 0;
                }
            } catch (errors) {
                NotificationManager.error("Erro ao salvar comentário");
            }
            setSaving(false)
        },
        [meal, setSaving, reset, onSave]
      );

    return (
        <Modal
          isOpen={meal !== undefined}
          setIsOpen={() => {}}
          title={title || `${moment(meal?.date).format("DD/MM/YYYY")} ${meal?.hour} - ${meal?.title}`}
          footer={
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className="flex w-full items-center">
                    <TextArea name="message" placeholder="Adicione seu comentário..."/>
                    <button
                        className="w-full h-min inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        type="button"
                        onClick={() => handleSubmit(onSubmit)()}
                    >
                        {saving &&
                        <svg role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="purple"/>
                        </svg>}
                        Comentar
                    </button>
                    <Popover>
                        <PopoverTrigger className="w-full h-min inline-flex justify-center items-center mr-2 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        💜 Reagir
                        </PopoverTrigger>
                        <PopoverContent className="bg-transparent border-none shadow-none">
                            <EmojiPicker
                                emojiStyle={EmojiStyle.GOOGLE}
                                skinTonesDisabled={true}
                                onEmojiClick={({ emoji }) => onSubmit({ message: emoji})}
                                categories={[
                                    {
                                        category: Categories.SUGGESTED,
                                        name: 'Usado recentemente'
                                    },
                                    {
                                        category: Categories.SMILEYS_PEOPLE,
                                        name: 'Smileys e Rostos'
                                    },
                                    {
                                        category: Categories.FOOD_DRINK,
                                        name: 'Comidas e Bebidas'
                                    },
                                    {
                                        category: Categories.ANIMALS_NATURE,
                                        name: 'Animais e natureza'
                                    },
                                    {
                                        category: Categories.ACTIVITIES,
                                        name: 'Atividades'
                                    }
                                ]}
                                searchPlaceholder="Pesquisar" />
                        </PopoverContent>
                    </Popover>
                    <button
                        className="w-full h-min inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        type="button"
                        onClick={onClose}
                    >
                        Fechar
                    </button>
                    
                </form>
            </FormProvider>
        }
        >
          <div className='flex flex-col overflow-scroll max-h-96 scroll-smooth' ref={scrollRef}>
            <div className="grid grid-cols-2 gap gap-2">
                <div className="break-words whitespace-normal text-sm font-medium">
                    {meal?.description}
                </div>
                <div className="flex flex-col">
                    {meal?.image && <img
                        src={meal?.image}
                        alt="Imagem"
                        className="max-h-44 w-fit self-center rounded-lg cursor-zoom-in"
                        onClick={() => {
                            //@ts-ignore
                            var image = new Image();
                            image.src = meal?.image;
                        
                            //@ts-ignore
                            var w = window.open("",'_blank');
                            w.document.write(image.outerHTML);
                            w.document.close(); 
                        }}
                    />}
                </div>
            </div>
            <ul className="flex flex-col mt-8">
                {_.orderBy(meal?.comments, ['updatedAt'], ['desc']).map((comment) => (
                <li
                    key={comment._id}
                    className={`
                        max-w-xs relative rounded-md p-3 bg-gray-100 w-fit mb-2
                        ${(comment.client || comment.user) === user._id ? 'self-end bg-purple-900 text-white' : ''}
                    `}
                >
                    <h3 className="text-sm font-medium leading-5">
                        {comment.message}
                    </h3>

                    <ul className={`
                        mt-1 flex space-x-1 text-xs font-normal leading-4 items-center
                        ${(comment.client || comment.user) === user._id ? 'text-gray-300' : 'text-gray-500'}
                        `}>
                        <li>{comment.author}</li>
                        <li>&middot;</li>
                        <li>{moment(comment.updatedAt).format("DD/MM/YYYY HH:mm")}</li>
                        {(comment.client || comment.user) === user._id && (
                        <li>
                            <button
                                className="text-red-400 text-xs ml-4"
                                type="button"
                                onClick={() => deleteComment(comment._id)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </button>
                        </li>
                    )}
                    </ul>

                    
                </li>
                ))}
            </ul>
          </div>
        </Modal>
    );
}

const Diary: React.FC = () => {
    const { clientId } = useParams<ClientParams>();
    const [loading, setLoading] = useState(false)
    const [meals, setMeals] = useState<IMeal[]>([])
    const [selectedMeal, setSelectedMeal] = useState<IMeal | undefined>()

    const getMeals = useCallback(async () => {
        setLoading(true)
        try {
            const response = await DiaryService.getMeals(clientId)
            const data = _.get(response, ["data", "data"]);
            var newMeals: IMeal[] = [];
            data.forEach(({ month, days}) => {
                days.forEach(({ meals }) => {
                    newMeals = newMeals.concat(meals.map((m) => ({...m, month: month.toLowerCase()})))
                });
            });
            setMeals(newMeals)
        } catch(e) {
            NotificationManager.error("Erro ao obter diário alimentar");
        }
        setLoading(false)
    }, [setMeals, setSelectedMeal, setLoading])

    useEffect(() => {
        setSelectedMeal(_.first(_.filter(meals, {_id: selectedMeal?._id})) || selectedMeal)
    }, [meals, setSelectedMeal])

    useEffect(() => {
        getMeals()
    }, [getMeals])
    
    return (
    <div className="w-full">
        {selectedMeal && 
            <DiaryComments
                 meal={selectedMeal}
                 onClose={() => setSelectedMeal(undefined)} 
                 onSave={() => getMeals()}
            />
        }
        <div className="pt-12 pb-3 text-gray-900 font-bold text-xl">Diário Alimentar</div>
        <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-purple-800 p-1">

            {Array.apply(null, Array(6)).map((_, index) => {
                const period = moment().add(-index, "month").format("MMMM/YYYY");
                return (
                    <Tab
                        key={index}
                        className={({ selected }) =>
                            classNames(
                            'w-full rounded-lg py-2.5 text-lg font-medium leading-5 text-white capitalize',
                            'ring-white ring-opacity-60 focus:ring-2',
                            selected
                                ? 'text-purple-800 bg-white shadow'
                                : 'hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                        >
                        {period}
                    </Tab>
                )
            })}
            </Tab.List>
            <Tab.Panels className="mt-2">
            {Array.apply(null, Array(6)).map((_, index) => {
                const period = moment().add(-index, "month").format("MMMM/YYYY");
                const filteredMeals = meals.filter((m) => m.month === period);
                return (
                    <Tab.Panel
                        key={index}
                        className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none"
                    >
                        <ul>
                            {filteredMeals.map((m) => (
                                <li
                                    key={m._id}
                                    className="relative rounded-md p-3 hover:bg-gray-100"
                                    onClick={() => setSelectedMeal(m)}
                                >
                                    <h3 className="text-lg font-medium leading-5">
                                        {m.title}
                                    </h3>
                
                                    <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                    <li>{moment(m.date).format("DD/MM/YYYY")} {m.hour}</li>
                                    <li>&middot;</li>
                                    <li>{m.comments.length} comentário</li>
                                    {m.image && (
                                        <>
                                            <li>&middot;</li>
                                            <li>Imagem adicionada</li>
                                        </>
                                    )}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </Tab.Panel>
                )
            })}
            </Tab.Panels>
        </Tab.Group>
    </div>
    );
}

export default Diary;