import { createSelector } from "reselect";

export const selectAuthState = createSelector(
  (state) => state.auth,
  (auth) => auth
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (auth) => !!auth.user
);

export const selectUser = createSelector(selectAuthState, (auth) => auth.user);

export const selectLoading = createSelector(
  selectAuthState,
  (auth) => auth.loading
);

export const selectErrors = createSelector(
  selectAuthState,
  (auth) => auth.errors
);
