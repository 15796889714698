import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Loading } from "../../components";
import {
  Container,
  HauxIcon,
  FormContainer,
  ImageContainer,
  Image,
  ImageOverlay,
  Label,
  Input,
  Button,
} from "./styles";
import { selectErrors, selectLoading } from "../../redux/ducks/Auth/selectors";
import { auth } from "../../redux/ducks/Auth/actions";

function SignIn() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loading = useSelector(selectLoading);
  const errors = useSelector(selectErrors);

  const handleSubmit = () => {
    dispatch(auth(username, password));
  };

  return (
    <Container>
      <FormContainer>
        <HauxIcon />
        <h1>Bem-vindo ao Back-Office</h1>
        <h2>Aqui você faz a gestão profissional do paciente.</h2>
        <Label>Usuário</Label>
        <Input
          placeholder="Digite aqui o seu usuário HAUX"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Label>Senha</Label>
        <Input
          placeholder="Digite aqui sua senha"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {!_.isEmpty(errors) && (
          <Label style={{ marginBottom: 40, color: "var(--danger)" }}>
            {errors}
          </Label>
        )}
        <Button onClick={handleSubmit}>
          Entrar
          {loading && (
            <div style={{ position: "absolute", right: 30, top: 10 }}>
              <Loading />
            </div>
          )}
        </Button>
      </FormContainer>
      <ImageContainer>
        <ImageOverlay />
        <Image />
      </ImageContainer>
    </Container>
  );
}

export default SignIn;
