//@ts-nocheck
import React, { InputHTMLAttributes } from "react";
import { useFormContext, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import _ from "lodash"

interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  suffix?: string;
  format?: string;
  compact?: boolean;
}

export const transform = {
  output: (value: string, suffix?: string, prefix?: string) => {
    if (!value) return 0;
    if (suffix === " mmHg") {
      return value.replace(suffix || "", "");
    }
    const number = value
      .replace(prefix || "", "")
      .replace(suffix || "", "")
      .replace(/[./A-Za-z %/]+/g, "")
      .replace(",", ".");
    return isNaN(Number(number))
      ? value.replace(suffix || "", "").trim()
      : Number(number);
  },
};

const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { label, name, compact, ...rest  } = props;
  const { control, formState: { errors } } = useFormContext();
  const error: { message?: String} | undefined = _.get(errors, name.split('.'))

  return (
    <div className="flex flex-col w-full">
    <Controller
      render={({ field }) => (
        <label className="font-bold uppercase">
            <span className={`text-gray-700 ${compact ? 'm-0 p-0 text-sm' : ""}`}>{label}</span>
            <NumberFormat
              className={`
                mt-1
                block
                w-full
                rounded-md
                border-gray-300
                shadow-sm
                ${error ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-2 focus:ring-opacity-30 focus:ring-red-500 focus:border-red-500': 'focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}
                ${compact ? 'p-0.5 text-sm m-0' : ""}
              `}
              decimalSeparator=","
              thousandSeparator="."
              format={props.format}
              suffix={props.suffix}
              placeholder={`${props.suffix || ""}`}
              {...rest}
              {...field}
              value={field.value || ""}
              allowLeadingZeros={true}
              onChange={(e) => {
                field.onChange(transform.output(e.target.value, props.suffix, props.prefix));
              }}
            />
            {error && error.message && <p className="mt-2 text-sm text-red-600 field-error">{error.message}</p>}
          </label>
      )}
      control={control}
      name={name}
    />
    </div>
  );
};


export const NumberInputRegister: React.FC<NumberInputProps> = (props) => {
  const { label, name, onChange, ...rest } = props;

  return (
    <div
      className={classNames({
        container: true,
        compact: props.compact,
      })}
    >
      <label>{label}</label>
      {props.hidden !== true && (
        //@ts-ignore
        <NumberFormat
          className="numberInput"
          decimalSeparator=","
          thousandSeparator="."
          {...rest}
          format={props.format}
          suffix={props.suffix}
          placeholder={`__ ${props.suffix || ""}`}
          onChange={onChange}
        />
      )}
      {props.renderOther}
    </div>
  );
};

export default NumberInput;