import React from "react";
import {
    Row,
    ItemWrapper,
    Title,
    Description
} from "../Food/styles";

interface IUsedExams {
    name: string;
    value: string;
}

const UsedExams: React.FC<IUsedExams> = ({
    name,
    value
}) => {
    return (
        <ItemWrapper>
            <Row>
                <Row
                    style={{ flex: 1, flexDirection: "column", alignItems: "flex-start" }}
                >
                    <Row style={{ width: "100%" }}>
                        <Title style={{ marginRight: 35 }}>{(name || "").toUpperCase()}</Title>
                    </Row>

                    <Row style={{ alignItems: "start" }}>
                        <Description>{value}</Description>
                    </Row>
                </Row>
            </Row>
        </ItemWrapper>
    );
};

export default UsedExams;