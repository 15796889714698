import { call, put } from "redux-saga/effects";
import { path } from "ramda";
import {
  requestClientsSuccess,
  setLastAnamnesis,
} from "../ducks/Client/actions";
import ClientService from "../../services/ClientService";
import AnamnesisService from "../../services/AnamnesisService";
import _ from "lodash";

export function* getAllClients() {
  try {
    const response = yield call(ClientService.getClients);
    const clients = path(["data", "data", "items"], response);
    yield put(requestClientsSuccess(clients));
  } catch (e) {
    yield put(requestClientsSuccess([]));
  }
}

export function* setCurrentClient({ client }) {
  try {
    const response = yield call(
      AnamnesisService.getAllAnamnesis,
      "tech",
      client._id
    );
    if (response.problem) throw new Error(response.problem);
    const anamnesis = path(["data", "data", "items"], response).reverse();
    const last = _.last(anamnesis);
    yield put(setLastAnamnesis(last));
  } catch (e) {
    yield put(setLastAnamnesis({}));
  }
}
