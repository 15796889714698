import React from "react";
import {
    Row,
    ItemWrapper,
    Title,
    Description
} from "../Food/styles";

interface IMadeChallenges {
    name: string;
    when: string;
}

const MadeChallenges: React.FC<IMadeChallenges> = ({
    name,
    when
}) => {
    return (
        <ItemWrapper>
            <Row>
                <Row
                    style={{ flex: 1, flexDirection: "column", alignItems: "flex-start" }}
                >
                    <Row style={{ width: "100%" }}>
                        <Title style={{ marginRight: 35 }}>{(name || "").toUpperCase()}</Title>
                    </Row>

                    <Row style={{ alignItems: "start" }}>
                        <Description>Realizado em {when}</Description>
                    </Row>
                </Row>
            </Row>
        </ItemWrapper>
    );
};

export default MadeChallenges;