const Types = {
  GET_ALL: "haux/anamnesis/GET_ALL",
  GET_ALL_SUCCESS: "haux/anamnesis/GET_ALL_SUCCESS",
  SAVE: "haux/anamnesis/SAVE",
  SAVE_SUCCESS: "haux/anamnesis/SAVE_SUCCESS",
  SAVE_FAIL: "haux/anamnesis/SAVE_FAIL",
  SET_ANAMNESIS_INDEX: "haux/anamnesis/SET_ANAMNESIS_INDEX",
};

export default Types;
