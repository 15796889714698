import styled from "styled-components";

export const Background = styled.div`
  background-color: ${({ open }) => (open ? "#00000066" : "transparent")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease;
  pointer-events: ${({ open }) => (open ? "all" : "none")};
  z-index: 1001;
`;

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: ${({ open, large }) => (open ? 0 : large ? "-95vw" : "-449px")};
  bottom: 0;
  width: ${({ large }) => (large ? "95vw" : "449px")};
  background-color: white;
  transition: all 0.3s ease;
  padding: 32px;
  overflow-y: scroll;
  z-index: 1002;
`;
