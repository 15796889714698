const movementDifficulty = [
  "Não",
  "Agachar-se",
  "Colocar tênis",
  "Pegar objetos da mesa",
  "Levantar da cama",
  "Levantar da posição sentada",
  "Pegar algo acima da cabeça",
  "Entrar e sair do carro",
  "Outro",
];

export default movementDifficulty;
