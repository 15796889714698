import styled from "styled-components";
interface IFooter {
  clientToggled: boolean;
  visible: boolean;
}
export const FooterContainer = styled.div<IFooter>`
  position: fixed;
  bottom: 0;
  left: ${({ clientToggled }) => (clientToggled ? 430 : 80)}px;
  right: 0;
  height: 69px;
  background-color: var(--card-background);
  font-family: var(--font-heavy);
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 88px;
  transition: all 0.3s ease, padding 0.5s linear;
  transform: ${({ visible }) =>
    visible ? "translateY(0)" : "translateY(69px)"};
`;

export const SaveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-heavy);
  font-size: 15px;
  background-color: var(--success);
  color: #fff;
  padding: 5px 24px;
  min-height: 50px;
  border-radius: 8px;
  text-transform: uppercase;

  :hover {
    background-color: var(--success-light);
  }
`;

export {};
