import styled from "styled-components";
import ProfileSVG from "../../../assets/images/profile.svg";
import BackSVG from "../../../assets/icons/back.svg";

interface IProfileContainer {
  visible: boolean;
}
export const ProfileContainer = styled.div<IProfileContainer>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 370px;
  padding-top: 28px;
  padding-left: 28px;
  padding-right: 48px;
  z-index: 1000;
  transition: all 0.3s ease;
  transform: ${({ visible }) =>
    !visible ? "translateX(100%)" : "translateX(0)"};
`;

export const ClientName = styled.p`
  font-family: var(--font-regular);
  font-size: 32px;
  color: #fff;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  margin-bottom: 34px;
  width: 100%;
  line-height: 30px;

  strong {
    font-family: var(--font-heavy);
  }
`;

export const BackIcon = styled.img.attrs({
  src: BackSVG,
})`
  height: 36px;
  width: 36px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.8s ease, padding 0.8s linear;
`;

export const ProfileImage = styled.img.attrs({
  src: ProfileSVG,
})`
  border-width: 3px;
  border-style: solid;
  border-color: #c4c4c4;
  border-radius: 63px;
  width: 126px;
  height: 126px;
`;

export const ProfilePlanTitle = styled.h1`
  font-family: var(--font-heavy);
  font-size: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: var(--success);
  margin-top: 16px;
  margin-bottom: 0px;
`;

export const ProfilePlanSubtitle = styled(ProfilePlanTitle)`
  font-size: 15px;
  color: var(--text);
  margin-top: 0px;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const Label = styled.label`
  font-family: var(--font-heavy);
  font-size: 13px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
`;

export const Value = styled(Label)`
  font-size: 16px;
  text-transform: none;
`;

export {};
