// @ts-nocheck
import React, { useState, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Row, Col } from "reactstrap";
import { LeftPanel } from "..";
import {
  Title,
  Subtitle,
  ListContainer,
  ItemContainer,
  RemoveIcon,
} from "../../pages/Anamnesis/styles";
import { AddButton, AddIcon } from "../FoodList/styles";
import { LABORATORY_EXAMS, CARDIOLOGY_EXAMS } from "../../utils/exams";

interface ExamsRequestProps {
  name: string;
  downloadExamsRequest?: Function;
}

const ExamsRequest: React.FC<ExamsRequestProps> = ({ name, downloadExamsRequest, handleExamsRequest }) => {
  const [open, setOpen] = useState(false);
  const { control, setValue } = useFormContext();
  const printRef = useRef(null);

  return (
    <Controller
      render={({ field }) => {
        const values = field.value || [];
        return (
          <>
            <div className="p-8">
            <div className="flex flex-row flex-wrap">
                {values.map((exam) => (
                  <div className="flex flex-row w-fit mr-8 mb-4" key={exam.name}>
                      <RemoveIcon
                        style={{ marginRight: 10, height: 20 }}
                        onClick={() => {
                          setValue(
                            name,
                            values.filter((e) => e.name !== exam.name),
                            { shouldTouch: true }
                          );
                        }}
                      />
                      <Title
                        style={{
                          color: "var(--purple-light)",
                          marginBottom: 0,
                        }}
                      >
                        {exam.label}
                      </Title>
                    </div>
                ))}
              </div>
              <div ref={printRef} style={{ display: "none" }}>
                <Row>
                  <Col>
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: 50,
                      }}
                    >
                      <strong>Solicitação de Exames</strong>
                    </p>
                  </Col>
                </Row>
                {values.map((exam) => (
                  <Row key={exam.name}>
                    <Col>
                      <p>{exam.label}</p>
                    </Col>
                  </Row>
                ))}
              </div>
              <Row style={{ display: "flex", justifyContent: "space-between"}}>
                <Col>
                  <AddButton
                    style={{ position: "initial" }}
                    onClick={() => setOpen(!open)}
                  >
                    <AddIcon />
                    Adicionar exames
                  </AddButton>
                </Col>
                <Col>
                  <AddButton
                    style={{ position: "initial" }}
                    onClick={() => downloadExamsRequest((field.value || []).map((e) => { return e.label }).join(", "))}
                  >
                    Imprimir
                  </AddButton>
                </Col>
              </Row>
            </div>
            <LeftPanel large={false} open={open} onClose={() => setOpen(false)}>
              <Title>Exames</Title>
              <Subtitle>Selecione os exames que deseja solicitar</Subtitle>

              <ListContainer>
                {[...LABORATORY_EXAMS, ...CARDIOLOGY_EXAMS]
                  .filter((e) => values.filter((i) => i.name === e.name).length === 0)
                  .map((exam) => (
                    <ItemContainer
                      key={exam.name}
                      onClick={() => {
                        setValue(name, [...values, exam], {
                          shouldTouch: true,
                        });
                      }}
                    >
                      <Title style={{ color: "var(--purple-light)" }}>
                        {exam.label}
                      </Title>
                      <Subtitle>{exam.suffix}</Subtitle>
                    </ItemContainer>
                  ))}
              </ListContainer>
            </LeftPanel>
          </>
        );
      }}
      control={control}
      name={name}
    />
  );
};

export default ExamsRequest;
