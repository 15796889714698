import React, { InputHTMLAttributes } from "react";
import { useFormContext, Controller } from "react-hook-form";

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  name: string;
}

const TextArea: React.FC<TextAreaProps> = ({ label, name, ...rest }) => {
  const { control, formState: { errors } } = useFormContext();
  const error: { message?: String} | undefined = _.get(errors, name.split('.'))

  return (
    <div className="flex flex-col w-full">
      <Controller
        render={({ field }) => (
          <label className="font-bold uppercase">
            <span className="text-gray-700">{label}</span>
            <textarea
              type="text"
              className={`mt-1 block w-full rounded-md border-gray-300 placeholder-gray-500 shadow-sm
              focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
              ${error ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-2 focus:ring-opacity-30 focus:ring-red-500 focus:border-red-500': 'focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}`}
              {...rest}
              {...field}
            />
            {error && error.message && <p className="mt-2 text-sm text-red-600 field-error">{error.message}</p>}
          </label>
        )}
        control={control}
        name={name}
      />
    </div>
  );
};

export default TextArea;




