import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Column } from "./BoardColumn";
import { Task } from "./TaskCard";
import { v4 as uuidv4 } from "uuid";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  AlertConfirm,
  AlertError,
  AlertProgress,
  AlertSuccess,
} from "components/alert";
import BoardService from "services/BoardService";
import { Franchise } from "context/UserContext";

const newColumnTitle = "Edite o nome da seção de perguntas";
const newTaskContent = "Edite aqui sua pergunta";

type BoardProviderProps = {
  children: React.ReactNode;
  type: string;
};

type BoardProviderState = {
  loading: boolean;
  saving: boolean;
  touched: boolean;
  franchise?: Franchise;
  setFranchise?: (franchise: Franchise | undefined) => void;
  saveBoard: () => void;
  columns: Column[];
  setColumns: (columns: Column[]) => void;
  newColumn: () => void;
  deleteColumn: (columnId: UniqueIdentifier) => void;
  tasks: Task[];
  setTasks: (tasks: Task[]) => void;
  newTask: (columnId: UniqueIdentifier) => void;
  deleteTask: (taskId: UniqueIdentifier) => void;
  setColumnTitle: (columnId: UniqueIdentifier, title: string) => void;
  setTaskContent: (taskId: UniqueIdentifier, content: string) => void;
  onFocusColumn?: (columnId: UniqueIdentifier) => void;
  onBlurColumn?: (columnId: UniqueIdentifier) => void;
  onFocusTask?: (taskId: UniqueIdentifier) => void;
  onBlurTask?: (taskId: UniqueIdentifier) => void;
};

const initialState = {
  loading: false,
  saving: false,
  touched: false,
  saveBoard: () => null,
  columns: [],
  setColumns: ([]) => null,
  newColumn: () => null,
  deleteColumn: (_columnId: UniqueIdentifier) => null,
  tasks: [],
  setTasks: ([]) => null,
  newTask: (_columnId: UniqueIdentifier) => null,
  deleteTask: (_taskId: UniqueIdentifier) => null,
  setColumnTitle: (_columnId: UniqueIdentifier, _title: string) => null,
  setTaskContent: (_task: UniqueIdentifier, _content: String) => null,
};

const BoardProviderContext = createContext<BoardProviderState>(initialState);

enum BoardState {
  NotLoaded,
  FirstLoad,
  Loaded,
  Touched,
  Errored,
}

export function BoardProvider({
  children,
  type,
  ...props
}: BoardProviderProps) {
  const [franchise, setFranchise] = useState<Franchise | undefined>();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [boardState, setBoardState] = useState<BoardState>(
    BoardState.NotLoaded
  );
  const [columns, setColumns] = useState<Column[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);

  const getBoard = useCallback(async () => {
    if (!franchise) {
      return;
    }

    var confirmed = true;
    if (boardState === BoardState.Touched) {
      confirmed = await AlertConfirm({
        title:
          "Você tem alterações não salvas, tem certeza que deseja prosseguir?",
        subtitle: "As mudanças não salvas serão perdidas",
        confirmButtonText: "Sim!",
      });
    }

    if (confirmed) {
      setLoading(true);
      try {
        const response = await BoardService.getBoard(type, franchise._id);
        const data = response.data.data;
        if (data) {
          const { columns, tasks } = data;
          setColumns(columns);
          setTasks(tasks);
        } else {
          setColumns([])
          setTasks([])
        }
        setBoardState(BoardState.FirstLoad);
      } catch {}
      setLoading(false);
    }
  }, [type, franchise]);

  const saveBoard = useCallback(async () => {
    if (!franchise) {
      return;
    }

    const columnEmpty = columns.reduce((partial, column) => {
      return partial || isEmptyTitleColumn(column.title);
    }, false);

    const taskEmpty = tasks.reduce((partial, task) => {
      return partial || isEmptyContentTask(task.content);
    }, false);

    if (columnEmpty || taskEmpty) {
      AlertError({
        title: "Não é possível salvar",
        subtitle: "Existem seções ou perguntas que não foram editadas.",
      });
      return;
    }

    let confirmed = await AlertConfirm({
      title: "Você tem certeza que deseja salvar?",
      subtitle: "As novas perguntas da anamnese serão atualizadas.",
      confirmButtonText: "Sim, salvar!",
    });
    if (confirmed) {
      AlertProgress({ title: "Salvando o template de perguntas" });
      setSaving(true);
      try {
        await BoardService.findOneAndUpdate({
          type,
          columns,
          tasks,
          franchise: franchise?._id,
        });
        setBoardState(BoardState.Loaded);
        AlertSuccess({ subtitle: "Template de perguntas salvo com sucesso." });
      } catch {
        AlertError({ subtitle: "Erro ao salvar template, tente novamente." });
      }
      setSaving(false);
    }
  }, [type, franchise, columns, tasks]);

  useEffect(() => {
    getBoard();
  }, [getBoard]);

  useEffect(() => {
    setBoardState((b) => {
      if (b == BoardState.NotLoaded) return b;
      if (b == BoardState.FirstLoad) return BoardState.Loaded;

      return BoardState.Touched;
    });
  }, [tasks, columns]);

  const newTask = (columnId: UniqueIdentifier) => {
    setTasks((rs) => [
      ...rs,
      {
        id: uuidv4(),
        columnId,
        content: newTaskContent,
      },
    ]);
  };

  const newColumn = () => {
    const newId = uuidv4();
    setColumns((cols) => [
      ...cols,
      {
        id: newId,
        title: newColumnTitle,
      },
    ]);
    newTask(newId);
  };

  const deleteColumn = async (columnId: UniqueIdentifier) => {
    const confirmed = await AlertConfirm({
      confirmButtonText: "Sim, excluir a seção!",
    });
    if (confirmed) {
      setColumns((rs) => [...rs.filter((column) => column.id != columnId)]);
      setTasks((rs) => [...rs.filter((task) => task.columnId != columnId)]);
    }
  };

  const deleteTask = async (taskId: UniqueIdentifier) => {
    const confirmed = await AlertConfirm({
      confirmButtonText: "Sim, excluir a pergunta!",
    });
    if (confirmed) {
      setTasks((rs) => [...rs.filter((task) => task.id != taskId)]);
    }
  };

  const setColumnTitle = (columnId: UniqueIdentifier, title: string) => {
    setColumns((cols) =>
      cols.map((col) => ({
        ...col,
        title: col.id == columnId ? title : col.title,
      }))
    );
  };

  const setTaskContent = (taskId: UniqueIdentifier, content: string) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, content } : task
      )
    );
  };

  const isEmptyTitleColumn = (title: string): boolean => {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = title;
    const fixedTitle =
      tempDivElement.textContent || tempDivElement.innerText || title;

    return (
      !fixedTitle ||
      fixedTitle.trim() === newColumnTitle ||
      fixedTitle.trim().length == 0
    );
  };

  const isEmptyContentTask = (content: string): boolean => {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = content;
    const fixedContent =
      tempDivElement.textContent || tempDivElement.innerText || content;

    return (
      !fixedContent ||
      fixedContent.trim() === newTaskContent ||
      fixedContent.trim().length == 0
    );
  };

  const onFocusColumn = (columnId: UniqueIdentifier) => {
    setColumns((prevColumns) => {
      const column = prevColumns.find((column) => column.id === columnId);
      if (!column) return prevColumns;

      if (isEmptyTitleColumn(column.title)) {
        return prevColumns.map((col) =>
          col.id === columnId ? { ...col, title: "" } : col
        );
      }

      return prevColumns;
    });
  };

  const onBlurColumn = (columnId: UniqueIdentifier) => {
    setColumns((prevColumns) => {
      const column = prevColumns.find((column) => column.id === columnId);
      if (!column) return prevColumns;

      if (isEmptyTitleColumn(column.title)) {
        return prevColumns.map((column) =>
          column.id === columnId ? { ...column, title: newColumnTitle } : column
        );
      }

      return prevColumns;
    });
  };

  const onFocusTask = (taskId: UniqueIdentifier) => {
    setTasks((prevTasks) => {
      const task = prevTasks.find((task) => task.id === taskId);
      if (!task) return prevTasks;

      if (isEmptyContentTask(task.content)) {
        return prevTasks.map((task) =>
          task.id === taskId ? { ...task, content: "" } : task
        );
      }

      return prevTasks;
    });
  };

  const onBlurTask = (taskId) => {
    setTasks((prevTasks) => {
      const task = prevTasks.find((task) => task.id === taskId);
      if (!task) return prevTasks;

      if (isEmptyContentTask(task.content)) {
        return prevTasks.map((task) =>
          task.id === taskId ? { ...task, content: newTaskContent } : task
        );
      }

      return prevTasks;
    });
  };

  const value = {
    franchise,
    setFranchise,
    loading,
    saving,
    touched: boardState === BoardState.Touched,
    saveBoard,
    columns,
    setColumns,
    newColumn,
    deleteColumn,
    tasks,
    setTasks,
    newTask,
    deleteTask,
    setColumnTitle,
    setTaskContent,
    onFocusColumn,
    onBlurColumn,
    onFocusTask,
    onBlurTask,
  };

  return (
    <BoardProviderContext.Provider {...props} value={value}>
      {children}
      <div className="h-24" />
    </BoardProviderContext.Provider>
  );
}

export const useBoard = () => {
  const context = useContext(BoardProviderContext);

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};
