import axios from "../middlewares/axios";

class PDFService {
  static generateReport(clientId, techAnamnesisId, template) {
    if (template === "reportTemplateMin") {
      const url = `${
        axios.defaults.baseURL
      }/pdf/report?client=${clientId}&techAnamnesis=${techAnamnesisId}&template=${template}`;
      return url;
    }

    const url = `/pdf/report?client=${clientId}&techAnamnesis=${techAnamnesisId}&template=${template}`;
    return axios.get(url, { responseType: "blob" });
  }
  static foodPlan(clientId) {
    const url = `/pdf/foodPlan?client=${clientId}`;
    return axios.get(url, { responseType: "blob" });
  }
  static examsRequest(clientId, examsRequest, doctor, crm) {
    const url = `/pdf/examsRequest?client=${clientId}&examsRequest=${examsRequest}&doctor=${doctor}&crm=${crm}`;
    return axios.get(url, { responseType: "blob" });
  }
}

export default PDFService;
