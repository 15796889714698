import React from "react";

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    selected?: Boolean;
    compact?: Boolean;
}

const Button: React.FC<IButton> = ({ selected, compact, children, className, ...props }) => {

    return (
        <button type="button" className={[
            "flex outline-none border font-medium rounded-lg text-sm text-center mr-2 mb-2 h-fit",
            selected ? "text-white bg-purple-800 hover:bg-purple-900 hover:text-white" : "hover:bg-purple-100 text-purple-700 hover:text-purple-800",
            compact ? "px-2.5 py-1" : "px-5 py-2.5",
            className
        ].join(" ")}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;