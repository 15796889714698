import { Switch, Route } from "react-router-dom";
import UsersList from "./list";
import UsersPermission from "./permission";
import UsersForm from "./form";

const Routes = () => {
  return (
    <div
      className="h-full p-12 overflow-scroll"
      style={{
        backgroundColor: "var(--neutral-color)",
      }}
    >
      <div
        className="rounded-lg py-6 px-12 bg-white"
      >
        <Switch>
          <Route
            path={`/user`}
            exact
            component={UsersList}
          />
          <Route
            path={`/user/form/:userId?`}
            component={UsersForm}
          />
          <Route
            path={`/user/permissions/:userId?`}
            //@ts-ignore
            component={UsersPermission}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Routes;