import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup'
import _ from "lodash";
import {
	FormProvider,
	useForm,
	useWatch,
	useFieldArray,
} from "react-hook-form";
import { NumberInput } from 'components/3.0';
import { TextArea, Select } from "atoms";
import { Section } from '../styles';
import { PrimaryTitle, HR } from "components/new/Title";
import ClientService from 'services/ClientService';
import ProductService, { IProduct } from 'services/ProductService';
import ProductContractSchema from './schema';

interface ClientParams {
	clientId?: string;
}

const SubscribeForm: React.FC = () => {
  const history = useHistory();
  const methods = useForm({
		resolver: yupResolver(ProductContractSchema)
	});
  const { reset, control, handleSubmit, formState: { errors }, clearErrors } = methods;
	const { fields, append, remove } = useFieldArray({
		control,
		name: "products",
	});
  const [products, setProducts] = useState<IProduct[]>([]);
	const productsValue = useWatch({ control, name: 'products' });
  const { clientId } = useParams<ClientParams>();

  const getData = useCallback(async () => {
      try {
        Swal.fire({
          title: 'Obtendo dados',
          text: 'Em progresso...',
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          }
        })
        const clientResponse = await ClientService.get(clientId);
        const client = _.get(clientResponse, ['data', 'data'])
        const products = await ProductService.getFranchiseProducts(client.franchise);
        setProducts(products);
        Swal.close();
      } catch {
        Swal.fire('Erro ao obter dados', '', 'error')
      }
		
	}, [clientId]);

  useEffect(() => {
    getData()
  }, [getData])

	const getContractValue = useCallback(() => {
		if (!productsValue) return 0;
		return productsValue.reduce((a, current) => a + ((current.quantity * current.value) || 0), 0)
	}, [productsValue]);

    const onSubmit = useCallback(
        async (data) => {
          try {

            Swal.fire({
							title: 'Salvando',
							text: 'Em progresso...',
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading()
							}
						})

						await ProductService.insertPack({ client: clientId, ...data})
						
						await Swal.fire({
							title: 'Dados salvos com sucesso',
							icon: 'success',
							didOpen: () => {
								Swal.hideLoading()
							},
              didClose: () => {
                history.goBack();
              }
						});

						reset({})
						clearErrors()
          } catch (errors) {
            Swal.fire('Erro ao salvar', '', 'error')
          }
        },
        [clientId, reset, clearErrors]
	);

	const onDelete = async (index: number) => {
		const result = await Swal.fire({
			title: "Deseja realmente excluir?",
			text: "Você não poderá desfazer esta ação!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "var(--success)",
			cancelButtonColor: "var(--danger)",
			confirmButtonText: "Sim, excluir!",
			cancelButtonText: "Desistir",
		});

		if (result.isConfirmed) {
			remove(index);
		}
	}

	const getProductsOptions = useCallback(() => {
		return products.map((p) => ({ value: p._id, label: p.title}))
	}, [products])

    return (
        <div className='p-8'>
          <FormProvider {...methods}>
            <form style={{ paddingBottom: 50 }} onSubmit={handleSubmit(onSubmit)}>
              <Section>
                <PrimaryTitle>Contratar um novo produto</PrimaryTitle>
                <HR />
                <div className='grid grid-flow-row gap-3.5'>
                  {(productsValue || []).length > 0 && <div className="grid grid-flow-row gap-3.5 mb-8">
                    <div className='col-span-4'>
                      <Select name={"paymentMethod"} label="Forma de pagamento" options={[
                        "Dinheiro",
                        "PIX",
                        "Cartão de crédito",
                        "Cartão de débito",
                        "Transferência bancária",
                        "Boleto bancário",
                        "Pagamentos por aplicativos",
                        "Cheque"
                      ]} />
                    </div>
                    <div className='col-span-4'>
                      <TextArea name="observations" label="Observações Gerais" />
                    </div>
                  </div>}
                  {fields.map((field, index) => (
                    <div key={field.id} className="grid grid-cols-9 gap-3">
                      <div className='flex col-span-4'>
                        <Select name={`products.${index}.product`} label="Nome do produto" options={getProductsOptions()} />
                      </div>
                      <div className='col-span-2'>
                        <NumberInput name={`products.${index}.quantity`} label='Quantidade' placeholder='0'/>
                      </div>
                      <div className='col-span-2'>
                        <NumberInput name={`products.${index}.value`} label='Valor individual' prefix='R$ ' placeholder='R$ 0,00'/>
                      </div>
                      <div className='col-span-1 flex items-end'>
                        <button
                          type="button"
                          className="ml-4 text-gray-400 hover:bg-gray-400 hover:text-white p-4 py-3 flex items-center rounded transition-all"
                          onClick={() => { onDelete(index)}}
                          >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                {(_.get(errors, ["products"]) && !(productsValue || []).length) && <p className='font-bold text-2xl mb-4 text-[#ff4548] mt-8 mb-8'>{_.get(errors, ["products", "message"])}</p>}
                <p className='font-bold text-2xl mb-4 text-gray-500 mt-8 mb-8'>Valor total do contrato: {getContractValue().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>

                  <div className='flex grid grid-flow-col gap-4'>
                  <button
                    type="button"
                    className="w-full transition-all bg-transparent hover:border-purple-800 text-purple-300 font-semibold hover:text-purple-800 py-2 px-4 border border-purple-300 rounded"
                    onClick={() => append({ quantity: 1})}
                    >
                    Adicionar produto
                  </button>
                  <button className="w-full transition-all text-white bg-[#33b6ae] hover:bg-[#1f6d68] font-semibold py-2 px-4 rounded" >
                    Confirmar contratação
                  </button>
                </div>
                
              </Section>
            </form>
          </FormProvider>
        </div>
    )
}

export default SubscribeForm;