import styled from "styled-components";
import LoadingSVG from "../../assets/icons/loading.svg";
import LoadingPurpleSVG from "../../assets/icons/loading-purple.svg";
import LoadingOrangeSVG from "../../assets/icons/loading-orange.svg";
import LoadingMedSVG from "../../assets/icons/loading-med.svg";

export const LoadingPurple = styled.img.attrs({
  src: LoadingPurpleSVG,
})`
  height: 40px;
`;

export const LoadingOrange = styled.img.attrs({
  src: LoadingOrangeSVG,
})`
  height: 40px;
`;

export const LoadingMed = styled.img.attrs({
  src: LoadingMedSVG,
})`
  height: 40px;
`;

export default styled.img.attrs({
  src: LoadingSVG,
})`
  height: 40px;
`;
