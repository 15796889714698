import axios from "../middlewares/axios";

export interface IUser {
  _id: string;
  name: string;
  username: string;
  profileType: string;
  email: string;
  register: string;
  password: string;
  passwordConfirm?: string;
  franchisesExternal: number[];
  isDeleted?: boolean;
}

export interface IProfile {
  _id: string;
  name: string;
  permissions: string[];
}

interface IUserResponse {
  items: IUser[],
  totalCount: number
}

interface IProfileResponse {
  items: IProfile[],
  totalCount: number
}

class UserService {
  static async getById(id: string): Promise<IUser> {
    const url = `/user/${id}`;
    const response = await axios.get<{
      data: IUser
    }>(url)
    response.data.data.passwordConfirm = response.data.data.password
    return response.data.data;
  }

  static async getAll(): Promise<IUserResponse> {
    const url = `/user?sortBy=name`;
    const response = await axios.get<{
      data: IUserResponse
    }>(url)
    return response.data.data;
  }

  static async getProfilePermissions(profile: string): Promise<IProfile | undefined> {
    const url = `/profile?name=${profile}&isDeleted=false`;
    const response = await axios.get<{
      data: IProfileResponse
    }>(url)
    return response.data.data.items.length > 0 ? response.data.data.items[0] : undefined;
  }

  static async updateUserPermissions(userId: string, permissions: string[]): Promise<any> {
    const url = `/profile/permissions/${userId}`;
    const response = await axios.patch<{
      data: any
    }>(url, permissions)
    return response.data.data;
  }

  static async insert(data: IUser): Promise<any> {
    const url = `/user`;
    const response = await axios.post(url, data)
    return response;
  }

  static async update(userId: string, data: IUser): Promise<any> {
    const url = `/user/${userId}`;
    const response = await axios.put(url, data)
    return response;
  }
}

export default UserService;