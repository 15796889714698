const periods = {
  breakfast: "Café da manhã",
  morningSnack: "Lanche da manhã",
  lunch: "Almoço",
  afternoonSnack: "Lanche da tarde",
  dinner: "Jantar",
  eveningSnack: "Ceia",
  extraMeal: "Refeição extra",
  snack: "Lanche",
};

export default periods;
