import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "redux/ducks/Auth/selectors";
import Swal from "sweetalert2";
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProvider,
  useForm,
  useWatch,
} from "react-hook-form";
import { FaUnlockAlt } from 'react-icons/fa';
import { 
  CheckGroup,
} from "../../components/3.0"
import { Select, TextInput } from 'atoms';
import { ALL_FRANCHISES } from 'context/ClientContext';
import UserService from "services/UserService";
import UserSchema from "./schema";

interface UserParams {
  userId?: string;
}

const UserForm: React.FC = () => {
  const history = useHistory();
  const methods = useForm({
    resolver: yupResolver(UserSchema)
  });
  const { handleSubmit, reset, control, setValue } = methods;
  const user = useSelector(selectUser);
  const [changePassword, setChangePassword] = useState(false)
  const { userId } = useParams<UserParams>();
  const isDeleted =  useWatch({
    control,
    name: "isDeleted",
  });

  useEffect(() => {
    const getDados = async (userId) => {
      try {
        Swal.fire({
          title: 'Obtendo dados do usuário',
          text: 'Em progresso...',
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          }
        })
        const user = await UserService.getById(userId)
        reset(user);
        Swal.close()
      } catch {
        Swal.fire('Erro ao obtertados', 'Verifique sua conexão e tente novamente', 'error')
      }
    }

    if (userId) {
      getDados(userId)
    }
  }, [userId, reset])

  const onSubmit = useCallback(async (data) => {
    try {
      Swal.fire({
				title: 'Salvando',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			})
      if (userId) {
        await UserService.update(userId, data)
      } else {
        await UserService.insert(data)
      }
      Swal.fire({
				title: 'Sucesso',
				text: 'Usuário salvo com sucesso',
				icon: 'success',
				didClose: () => {
					history.goBack()
				}
			})
    } catch (e) {
      Swal.fire('Erro ao salvar', 'Verifique se o nome do usuário já existe', 'error')
    }
  }, [userId]);

  return (
    <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='container mx-auto'>
            <h1 className="text-4xl capitalize mb-2" style={{
              fontFamily: "var(--font-heavy)",
              color: `var(--purple-dark)`
            }}>
              Novo usuário
            </h1>

            <div className="mt-8 grid grid-cols-8 grid-flow-row-dense gap-4">
              <div className='col-span-8 max-w-md'>
                <TextInput name="username" label="Login"/>
              </div>
              <div className='col-span-8'>
                <TextInput name="name" label="Nome completo"/>
              </div>
              <div className='col-span-8 max-w-md'>
                <Select
                  name='profileType'
                  label='Perfil do usuário'
                  options={[
                    { label: 'Admin', value: 'Admin'},
                    { label: 'Superadmin', value: 'Superadmin'},
                    { label: 'Nutri', value: 'Nutri'},
                    { label: 'Med', value: 'Med'},
                    { label: 'Psico', value: 'Psico'},
                    { label: 'Training', value: 'Training'},
                    { label: 'Coordenação', value: 'Coordinator'},
                    { label: 'Medical', value: 'Medical'},
                    { label: 'Enfermeira Medical', value: 'MedicalNurse'},
                    { label: 'Coordenação Medical', value: 'MedicalCoordinator'},
                  ]}
                />
              </div>
              <div className='col-span-5'>
                <TextInput name="email" label="E-mail"/>
              </div>
              <div className='col-span-3'>
                <TextInput name="register" label="Registro no conselho (CRM, CRN...)"/>
              </div>
              <div className='col-span-4' style={{ display: !userId || changePassword ? "block" : "none"}}>
                <TextInput name="password" label="Senha" type="password" />
              </div>
              <div className='col-span-4' style={{ display: !userId || changePassword ? "block" : "none"}}>
                <TextInput name="passwordConfirm" label="Confirme a Senha" type="password"/>
              </div>

              <div className='col-span-2' style={{ display: userId && !changePassword ? "block" : "none"}}>
                <button
                  type="button"
                  className="flex items-center outline-none border font-medium rounded text-center h-fit px-5 py-2 px-4 hover:bg-purple-100 hover:text-purple-800"
                    onClick={() => {
                      setChangePassword(true)
                    }}
                  >
                    <FaUnlockAlt className="mr-2" />
                    Alterar senha
                </button>
              </div>
              
              <div className='col-span-8'>
                <CheckGroup
                  name="franchisesExternal"
                  label="Selecione as franquias"
                  options={ALL_FRANCHISES.filter((f) => user.franchisesExternal.includes(f.value))}
                />
              </div>
            </div>
            <div className="flex mt-8 gap-8">
              <button className="bg-[#624E92] hover:bg-[#3d2e61] text-white font-bold py-2 px-4 rounded w-40">
                Salvar
              </button>
              <button
                className="flex outline-none border font-medium rounded text-center h-fit px-5 py-2 px-4 hover:bg-purple-100 text-purple-700 hover:text-purple-800"
                  onClick={() => {
                    history.replace('/user/')
                  }}
                >
                  Cancelar
              </button>
              {userId && <button
                className={`flex outline-none font-medium rounded text-center h-fit px-5 py-2 px-4 ${isDeleted ? "text-black bg-gray-100 hover:bg-gray-300" : "text-white bg-red-500 hover:bg-red-800"}`}
                  onClick={() => {
                    setValue("isDeleted", !isDeleted)
                  }}
                >
                  {isDeleted ? "Ativar usuário" : "Inativar usuário"}
              </button>}
            </div>
          </div>
        </form>
		</FormProvider>
  )
}

export default UserForm;