import axios from "../middlewares/axios";

class ClientService {
  static getAll() {
    const url = `/challenges`;
    return axios.get(url);
  }

  static getAccepted(client) {
    const url = `/challengeAccepted?client=${client}`;
    return axios.get(url);
  }

  static acceptMany(data) {
    const url = `/challengeAccepted/many`;
    return axios.post(url, data);
  }

  static getHistory(client) {
    const url = `/challenges/historyClient?client=${client}`;
    return axios.get(url);
  }
}

export default ClientService;
