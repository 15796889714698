import styled from "styled-components";
import AddSVG from "../../assets/icons/add.svg";
import RemoveSVG from "../../assets/icons/remove.svg";
import EnergyOrangeSVG from "../../assets/icons/energy-orange.svg";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
`;

export const AddIcon = styled.img.attrs({
  src: AddSVG,
})`
  margin-right: 25px;
`;

export const RemoveButton = styled.img.attrs({
  src: RemoveSVG,
})`
  cursor: pointer;
`;

export const AddButton = styled.button.attrs({
  type: "button",
})`
  position: absolute;
  right: 17px;
  top: -105px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 321px;
  height: 68px;
  background: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-light);
  border-radius: 8px;
  transition: all 0.3s ease;
  font-family: var(--font-heavy);
  font-size: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: var(--purple);

  :hover {
    border-color: var(--purple);
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EnergyOrangeIcon = styled.img.attrs({
  src: EnergyOrangeSVG,
})`
  margin-top: -7px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 79px;
  min-width: 93px;
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 11px;
  margin-top: 36px;
  margin-right: 20px;
`;

export const CardTitle = styled.div`
  font-family: var(--font-heavy);
  font-size: 10px;
  letter-spacing: -0.01em;
  line-height: 18px;
  text-align: left;
  text-transform: uppercase;
  color: ${({ highlight }) =>
    highlight ? "var(--orange)" : "var(--purple-light)"};
`;

export const CardValue = styled(CardTitle)`
  font-size: 26px;
`;

export const CardUnit = styled(CardTitle)`
  font-size: 16px;
  padding-top: 3px;
`;

export const FoodsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    margin-right: 100px;
  }
`;

export const MealWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px;
  border-radius: 8px;
  transition: all 0.3s ease;

  img {
    width: 150px;
  }

  div {
    p {
      font-family: var(--font-heavy);
      font-size: 20px;
      color: var(--purple);
      margin-bottom: 0;
    }

    span {
      font-family: var(--font-heavy);
      font-size: 16px;
      color: var(--text);
    }
  }

  :hover {
    background-color: var(--hover-background);
  }
`;
