import styled from "styled-components";

export const Container = styled.div``;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  opacity: 0.26;
`;

export const Label = styled.label`
  font-family: ${({ selected }) =>
    selected ? "var(--font-bold)" : "var(--font-medium)"};
  font-size: 14px;
  color: ${({ selected }) => (selected ? "var(--purple)" : "var(--text)")};
  margin-bottom: 0;
`;
