const muscleAches = [
  "Não",
  "Caminhada",
  "Pilates",
  "Corrida",
  "Musculação",
  "Bicicleta",
  "Ginástica",
  "Hidroginástica",
  "Outro",
];

export default muscleAches;
