import React, { SelectHTMLAttributes, useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import classnames from "classnames";
import _ from "lodash";

interface Option {
  label: string;
  value: string | number;
}

interface RadioProps extends SelectHTMLAttributes<HTMLInputElement> {
  label?: string;
  compact?: boolean;
  name: string;
  direction?: "row" | "column";
  options?: Option[] | string[];
  defaultValue?: string;
  showSelect?: boolean;
}

const Select: React.FC<RadioProps> = (props) => {
  const { label, name, options, showSelect = true, ...rest } = props;
  const { control, formState: { errors } } = useFormContext();
  const error: { message?: String} | undefined =_.get(errors, name.split('.'))

  const getOptions = useMemo(() => {
    return options?.map((option: string | Option) =>
      _.isString(option)
        ? {
            label: option,
            value: option,
          }
        : option
    ) as Option[];
  }, [options]);

  return (
    <div className="flex flex-col w-full">
        <Controller
        render={({ field }) => (
            <label className="font-bold uppercase">
                <span className="text-gray-700">{label}</span>
                <select
                    className={classnames(
                      "mt-1 block w-full rounded-md border-gray-300 shadow-sm placeholder-gray-500",
                      { "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-2 focus:ring-opacity-30 focus:ring-red-500 focus:border-red-500": error },
                      { "focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50": !error }
                    )}
                    {...rest}
                    {...field} 
                    defaultValue={"23:00"}>
                      {showSelect && <option label="Selecione..."/>}
                      {getOptions.map(({ label, value }) => (
                        <option key={value} value={value} label={label} />
                      ))}
                </select>
                {error && error.message && <p className="mt-2 text-sm text-red-600 field-error">{error.message}</p>}
            </label>
        )}
        control={control}
        name={name}
        />
    </div>
  );
};

export default Select;