// @ts-nocheck
import React, { InputHTMLAttributes, useCallback, useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Input, Row, Col, Label, CloseButton } from "./styles"
import CloseSVG from "../../assets/icons/close.svg";
import _ from "lodash";

interface Option {
  label: string;
  value: string | number;
}

interface CheckProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  compact?: boolean;
  name: string;
  direction?: "row" | "column";
  options?: Option[] | string[];
  renderOther?: React.ReactNode;
}

const Intern = ({field, option, name, setValue}) => {

  const [optionValue, setOptionValue] = useState({});

  const getOptionValue = (values = [], option) => {
    const filtered = values.filter((v) => v.value === option);
    return filtered.length > 0 ? filtered[0] : {};
  } 

  useEffect(() => {
    setOptionValue(getOptionValue(field.value, option))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, setOptionValue])

  return (
    <>
        <CloseButton
          src={CloseSVG}
          onClick={() => {
            const values = field.value || [];
              if (values.filter((v) => v.value === option.value).length > 0) {
                setValue(
                  name,
                  values.filter((v) => v.value !== option.value),
                  { shouldTouch: true }
                );
              } else {
                setValue(name, [...values,  {...option }], 
                { shouldTouch: true });
              }
          }}
        />
        <br />
        <Row>
          <Col>
            <Label>Observação</Label>
            <Input
              value={optionValue.observation}
              placeholder="Digite aqui..."
              onChange={(e) => {
                const value = {...optionValue};
                value.observation = e.target.value;
                setValue(name, [...(field.value || []).filter((v) => v.value !== option),  value], { shouldTouch: true });
                setOptionValue(value)
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Status</Label>
            <Input
              value={optionValue.status}
              placeholder="Digite aqui..."
              onChange={(e) => {
                const value = {...optionValue};
                value.status = e.target.value;
                setValue(name, [...(field.value || []).filter((v) => v.value !== option),  value ], { shouldTouch: true });
                setOptionValue(value)
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Col>
          <Col>
            <Label>Nome do Médico</Label>
            <Input
              value={optionValue.doctor}
              placeholder="Digite aqui..."
              onChange={(e) => {
                const value = {...optionValue};
                value.doctor = e.target.value;
                setValue(name, [...(field.value || []).filter((v) => v.value !== option),  value], { shouldTouch: true });
                setOptionValue(value)
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Tratamento</Label>
            <Input
              value={optionValue.treatment}
              placeholder="Digite aqui..."
              onChange={(e) => {
                const value = {...optionValue};
                value.treatment = e.target.value;
                setValue(name, [...(field.value || []).filter((v) => v.value !== option),  value ], { shouldTouch: true });
                setOptionValue(value)
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Col>
        </Row>
      </>
  )
}

const CheckGroup: React.FC<CheckProps> = (props) => {
  const { label, name, options } = props;
  const { control, setValue } = useFormContext();

  const getOptions = useCallback((): Option[] => {
    return options?.map((option: string | Option) =>
      _.isString(option)
        ? {
            label: option,
            value: option,
          }
        : option
    ) as Option[];
  }, [options]);

  const isOther = (value) => {
    return (
      value &&
      (value.includes("Outro") ||
        value.includes("Outros") ||
        value.includes("Outra") ||
        value.includes("Outras"))
    );
  };

  

  return (
    <Controller
      render={({ field }) => (
        <>
          <div className="flex flex-col p-5 w-full rounded-lg mb-8">
            <label>{label}</label>
            <div className={`flex flex-row flex-wrap`}>
              {getOptions().map((option) => (
                <div
                  key={option.value}
                  className={`
                    p-4
                    font-bold
                    h-fit
                    uppercase
                    text-left
                    text-gray-400
                    border
                    border-solid
                    border-gray-400
                    rounded-lg
                    transition-all
                    cursor-pointer
                    mr-2
                    mb-2
                    relative
                    ${(field.value || []).filter((v) => v.value === option.value).length > 0 ? "text-teal-500 border-teal-500 border-2": ""}
                  `}
                  onClick={() => {
                    const values = field.value || [];
                    if (values.filter((v) => v.value === option.value).length > 0) {
                      setValue(
                        name,
                        values.filter((v) => v.value !== option.value),
                        { shouldTouch: true }
                      );
                    } else {
                      setValue(name, [...values,  {...option }], 
                      { shouldTouch: true });
                    }
                  }}
                >
                  {option.label}
                  {(field.value || []).filter((v) => v.value === option.value).length > 0 && (
                    <Intern key={option.value} field={field} option={option.value} name={name} setValue={setValue}/>
                  )}
                </div>
              ))}
            </div>
          </div>
          {props.renderOther && (
            <div
              className="otherContainer"
              style={{ display: isOther(field.value) ? "block" : "none" }}
            >
              {props.renderOther}
            </div>
          )}
        </>
      )}
      control={control}
      name={name}
    />
  );
};

export default CheckGroup;
