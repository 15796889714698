import axios from "../middlewares/axios";

class AuthService {
  static auth(username, password) {
    const url = `/login`;
    return axios.post(url, { username, password });
  }
}

export default AuthService;
