import { TechTitle } from "components/new/Title";
import { Section } from "pages/Anamnesis/Tech/styles";
import { Row, Col } from "reactstrap";
import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  FiFile,
  FiImage,
  FiFileText,
  FiTrash2,
  FiUpload,
} from "react-icons/fi";
import { cn } from "@/lib/utils"; // Função utilitária para classes dinâmicas do shadcn/ui
import FileService from "services/FileService";
import {
  AlertConfirm,
  AlertProgress,
  AlertSuccess,
  AlertError,
} from "components/alert";

export interface FileCenterProps {
  title?: string;
  color?: string;
  clientId?: string;
  preffix?: string;
}

interface IAttachment {
  name: string;
  url: string;
  size: number;
}

interface ClientParams {
  clientId?: string;
}

const FileCenter: React.FC<FileCenterProps> = ({ title, color, clientId: clientProp, preffix }) => {
  const { clientId: clientParam } = useParams<ClientParams>();
  const clientId = clientProp ?? clientParam;
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const maxFileMb = 10;

  const fetchAttachments = useCallback(async (client: string, preffix: string | undefined) => {
    setLoading(true);
    setUploadError(null);

    try {
      const response = await FileService.getAllClientAttachments(client, preffix);
      setAttachments(response.data.data.files || []); // Armazena os anexos no estado
    } catch (err) {
      setUploadError("Erro ao carregar os anexos.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (clientId) {
      fetchAttachments(clientId ?? "", preffix); // Carrega os anexos ao montar o componente
    }
  }, [clientId, preffix, fetchAttachments]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadError(null);
    const newFiles = acceptedFiles.filter(
      (file) => file.size <= maxFileMb * 1024 * 1024
    );
    if (newFiles.length < acceptedFiles.length) {
      setUploadError(
        `Alguns arquivos excedem o limite de ${maxFileMb}MB e não foram adicionados.`
      );
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [], // Aceita todos os tipos de imagem
      "application/pdf": [], // Aceita PDFs
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // Word moderno (.docx)
      "application/msword": [], // Word antigo (.doc)
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // Excel moderno (.xlsx)
      "application/vnd.ms-excel": [], // Excel antigo (.xls)
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PowerPoint moderno (.pptx)
      "application/vnd.ms-powerpoint": [],
    },
  });

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleRemoveAttachment = async (attachmentName: string) => {
    const confirmed = await AlertConfirm({});

    if (confirmed) {
      try {
        AlertProgress({ title: "Excluindo arquivo anexado" });
        await FileService.deleteClientAttachment(attachmentName);
        setAttachments((prevAttachments) =>
          prevAttachments.filter(
            (attachment) => attachment.name !== attachmentName
          )
        );
        AlertSuccess({ subtitle: "Arquivo excluído com sucesso!" });
      } catch (e) {
        AlertError({});
      }
    }
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setUploadError("Nenhum arquivo para fazer upload.");
      return;
    }

    setUploadError(null);
    setIsUploading(true);

    var totalSize: number = files.reduce(
      (previousValue, currentValue) => previousValue + currentValue.size,
      0
    );

    try {
      // Enviar cada arquivo individualmente
      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("client", clientId ?? "");
        formData.append("preffix", preffix ?? "")

        await FileService.uploadClientAttachment(formData, (event) => {
          const progress = Math.round((event.loaded * 100) / totalSize);

          //@ts-ignore
          setUploadProgress(progress);
        });
      }

      // Resetar estado após upload completo
      setFiles([]);
      setUploadProgress(null);
      setIsUploading(false);
      fetchAttachments(clientId ?? "", preffix);
      AlertSuccess({ subtitle: "Todos os arquivos foram enviados com sucesso!" });
    } catch (error) {
      setUploadError("Erro ao fazer upload. Por favor, tente novamente.");
      setIsUploading(false);
    }
  };

  const handleOpenFile = (file: File) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  const renderIconAttachment = (attachment: IAttachment) => {
    const [extension] = attachment.name.slice(-1);

    if (
      [
        "png",
        "jpg",
        "jpeg",
        "ico",
        "gif",
        "bmp",
        "tiff",
        "eps",
        "svg",
      ].includes(extension.toLocaleLowerCase())
    )
      return <FiImage className="text-yellow-500" />;
    if (extension.toLocaleLowerCase() === "pdf")
      return <FiFileText className="text-red-500" />;
    if (["doc", "docx"].includes(extension.toLocaleLowerCase()))
      return <FiFile className="text-blue-500" />;
    if (
      ["xls", "xlsx", "xlsm", "xlsb", "xltx", "xlm"].includes(
        extension.toLocaleLowerCase()
      )
    )
      return <FiFile className="text-green-500" />;
    if (["ppt", "pptx", "pptm", "potx"].includes(extension.toLocaleLowerCase()))
      return <FiFile className="text-orange-500" />;
    return <FiFile className="text-gray-500" />;
  };

  const renderIcon = (file: File) => {
    if (file.type.startsWith("image/"))
      return <FiImage className="text-yellow-500" />;
    if (file.type === "application/pdf")
      return <FiFileText className="text-red-500" />;
    if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword"
    )
      return <FiFile className="text-blue-500" />;
    if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    )
      return <FiFile className="text-green-500" />;
    if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      file.type === "application/vnd.ms-powerpoint"
    )
      return <FiFile className="text-orange-500" />;
    return <FiFile className="text-gray-500" />;
  };

  return (
    <Section>
      {(!clientParam || clientProp) && <div style={{ padding: 0, marginBottom: 30}}>
        <Col style={{ margin: 0 }}>
          <TechTitle style={{ color: color || "var(--primary-dark-color)" }}>
            {title ?? "Central de Arquivos"}
          </TechTitle>
        </Col>
      </div>}
      <Row className="w-full">
        <Col className="w-full">
          <div className="w-full p-4 border-2 border-dashed rounded-lg bg-gray-50 dark:bg-gray-800 border-gray-300 dark:border-gray-700">
            <div
              {...getRootProps()}
              className={cn(
                "flex flex-col items-center justify-center h-32 p-4 cursor-pointer",
                isDragActive && "bg-blue-100 dark:bg-blue-900"
              )}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="text-blue-500">Solte os arquivos aqui...</p>
              ) : (
                <p className="text-gray-500 dark:text-gray-400">
                  Arraste e solte arquivos ou clique para selecionar.
                  <br />
                  (Imagens, PDFs, Word, Excel, PowerPoint | Máx. {maxFileMb}MB)
                </p>
              )}
            </div>

            {files.length > 0 && (
              <div className="mt-4">
                <h4 className="text-lg font-medium text-gray-700 dark:text-gray-300">
                  Arquivos para enviar:
                </h4>
                <ul className="mt-2 space-y-2">
                  {files.map((file, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between p-2 bg-gray-100 dark:bg-gray-700 rounded-lg"
                    >
                      <div className="flex items-center space-x-2">
                        {renderIcon(file)}
                        <button
                          onClick={() => handleOpenFile(file)} // Certifique-se de que essa função está implementada corretamente
                          className="text-sm text-blue-500 hover:underline"
                          type="button" // Impede que o botão dispare um comportamento de "submit"
                        >
                          {file.name}
                        </button>
                        <span className="text-sm text-gray-400 dark:text-gray-500">
                          ({(file.size / 1024).toFixed(2)} KB)
                        </span>
                      </div>
                      <button
                        onClick={() => handleRemoveFile(index)}
                        className="text-red-500 hover:text-red-700"
                        aria-label={`Remover ${file.name}`}
                      >
                        <FiTrash2 />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {uploadError && (
              <p className="mt-2 text-sm text-red-500">{uploadError}</p>
            )}

            <button
              onClick={handleUpload}
              className="mt-4 w-full bg-blue-500 text-white py-2 px-4 mb-4 rounded-lg hover:bg-blue-600 disabled:opacity-50"
              disabled={isUploading || files.length === 0}
            >
              {isUploading ? (
                <span className="flex items-center justify-center space-x-2">
                  <FiUpload className="animate-spin" />
                  <span>Enviando...</span>
                </span>
              ) : (
                "Enviar Arquivos"
              )}
            </button>

            {uploadProgress !== null && (
              <div className="mt-4">
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Progresso do upload: {Math.min(uploadProgress, 100)}%
                </p>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-lg h-4 mt-1">
                  <div
                    className="bg-blue-500 h-4 rounded-lg"
                    style={{ width: `${Math.min(uploadProgress, 100)}%` }}
                  />
                </div>
              </div>
            )}

            <div className="mt-4">
              <h4 className="flex items-center justify-between text-lg font-medium text-gray-700 dark:text-gray-300">
                Anexos já enviados:
                <span className="text-sm text-gray-400 dark:text-gray-500">
                  ({(attachments.reduce((previous, current) => previous + current.size, 0) / (1024 * 1024)).toFixed(2)} MB)
                </span>
              </h4>
              {loading && <p>Carregando...</p>}
              <ul className="mt-2 space-y-2">
                {attachments.length > 0 ? (
                  attachments.map((attachment) => (
                    <li
                      key={attachment.name}
                      className="flex items-center justify-between p-2 bg-gray-100 dark:bg-gray-700 rounded-lg"
                    >
                      <div className="flex items-center space-x-2">
                        {renderIconAttachment(attachment)}
                        <a
                          href={attachment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm text-blue-500 hover:underline"
                        >
                          {attachment.name.replace((preffix ? `${preffix}/` : ""), "").replace(`${clientId}/`, "")}
                        </a>
                        <span className="text-sm text-gray-400 dark:text-gray-500">
                          ({(attachment.size / 1024).toFixed(2)} KB)
                        </span>
                      </div>
                      <button
                        onClick={() => handleRemoveAttachment(attachment.name)}
                        className="text-red-500 hover:text-red-700"
                        aria-label={attachment.name.replace(`${clientId}/`, "")}
                        type="button"
                      >
                        <FiTrash2 />
                      </button>
                    </li>
                  ))
                ) : (
                  <p>
                    {!loading &&
                      "Não há anexos para este cliente ou erro ao obter dados"}
                  </p>
                )}
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default FileCenter;
