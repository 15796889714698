import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--card-background);
  padding: 24px 30px;
  margin-top: 48px;
  margin-bottom: 80px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 626px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.label`
  font-family: var(--font-heavy);
  font-size: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--black);
  margin-bottom: 0;
`;

export const Status = styled.label`
  font-family: var(--font-heavy);
  font-size: 22px;
  text-align: left;
  color: var(--success);
  margin-bottom: 0;
`;

export const Description = styled.label`
  font-family: var(--font-medium);
  font-size: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--text);
  margin-bottom: 0;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 3px;
  width: 100%;
  position: relative;
  margin-top: 40px;
`;

export const Range = styled.div`
  position: relative;
  flex: ${({ size }) => size};
  background-color: ${({ color }) => color};
  border-top-left-radius: ${({ borderRadiusLeft }) => borderRadiusLeft}px;
  border-bottom-left-radius: ${({ borderRadiusLeft }) => borderRadiusLeft}px;
  border-top-right-radius: ${({ borderRadiusRight }) => borderRadiusRight}px;
  border-bottom-right-radius: ${({ borderRadiusRight }) => borderRadiusRight}px;
`;

export const Circle = styled.div`
  width: 106px;
  height: 106px;
  border-radius: 53px;
  background-color: var(--success);
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Value = styled.span`
  font-family: var(--font-heavy);
  font-size: 32px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
`;

export const Hint = styled.span`
  position: absolute;
  font-family: var(--font-heavy);
  font-size: 10px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
  top: 8px;
`;

export const Label = styled.p`
  font-family: var(--font-heavy);
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
`;
