import React, { InputHTMLAttributes, useCallback } from "react";
import { useFormContext, Controller } from "react-hook-form";
import _ from "lodash";

interface Option {
  label: string;
  value: string | number | boolean;
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  compact?: boolean;
  name: string;
  direction?: "row" | "column";
  options?: Option[] | string[];
  renderOther?: React.ReactNode;
}

const RadioGroup: React.FC<RadioProps> = (props) => {
  const { label, name, options } = props;
  const { control, setValue, formState: { errors } } = useFormContext();
  const error: { message?: String} | undefined = _.get(errors, name.split('.'))

  const getOptions = useCallback((): Option[] => {
    return options?.map((option: string | Option) =>
      _.isString(option)
        ? {
            label: option,
            value: option,
          }
        : option
    ) as Option[];
  }, [options]);

  const isOther = (value) => {
    return (
      value &&
      (value.includes("Outro") ||
        value.includes("Outros") ||
        value.includes("Outra") ||
        value.includes("Outras"))
    );
  };

  return (
    <Controller
      render={({ field }) => (
        <div className="flex flex-col w-full">
          <label className="font-bold uppercase">{label}</label>
          <div className="flex flex-row flex-wrap">
            {getOptions().map((option) => (
              <div
                key={`${option.value}`}
                className={`
                    font-bold
                    h-fit
                    uppercase
                    text-left
                    border
                    border-solid
                    rounded-lg
                    p-5
                    mt-3
                    mr-3
                    transition-all
                    ${(field.value === option.value) ? 'border-teal-400 bg-teal-400 text-white hover:bg-teal-500': 'border-gray-400 text-gray-400 hover:border-gray-300 hover:bg-gray-300 hover:text-white'}
                    ${error && error.message ? 'text-red-600 border-red-600 field-error': ''}
                  `}
                onClick={() => {
                  setValue(name, option.value, { shouldTouch: true });
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
          {error && error.message && <p className="mt-2 text-sm text-red-600 field-error uppercase">{error.message}</p>}
          {props.renderOther && (
            <div
              style={{ display: isOther(field.value) ? "block" : "none" }}
            >
              {props.renderOther}
            </div>
          )}
        </div>
      )}
      control={control}
      name={name}
    />
  );
};

export default RadioGroup;
