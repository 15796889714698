import axios from "../middlewares/axios";

class GoalsService {
  static getAll(client) {
    const url = `/anamnesi/goals/all?client=${client}`;
    return axios.get(url);
  }

  static insert(data) {
    const url = `/anamnesi/goals`;
    return axios.post(url, data);
  }

  static update(id, data) {
    const url = `/anamnesi/goals/${id}`;
    return axios.put(url, data);
  }

  static delete(id) {
    const url = `/anamnesi/goals/${id}`;
    return axios.delete(url);
  }
}

export default GoalsService;
