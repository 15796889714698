import React from "react";
import { PrimaryTitle } from "../Title";
import { Container, Header } from "./styles";
interface IInfos {
  title: string;
  right?: Function;
  children: any;
}

const Info: React.FC<IInfos> = ({ 
  children, title, right
}) => {
  return (
    <Container>
      <Header>
        <PrimaryTitle>{title}</PrimaryTitle>
        {right && right()}
      </Header>
      {children}
    </Container>
  );
};

export default Info;
