import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import InputMask from "react-input-mask";
import { Container, Label, Input } from "./styles";

const formatChars = {
  n: "[0-1]",
  m: "[0-9]",
  e: "[0-3]",
  d: "[0-9]",
  z: "[1-2]",
  y: "[0-9]",
};

const DateInput = ({ label, compact, ...props }) => {
  const inputRef = useRef();
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();

  return (
    <Container
      error={!!meta.error && submitCount}
      onMouseEnter={() => {
        inputRef.current.focus();
      }}
    >
      <Label compact={compact}>{label}</Label>
      <InputMask
        formatChars={formatChars}
        mask="ed/nm/zyyy"
        alwaysShowMask={false}
        maskChar={null}
        {...field}
      >
        {(inputProps) => (
          <Input ref={inputRef} compact={compact} {...inputProps} />
        )}
      </InputMask>
    </Container>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  compact: PropTypes.bool,
};

export default DateInput;
