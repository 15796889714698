import { FaUserCircle } from "react-icons/fa"
import { Container, HauxIcon } from "./styles";

function Menu({ onUserClick }) {
  return (
    <Container>
      <HauxIcon />
      <FaUserCircle alt="Clique para sair" style={{ cursor: "pointer" }} color={'white'} size={38} onClick={onUserClick} />
    </Container>
  );
}

export default Menu;
