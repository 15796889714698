import axios from "../middlewares/axios";
import moment from "moment";

class DiaryService {
  static getMeals(clientId) {
    const start = moment().add(-6, "year").format("YYYY-MM-DD");
    const end = moment().add(1, "year").format("YYYY-MM-DD");
    const url = `/diary/meal?client=${clientId}&start=${start}&end=${end}`;
    return axios.get(url);
  }

  static addComment(mealDiaryId, data) {
    const url = `/diary/meal/comment/${mealDiaryId}`;
    return axios.post(url, data);
  }

  static deleteComment(mealDiaryId, commentId) {
    const url = `/diary/meal/comment/${mealDiaryId}/${commentId}`;
    return axios.delete(url);
  }

  static getMealById(id, clientId) {
    const url = `/diary/meal/one?_id=${id}&client=${clientId}`;
    return axios.get(url);
  }

  static getMealByDateAndHour(clientId, date, hour) {
    const url = `/diary/meal/one?client=${clientId}&date=${date}&hour=${hour}`;
    return axios.get(url);
  }

}

export default DiaryService;
