import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { useDndContext, type UniqueIdentifier } from "@dnd-kit/core";
import ContentEditable from "react-contenteditable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo, useCallback } from "react";
import { Task, TaskCard } from "./TaskCard";
import { cva } from "class-variance-authority";
import { Card, CardContent, CardHeader } from "./ui/card";
import { Button } from "@/components/ui/button";
import { GripVertical, Plus, Trash2Icon } from "lucide-react";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";
import { useBoard } from "./board-provider";
import _ from "lodash";

export interface Column {
  id: UniqueIdentifier;
  title: string;
}

export type ColumnType = "Column";
export type Orientation = "vertical" | "horizontal";

export interface ColumnDragData {
  type: ColumnType;
  column: Column;
}

interface BoardColumnProps {
  column: Column;
  tasks: Task[];
  isOverlay?: boolean;
  orientation?: Orientation;
}

export function BoardColumn({ column, tasks, isOverlay, orientation }: BoardColumnProps) {
  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const { newTask, onFocusColumn, onBlurColumn, setColumnTitle, deleteColumn } = useBoard();

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    } as ColumnDragData,
    attributes: {
      roleDescription: `Column: ${column.title}`,
    },
  });

  const debouncedSetColumnTitle = useCallback(
    _.debounce((column: Column, title: string) => {
      setColumnTitle(column.id, title)
    }, 500),
    []
  );

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva(
    (orientation == "vertical" ? "w-full bg-[#e7aa2266] flex flex-col flex-shrink-0 snap-center" :
     "h-[440px] max-h-[440px] w-[300px] max-w-full bg-[#e7aa2266] flex flex-col flex-shrink-0 snap-center"
    ),
    {
      variants: {
        dragging: {
          default: "border-2 border-transparent",
          over: "ring-2 opacity-30",
          overlay: "ring-2 ring-primary",
        },
      },
    }
  );

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
      })}
    >
      <CardHeader className="p-4 font-semibold border-b-2 text-left flex flex-row space-between items-center">
        <Button
          variant={"ghost"}
          {...attributes}
          {...listeners}
          className="p-1 text-primary/50 -ml-2 h-auto cursor-grab relative"
        >
          <span className="sr-only">{`Move column: ${column.title}`}</span>
          <GripVertical />
        </Button>
        <ContentEditable
          className="ml-2 w-full pr-4 text-secondary-foreground/50 text-lg"
          html={`${column.title}`}
          disabled={false}
          onChange={(e) => debouncedSetColumnTitle(column, e.target.value)}
          onFocus={() => onFocusColumn?.(column.id)}
          onBlur={() => onBlurColumn?.(column.id)}
          tagName='span'
        />
        <Button
          type="button"
          variant={"ghost"}
          className="p-1 mr-4 text-secondary-foreground/50 h-full ml-auto h-auto cursor-pointer"
          onClick={() => deleteColumn(column.id)}
        >
          <span className="sr-only">Delete task</span>
          <Trash2Icon />
        </Button>
        <Button
          type="button"
          variant={"ghost"}
          className="w-auto p-2 bg-[#e7aa22] text-white hover:bg-[#e7aa2266] hover:text-white -ml-2 h-auto"
          onClick={() => newTask(column.id)}
        >
          <Plus />
          Nova pergunta
        </Button>
      </CardHeader>
      <ScrollArea>
        <CardContent className="flex flex-grow flex-col gap-2 p-2">
          <SortableContext items={tasksIds}>
            {tasks.map((task) => (
              <TaskCard key={task.id} task={task} />
            ))}
          </SortableContext>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}

interface BoardContainerProps {
  orientation?: "vertical" | "horizontal";
  children: React.ReactNode;
}

export function BoardContainer({ children, orientation }: BoardContainerProps) {
  const dndContext = useDndContext();

  const variations = cva("px-2 md:px-0 flex lg:justify-center pb-4", {
    variants: {
      dragging: {
        default: "snap-x snap-mandatory",
        active: "snap-none",
      },
    },
  });

  return (
    <ScrollArea
      className={variations({
        dragging: dndContext.active ? "active" : "default",
      })}
    >
      <div className={`flex gap-4 items-center ${orientation == "vertical" ? "flex-col" : "flex-row"} justify-start`}>
        {children}
      </div>
      <ScrollBar orientation={orientation ?? "horizontal"} />
    </ScrollArea>
  );
}