import React, { useState, useEffect, useCallback } from "react";
import Infos from "../../../components/new/Infos";
import { LoadingPurple } from "../../../components/Loading";
import ReportService from "../../../services/ReportsService";
import moment from "moment";
import _ from "lodash";

interface Report {
  _id: string;
  updatedAt: string;
  text: string;
  user: {
    _id?: string;
    name?: string;
  };
}

interface IObservations {
  clientId: string;
}

const Observations: React.FC<IObservations> = ({ clientId }) => {
  const [loading, setLoading] = useState(false);
  const [observations, setObservations] = useState<Report[]>([]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ReportService.getAll(clientId, "observation");
      let reports = _.get(response, ["data", "data", "items"]);
      if (reports && reports.length > 0) {
        const [first] = reports;
        const itemsResponse = await ReportService.getItems(first._id);
        const data = _.get(itemsResponse, ["data", "data", "items"], []);
        setObservations(data.filter((o) => o.text && o.text.length > 0));
      }
    } catch (e) {}
    setLoading(false);
  }, [clientId]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <Infos title="Observações do Cliente">
      {!observations ||
        (observations.length == 0 && (
          <small className="mt-8">Nenhuma observação foi encontrada</small>
        ))}
      {observations.map((report) => {
        if (report.text && report.text.length > 0) {
          return (
            <div style={{ marginTop: 50 }} key={report._id}>
              <strong>
                {report.user.name} -{" "}
                {moment(report.updatedAt).format("DD/MM/YYYY")}
              </strong>
              <br />
              <small>
                <div
                  dangerouslySetInnerHTML={{
                    __html: report.text,
                  }}
                />
              </small>
            </div>
          );
        }
      })}
    </Infos>
  );
};

export default Observations;
