import React from "react";

import { Background, Container } from "./styles";

function LeftPanel({ open, large, onClose, children }) {
  return (
    <>
      <Background open={open} onClick={onClose} />
      <Container open={open} large={large}>
        {children}
      </Container>
    </>
  );
}

export default LeftPanel;
