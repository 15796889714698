import styled from "styled-components";
import AddSVG from "../../../assets/icons/add.svg";
import RemoveSVG from "../../../assets/icons/remove.svg";
import EnergySVG from "../../../assets/icons/energy.svg";

export const AddIcon = styled.img`
  margin-right: 25px;
`;
AddIcon.defaultProps = {
  src: AddSVG,
};

export const RemoveButton = styled.img`
  cursor: pointer;
  width: 25px;
`;
RemoveButton.defaultProps = {
  src: RemoveSVG,
};

export const EnergyIcon = styled.img`
  margin-top: 5px;
  margin-right: 5px;
`;
EnergyIcon.defaultProps = {
  src: EnergySVG,
};

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 68px;
  background: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-light);
  border-radius: 8px;
  transition: all 0.3s ease;
  font-family: var(--font-heavy);
  font-size: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: var(--purple);

  :hover {
    border-color: var(--purple);
  }
`;
AddButton.defaultProps = {
  type: "button",
}

export const Title = styled.h1`
  font-family: var(--font-heavy);
  font-size: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: var(--purple-dark);
`;

export const Subtitle = styled.h2`
  font-family: var(--font-medium);
  font-size: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);

  strong {
    font-family: var(--font-heavy);
  }
`;

export const SearchInput = styled.input`
  height: 47px;
  width: 100%;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-light);
  color: var(--text);
  background-color: var(--card-background);
  padding: 0 19px;

  ::placeholder {
    color: var(--text-light);
  }
`;

export {};

export const Section = styled.div`
  align-items: flex-start;
  padding: 32px 32px 16px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
`;
