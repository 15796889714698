import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import removeAccents from 'remove-accents';
import Swal from "sweetalert2";
import _ from "lodash";
import moment from "moment";
import { Modal } from '../../components/3.0'
import { LoadingPurple } from "../../components/Loading";
import PlanoAlimentar from "./plan";
import NutritionalPlanService from '../../services/NutritionalPlan';

interface ClientParams {
    clientId?: string;
    planId?: string;
}

interface IPlan {
    _id: string;
    title?: string;
    createdAt: string;
    createdBy?: string;
    updatedAt: string;
    updatedBy?: string;
    client: {
       name: string; 
    };
    active?: boolean;
}

interface IPlanTemplates {
    isOpen: boolean;
    setIsOpen: (open: boolean) => any;
    onSelectTemplate?: (id: string) => any;
}

const PlanTemplates: React.FC<IPlanTemplates> = ({
    isOpen,
    setIsOpen,
    onSelectTemplate
}) => {
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState<IPlan[]>([]);

    const getAll = useCallback(async () => {
        if (!isOpen) {
            setPlans([]);
            setLoading(false);
            setText("");
            return;
        }
        setLoading(true);
        try {
            const response = await NutritionalPlanService.getAllPlans();
            const items = _.get(response, ["data", "data", "items"], []);
            setPlans(items)
        } catch(e) {
            NotificationManager.error("Erro ao obter planos");
        }
        setLoading(false)
    }, [isOpen, setPlans, setLoading]);

    useEffect(() => {
        getAll();
    }, [getAll])

    const filteredPlans = useCallback((() => {
        return plans.filter((plan) => 
            removeAccents(plan.title ?? "(Sem título)".toUpperCase()).includes(removeAccents(text.toUpperCase().normalize("NFC"))) || 
            removeAccents(plan.client.name.toUpperCase()).includes(removeAccents(text.toUpperCase()))
        )
    }), [plans, text])

    const onSelect = useCallback(async (plan: IPlan) => {
        onSelectTemplate?.(plan._id)
    }, [onSelectTemplate])

    return (
        <Modal
            title="Escolha o template para criar o novo plano"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className="relative -mt-4">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <input type="search" id="default-search" className="mt-1
                block
                w-full
                rounded-md
                border-gray-300
                shadow-sm
                pl-10
                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                " placeholder="Pesquisar template..." value={text} onChange={(e) => {
                    //@ts-ignore
                    setText(e.target.value)
                }}/>
            </div>
            <div className="rounded-xl bg-white p-3 -ml-6 -mr-6 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none h-80 overflow-scroll">
                {loading && <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    }}
                >
                    <LoadingPurple style={{ height: 100, alignSelf: "center" }} />
                </div>}
                <ul>
                    {filteredPlans().map((plan) => (
                        <li
                            key={plan._id}
                            className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer"
                            onClick={() => onSelect(plan)}
                        >
                            <h3 className="text-sm font-medium leading-5">
                                {plan.title ?? plan.client.name}
                            </h3>

                            <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                <li>
                                    Criado por <strong>{plan.createdBy ?? '-'}</strong>
                                    &nbsp;em <strong>{moment(plan.createdAt).format("DD MMM, HH:mm")}</strong>
                                </li>
                                <li>&middot;</li>
                                <li>
                                    Atualizado por <strong>{plan.updatedBy ?? '-'}</strong>
                                    &nbsp;em <strong>{moment(plan.updatedAt).format("DD MMM, HH:mm")}</strong>
                                </li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
}

const PlanosAlimentares = () => {
    const { clientId } = useParams<ClientParams>();
    const [plans, setPlans] = useState<IPlan[]>([])
    const [loading, setLoading] = useState(false);
    const [isOpenTemplates, setIsOpenTemplates] = useState(false);
    const history = useHistory();

    const getAll = useCallback(async () => {
        setLoading(true)
        try {
            const response = await NutritionalPlanService.getClientPlans(clientId);
            const items = _.get(response, ["data", "data", "items"], []);
            setPlans(items)
        } catch(e) {
            NotificationManager.error("Erro ao obter planos");
        }
        setLoading(false)
    }, [clientId, setPlans, setLoading]);

    useEffect(() => {
        getAll();
    }, [getAll])

    const addPlan = useCallback(async (
        fromId: string | undefined = undefined
    ) => {
        if(!fromId) {
            const { isConfirmed } = await Swal.fire({
                title: `Deseja copiar os dados de um template?`,
                text: "Você pode escolher algum plano alimentar previamente cadastrado",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "var(--success)",
                confirmButtonText: "Sim",
                cancelButtonText: "Criar sem template",
            })
            setIsOpenTemplates(isConfirmed)
            if (isConfirmed) {
                return;
            }
        }
        
        await NutritionalPlanService.insertPlan({
            client: clientId
        }, fromId);
        getAll();
    }, [clientId, setIsOpenTemplates]);

    return (
        <div className="w-full">
            <PlanTemplates
                isOpen={isOpenTemplates}
                setIsOpen={setIsOpenTemplates}
                onSelectTemplate={(planId) => {
                    setIsOpenTemplates(false)
                    addPlan(planId)
                }}
            />

            <button
                className="bg-purple-800 text-white hover:bg-purple-900 p-2 pl-4 pr-4 flex items-center rounded transition-all mb-4"
                onClick={() => addPlan()}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                Adicionar novo plano
            </button>
            <div className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none">
                {loading && <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    }}
                >
                    <LoadingPurple style={{ height: 100, alignSelf: "center" }} />
                </div>}
                <ul>
                    {plans.map((plan) => (
                        <li 
                            key={plan._id}
                            className="relative rounded-md p-3 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                                history.push(`planoalimentar/${plan._id}`);
                            }}
                        >
                            <h3 className="text-sm font-medium leading-5">
                                {plan.title ?? '(Sem título)'}
                                {plan.active == true ?
                                    <span className="ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-green-200 text-green-700 rounded-full">
                                        Ativo
                                    </span>
                                :
                                    <span className="ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-orange-200 text-orange-700 rounded-full">
                                        Inativo
                                    </span>
                                }
                                
                                
                            </h3>

                            <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                <li>
                                    Criado por <strong>{plan.createdBy ?? '-'}</strong>
                                    &nbsp;em <strong>{moment(plan.createdAt).format("DD MMM, HH:mm")}</strong>
                                </li>
                                <li>&middot;</li>
                                <li>
                                    Atualizado por <strong>{plan.updatedBy ?? '-'}</strong>
                                    &nbsp;em <strong>{moment(plan.updatedAt).format("DD MMM, HH:mm")}</strong>
                                </li>
                            </ul>
                        </li>
                    ))}
                    
                </ul>
            </div>
        </div>
    )
};

const Index = () => {
    const { planId } = useParams<ClientParams>();
    return (
        planId ? <PlanoAlimentar /> : <PlanosAlimentares />
    )
}

export default Index;