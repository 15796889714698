import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../redux/ducks/Auth/selectors";

const PrivateRoute = (props) => {
  const isAutenticated = useSelector(selectIsAuthenticated);

  if (isAutenticated) {
    return <Route {...props} />;
  }

  return <Redirect to={{ pathname: "/login" }} />;
};

export default PrivateRoute;
