import styled from "styled-components";
import EnergySVG from "../../assets/icons/energy.svg";

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 17px;
  border-radius: 8px;
  background-color: var(--card-background);
  transition: all 0.3s ease, padding 0.8s linear;
  margin-top: 10px;
  margin-right: 10px;

  :hover {
    background-color: var(--hover-background);
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-family: var(--font-heavy);
  font-size: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--purple-light);
`;

export const Description = styled.span`
  font-weight: 900;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--text);
  margin-right: 14px;
  margin-bottom: 0;
  font-family: var(--font-heavy);
  font-size: 12px;
  text-align: right;
  margin-right: 0;
`;

export const Value = styled(Title)`
  font-size: 20px;
  letter-spacing: -0.01em;
  text-align: right;
`;

export const Calories = styled(Title)`
  font-size: 11px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-left: 3px;
  padding-top: 4px;
`;

export const EnergyIcon = styled.img.attrs({
  src: EnergySVG,
})`
  margin-right: 6px;
`;

export const Image = styled.img`
  width: 100px;
  height: 50px;
`;
