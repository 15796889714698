import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useField, useFormikContext } from "formik";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import {
  setToggled,
  setSearchText,
  setCurrentClient,
  requestClients,
} from "../../redux/ducks/Client/actions";
import { LeftPanel } from "../../components";
import {
  selectToggled,
  selectClients,
  selectCurrentClient,
  selectBiologicalGenre,
  selectAge,
  selectBirthDate,
  selectWeight,
  selectHeight,
  selectAllClients,
} from "../../redux/ducks/Client/selectors";
import {
  Container,
  ToggleButton,
  ToggleIcon,
  BackIcon,
  ProfileContainer,
  ProfileImage,
  ProfilePlanTitle,
  ProfilePlanSubtitle,
  ValueContainer,
  Label,
  Value,
  ListContainer,
  ListTitle,
  SearchContainer,
  SearchIcon,
  SearchInput,
  ClientListContainer,
  ClientItemContainer,
  ClientItemImage,
  ClientName,
  ClientPlan,
  AddButton,
  AddIcon,
  RemoveIcon,
  ClientItemWrapper,
  ClientFormListContainer,
} from "./styles";
import GoalService from "../../services/GoalService";
import ClientService from "../../services/ClientService";

function Clients() {
  const toggled = useSelector(selectToggled);
  const clients = useSelector(selectClients);
  const client = useSelector(selectCurrentClient);
  const biologicalGenre = useSelector(selectBiologicalGenre);
  const age = useSelector(selectAge);
  const birthDate = useSelector(selectBirthDate);
  const weight = useSelector(selectWeight);
  const height = useSelector(selectHeight);
  const [goal, setGoal] = useState({});
  const [info, setInfo] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(client)) {
      dispatch(requestClients());
      setInfo({});
    } else {
      getGoal(client._id);
      getInfo(client._id);
    }
  }, [dispatch, client]);

  const getGoal = async (client) => {
    const response = await GoalService.getLast(client);
    setGoal(response.data.data || {});
  };

  async function getInfo(client) {
    const response = await ClientService.info(client);
    const data = _.get(response, ["data", "data"], {});
    setInfo(data);
  }

  const updateGoal = async (goal) => {
    await GoalService.update(goal._id, goal);
  };

  const renderList = () => (
    <ListContainer visible={_.isEmpty(client)}>
      <ListTitle>Clientes</ListTitle>
      <SearchContainer>
        <SearchIcon />
        <SearchInput
          placeholder="Pesquisar Clientes"
          onChange={(e) => {
            dispatch(setSearchText(e.target.value));
          }}
        />
      </SearchContainer>

      <ClientListContainer>
        {clients.map((c) => (
          <ClientItemContainer
            key={c._id}
            onClick={() => {
              dispatch(setCurrentClient(c));
            }}
          >
            <ClientItemImage />
            <div>
              <ClientName>
                <strong>{c.firstName} </strong>
                {c.secondaryNames}
              </ClientName>
              <ClientPlan>HAUX LIFE + MED</ClientPlan>
            </div>
          </ClientItemContainer>
        ))}
      </ClientListContainer>
    </ListContainer>
  );

  const renderClient = () => (
    <ProfileContainer visible={!_.isEmpty(client)}>
      <ClientName
        style={{
          display: "flex",
          marginBottom: 34,
          width: "100%",
          fontSize: 32,
          lineHeight: 1,
        }}
      >
        <BackIcon onClick={() => dispatch(setCurrentClient(null))} />
        <strong>
          {_.get(client, ["firstName"])} {_.get(client, ["secondName"])}
        </strong>
      </ClientName>
      <ProfileImage />

      {_.get(info, ["pacotes"], []).map((pacote) => (
        <ProfilePlanTitle key={pacote.id}>{pacote.Nome}</ProfilePlanTitle>
      ))}
      <ProfilePlanSubtitle />

      <ValueContainer style={{ marginTop: 30 }}>
        <Label>Peso atual</Label>
        <Value>{weight} kg</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Meta de peso</Label>
        <Value>{_.get(goal, ["weight"])} kg</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Gênero Biológico</Label>
        <Value>{biologicalGenre.toUpperCase()}</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Altura</Label>
        <Value>{height}m</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Idade</Label>
        <Value>{age}</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Data de nascimento</Label>
        <Value>{birthDate}</Value>
      </ValueContainer>
      {!_.isEmpty(goal) && (
        <ValueContainer>
          <Label>Meta Calórica</Label>
          <Value>
            <select
              key={goal._id}
              defaultValue={[
                _.get(goal, "minCalories"),
                _.get(goal, "maxCalories"),
              ]}
              style={{ color: "white" }}
              onChange={(e) => {
                const [minCalories, maxCalories] = e.target.value.split(",");
                const newGoal = {
                  ...goal,
                  minCalories: Number(minCalories),
                  maxCalories: Number(maxCalories),
                };
                updateGoal(newGoal);
              }}
            >
              {[
                [1000, 1200],
                [1300, 1500],
                [1600, 1800],
                [1900, 2100],
                [2200, 2400],
                [2500, 2700],
                [2800, 3000],
                [3100, 3300],
                [3400, 3600],
                [3700, 3900],
              ].map(([min, max], index) => (
                <option
                  key={index}
                  value={[min, max]}
                >{`${min}-${max} KCal`}</option>
              ))}
            </select>
          </Value>
        </ValueContainer>
      )}
    </ProfileContainer>
  );

  return (
    <Container toggled={toggled}>
      {renderList()}
      {renderClient()}
      <ToggleButton onClick={() => dispatch(setToggled(!toggled))}>
        <ToggleIcon toggled={toggled} />
      </ToggleButton>
    </Container>
  );
}

export const AddClient = ({ name }) => {
  const dispatch = useDispatch();
  const [showClients, setShowClients] = useState(false);
  const allClients = useSelector(selectAllClients);
  const clients = useSelector(selectClients);
  // const [selected, setSelected] = useState([]);
  const [, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  const { value } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    dispatch(requestClients());
  }, [dispatch]);

  const getClientList = useCallback(() => {
    return clients.filter((c) => !(value || []).includes(c._id));
  }, [clients, value]);

  return (
    <ClientFormListContainer error={!!meta.error && submitCount}>
      <Row>
        <Col>
          {(value || []).map((id) => {
            let c = allClients.filter((cl) => cl._id === id);
            if (c.length === 0) {
              return <></>;
            }
            c = c[0];
            return (
              <ClientItemWrapper key={c._id}>
                <ClientName style={{ color: "var(--purple-light)" }}>
                  <strong>{c.firstName} </strong>
                  {c.secondaryNames}
                </ClientName>
                <RemoveIcon
                  onClick={() => {
                    setValue((value || []).filter((id) => id !== c._id));
                  }}
                />
              </ClientItemWrapper>
            );
          })}
        </Col>
        <Col>
          <div style={{ padding: 17 }}>
            <AddButton onClick={() => setShowClients(true)}>
              <AddIcon />
              Adicionar participantes
            </AddButton>
          </div>
        </Col>
      </Row>
      <LeftPanel open={showClients} onClose={() => setShowClients(false)}>
        <ListTitle style={{ color: "var(--purple)" }}>Clientes</ListTitle>
        <SearchContainer style={{ backgroundColor: "var(--card-background)" }}>
          <SearchIcon />
          <SearchInput
            placeholder="Pesquisar Clientes"
            onChange={(e) => {
              dispatch(setSearchText(e.target.value));
            }}
          />
        </SearchContainer>

        <ClientListContainer>
          {getClientList().map((c) => (
            <ClientItemContainer
              key={c._id}
              light={true}
              onClick={() => {
                setValue([...(value || []), c._id]);
                setShowClients(false);
              }}
            >
              <ClientItemImage />
              <div>
                <ClientName style={{ color: "var(--text)" }}>
                  <strong>{c.firstName} </strong>
                  {c.secondaryNames}
                </ClientName>
                <ClientPlan>HAUX LIFE + MED</ClientPlan>
              </div>
            </ClientItemContainer>
          ))}
        </ClientListContainer>
      </LeftPanel>
    </ClientFormListContainer>
  );
};

export default Clients;
