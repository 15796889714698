import { UniqueIdentifier } from "@dnd-kit/core";
import axios from "../middlewares/axios";

interface BoardDataColumn {
  id: UniqueIdentifier;
  title: string;
}

interface BoardDataTask {
  id: UniqueIdentifier;
  columnId: UniqueIdentifier;
  content: string;
}

interface BoardDataProps {
  type: string;
  columns: BoardDataColumn[];
  tasks: BoardDataTask[];
  franchise: string;
}

class BoardService {
  static getBoard(type: string, franchiseId: string) {
    const url = `/board/${type.toLowerCase()}?franchiseId=${franchiseId}`;
    return axios.get(url);
  }

  static findOneAndUpdate(data: BoardDataProps) {
    const url = `/board/${data.type.toLowerCase()}`;
    return axios.put(url, data);
  }
}

export default BoardService;
