import { createSelector } from "reselect";
import moment from "moment";
import _ from "lodash";

export const selectClientState = createSelector(
  (state) => state.client,
  (client) => client
);

export const selectAllClients = createSelector(
  selectClientState,
  (client) => {
    const clients =
      client.clients.map((c) => {
        const names = c.name.split(" ");
        const [firstName, ...secondaryNames] = names;
        const [secondName] = secondaryNames;

        return {
          ...c,
          firstName,
          secondName: secondName || "",
          secondaryNames: (secondaryNames || []).join(" "),
        };
      }) || [];
    return clients;
  }
);

export const selectClients = createSelector(
  selectClientState,
  (client) => {
    const clients =
      client.clients.map((c) => {
        const names = c.name.split(" ");
        const [firstName, ...secondaryNames] = names;
        const [secondName] = secondaryNames;

        return {
          ...c,
          firstName,
          secondName: secondName || "",
          secondaryNames: (secondaryNames || []).join(" "),
        };
      }) || [];
    return _.isEmpty(client.searchText)
      ? clients
      : clients.filter((c) =>
          c.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase()
            .includes(
              client.searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()
            )
        );
  }
);

export const selectCurrentClient = createSelector(
  selectClientState,
  (client) => client.client
);

export const selectLastAnamnesis = createSelector(
  selectClientState,
  (client) => client.lastAnamnesis
);

export const selectToggled = createSelector(
  selectClientState,
  (client) => client.toggled
);

export const selectBiologicalGenre = createSelector(
  selectCurrentClient,
  (client) =>
    client ? (client.biologicalSex === "F" ? "Mulher" : "Homem") : ""
);

export const selectGoal = createSelector(
  selectCurrentClient,
  (client) => (client ? client.goal || {} : {})
);

export const selectBirthDate = createSelector(
  selectCurrentClient,
  (client) =>
    client
      ? (moment(client.birthDate).isValid()
          ? moment(client.birthDate)
          : moment(client.birthDate, "DD/MM/YYYY")
        ).format("DD/MM/YYYY")
      : ""
);

export const selectAge = createSelector(
  selectCurrentClient,
  (client) =>
    client
      ? moment().diff(client.birthDate, "years") ||
        moment().diff(moment(client.birthDate, "DD/MM/YYYY"), "years")
      : ""
);

export const selectWeight = createSelector(
  selectLastAnamnesis,
  (anamnesis) => _.get(anamnesis, ["bodyComposition", "weight"]) || 0
);

export const selectHeight = createSelector(
  selectLastAnamnesis,
  (anamnesis) =>
    new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
      _.get(anamnesis, ["bodyComposition", "height"]) || 0
    )
);
