import { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import Pagination from './pagination';
import UserService, { IUser } from 'services/UserService';
import Swal from 'sweetalert2';
import _ from "lodash";

const UsersList = () => {
  const history = useHistory();
  const [users, setUsers] = useState<IUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(7);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      Swal.fire({
				title: 'Obtendo dados',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
						Swal.showLoading()
				}
			});
      const { items: users } = await UserService.getAll();
      setUsers(users);
      Swal.close();
    };

    fetchUsers();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const getFilteredUsers = useCallback(() => {
    if (!_.isEmpty(search)) {
      return users.filter((user) => (
        user.name.toUpperCase().includes(search.toUpperCase()) ||
        user.profileType.toUpperCase().includes(search.toUpperCase()) ||
        user.username.toUpperCase().includes(search.toUpperCase())
      ));
    }

    return currentUsers;
  }, [users, search, currentUsers])

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl capitalize mb-2" style={{
        fontFamily: "var(--font-heavy)",
        color: `var(--purple-dark)`
      }}>
        Usuários gerenciáveis
      </h1>
      <div className='flex justify-between'>
      <div className="relative w-96">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
          </div>
          <input type="text" value={search} onChange={(e) => {
            setSearch((e.target as any).value)
          }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#624E92] focus:border-[#624E92] block w-full pl-10 p-2.5" placeholder="Pesquisar" />
      </div>
        <button
          className="flex bg-[#624E92] hover:bg-[#3d2e61] text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            history.push('/user/form/')
          }}
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
          Novo usuário
        </button>
      </div>
      <table className="mt-8 table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 w-3/12">Login</th>
            <th className="px-4 py-2 w-4/12">Nome</th>
            <th className="px-4 py-2 w-2/12">Perfil</th>
            <th className="px-4 py-2 w-3/12"></th>
          </tr>
        </thead>
        <tbody className=''>
          {getFilteredUsers().map((user) => (
            <tr key={user._id} className='border'>
              <td className="border px-4 py-2">{user.username}</td>
              <td className="border px-4 py-2">{user.name}</td>
              <td className="border px-4 py-2">{user.profileType}</td>
              <td className="px-4 py-2 flex justify-center justify-items-center gap-2">
                <button
                  className="bg-[#624E92] hover:bg-[#3d2e61] text-white font-bold py-2 px-4 rounded"
                  onClick={() => {
                    history.push(`/user/permissions/${user._id}`)
                  }}
                >
                  Permissões
                </button>
                <button
                  className="flex items-center outline-none border hover:bg-purple-100 hover:text-purple-800 font-bold py-2 px-4 rounded w-40"
                  onClick={() => {
                    history.push(`/user/form/${user._id}`)
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-2 h-4 w-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                  </svg>
                  Editar usuário
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {_.isEmpty(search) && <Pagination
        itemsPerPage={usersPerPage}
        totalItems={users.length}
        paginate={paginate}
        currentPage={currentPage}
      />}
    </div>
  );
};

export default UsersList;
