import axios from "../middlewares/axios";

export interface IProduct {
    _id: string,
    title: string;
    description?: string;
    type: string;
}

export interface IProductItemPack {
  _id: string;
  product: IProduct;
  quantity: number;
  value: number;
  finished: [any];
  uses?: [{
    date: string;
    type: string;
    byName: string;
    byId: string;
  }]
}

export interface IProductPack {
  _id: string;
  client: string;
  products: [IProductItemPack];
  paymentMethod?: string;
  observations?: string;
  createdAt: string;
  updatedAt: string;
}

class ProductService {
  static async getFranchiseProducts(franchiseId: string) {
    const url = `/product/franchise/${franchiseId}`;
    const response = await axios.get<{
        data: IProduct[]
    }>(url);

    return response.data.data;
  }

  static async getAllPacks(clientId?: string) {
    const url = `/product/client?client=${clientId}`;
    const response = await axios.get<{
        data: {
          items: IProductPack[]
        }
    }>(url);

    return response.data.data.items;
  }

  static async insertPack(data: IProductPack) {
    const url = `/product/client`;
    const response = await axios.post(url, data);
    return response.data.data;
  }

  static async deletePack(packId: string) {
    const url = `/product/client/${packId}`;
    const response = await axios.delete(url);
    return response.data.data;
  }

  static async addFinished(packId: string, productId: string, date) {
    const url = `/product/client/finished/add/${packId}`;
    const response = await axios.put(url, {
      _id: productId,
      date
    });
    return response.data.data;
  }

  static async removeFinished(packId: string, productId: string, idDate: string) {
    const url = `/product/client/finished/remove/${packId}`;
    const response = await axios.put(url, {
      _id: productId,
      idDate
    });
    return response.data.data;
  }


}

export default ProductService;