import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "@unform/core";
import {
  Container,
  Label,
  Dropdown,
  DropdownContent,
  DropdownButton,
  ArrowDown,
} from "./styles";

const TextInput = ({ label, name, value, onChange, options }) => {
  const selectRef = useRef(null);
  const spanRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(
    name || "select"
  );

  useEffect(() => {
    const option = options.filter((o) => o.value === value);
    if (option.length > 0) {
      onSetOption(option[0]);
    }
  }, [value, options]);

  useEffect(() => {
    if (name) {
      registerField({
        name: fieldName,
        ref: selectRef.current,
        path: "value",
        getValue: (ref) => {
          if (value) {
            return value;
          }
          return (spanRef.current || {}).innerHTML;
        },
        setValue: (ref, value) => {
          options.forEach((o) => {
            if (o.value === value) {
              value = o.label;
            }
          });
          (spanRef.current || {}).innerHTML = value || options[0].label;
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, options, registerField]);

  const onSetOption = (option) => {
    spanRef.current.innerHTML = option.label;
  };

  return (
    <Container error={!!error}>
      <Label>{label}</Label>
      <Dropdown ref={selectRef}>
        <span ref={spanRef} style={{ marginRight: 30 }}>
          {defaultValue || options[0].label}
        </span>
        <ArrowDown />
        <DropdownContent>
          {options.map((option) => (
            <DropdownButton
              key={option.value}
              data-value={option.value}
              onClick={(e) => {
                if (onChange) {
                  onChange(option.value);
                } else {
                  onSetOption(option);
                }

                e.stopPropagation();
              }}
            >
              {option.label}
            </DropdownButton>
          ))}
        </DropdownContent>
      </Dropdown>
    </Container>
  );
};

TextInput.defaultProps = {
  options: [],
};

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
};

export default TextInput;
