import React, { useState } from "react";
import NumberInput from "../NumberInput";
import { Row, Col } from "reactstrap";
import { Container, LabelWrapper, Label } from "./styles";
import TextInput from "../TextInput";

function Exam({ label, name, references, ...props }) {
  const [referenceIndex, setReferenceIndex] = useState(-1);

  const Class = props.suffix ? NumberInput : TextInput;

  return (
    <Container>
      <Row>
        <Col>
          <Class
            label={label}
            name={name}
            {...props}
            onChange={(e) => {
              if (props.suffix) {
                const number = e.target.value
                  .replace(/[./A-Za-z %/]+/g, "")
                  .replace(",", ".");
                let rIndex = -1;
                references.forEach((r, i) => {
                  if (r.min <= number && r.max > number) {
                    rIndex = i;
                  }
                });
                setReferenceIndex(rIndex);
              }
            }}
          />
        </Col>
        <Col>
          <LabelWrapper>
            {references.map((r, i) => (
              <Label key={i} selected={referenceIndex === i}>
                {r.label}
              </Label>
            ))}
          </LabelWrapper>
        </Col>
      </Row>
    </Container>
  );
}

export default Exam;
