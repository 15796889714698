import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 17px;
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease, padding 0.8s linear;

  background-color: ${({ error }) =>
    error ? "var(--danger-form)" : "transparent"};

  :hover {
    background-color: var(--hover-background);
  }
`;

export const Label = styled.label`
  font-family: var(--font-heavy);
  font-size: ${(props) => (props.compact ? 12 : 15)}px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
  margin-bottom: 0;
`;

export const Input = styled.input`
  font-family: var(--font-heavy);
  font-size: ${(props) => (props.compact ? 20 : 26)}px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--purple);

  ::placeholder {
    color: var(--text-light);
  }
`;

export const InputCondensed = styled(Input)`
  font-size: 20px;
`;
