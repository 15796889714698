import React, { InputHTMLAttributes, useCallback } from "react";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";
// //import "./styles.scoped.scss";
import _ from "lodash";

interface Option {
  label: string;
  value: string | number;
}

interface CheckProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  compact?: boolean;
  name: string;
  direction?: "row" | "column";
  options?: Option[] | string[];
  periods: string[];
  frequecies: string[];
  renderOther?: React.ReactNode;
}

const CheckGroup: React.FC<CheckProps> = (props) => {
  const { label, name, options } = props;
  const { control, setValue } = useFormContext();

  const getOptions = useCallback((): Option[] => {
    return options?.map((option: string | Option) =>
      _.isString(option)
        ? {
            label: option,
            value: option,
          }
        : option
    ) as Option[];
  }, [options]);

  const isSelected = (value, field) => {
    return (field.value || []).filter((v) => v.value === value).length > 0
  }

  const isOther = (field) => {
    const values = field.values || [];
    return values.filtered((v) => v.value &&
    (v.value.includes("Outro") ||
    v.value.includes("Outros") ||
    v.value.includes("Outra") ||
    v.value.includes("Outras"))) (
    );
  };

  const getValue = (value, field) => {
    const values = field.value || [];
    const filtered = values.filter((v) => v.value === value);
    return filtered.length > 0 ? filtered[0] : {};
  }

  const setNewValue = (value, key, keyValue, field) => {
    const values = field.value || [];
    const newValues = values.map((v) => v.value === value ? {
      ...v,
      [key]: keyValue
    } : v)
    setValue(name, newValues, { shouldTouch: true })
  }

  return (
    <Controller
      render={({ field }) => (
        <>
          <div className="flex flex-col w-full">
            <label className="font-bold uppercase">{label}</label>
            <div className="flex flex-row flex-wrap">
              {getOptions().map((option) => (
                <div
                  key={option.value}
                  className={`
                    font-bold
                    h-fit
                    uppercase
                    text-left
                    border
                    border-solid
                    rounded-lg
                    p-5
                    mt-3
                    mr-3
                    transition-all
                    ${(isSelected(option.value, field)) ? 'border-teal-400 bg-teal-400 text-white hover:bg-teal-500': 'border-gray-400 text-gray-400 hover:border-gray-300 hover:bg-gray-300 hover:text-white'}
                  `}
                  onClick={() => {
                    if (isSelected(option.value, field)) {
                      setValue(
                        name,
                        (field.value || []).filter((v) => v.value !== option.value),
                        { shouldTouch: true }
                      );
                    } else {
                      setValue(name, [...(field.value || []), { value: option.value }], 
                      { shouldTouch: true });
                    }
                  }}
                >
                  {option.label}
                  {isSelected(option.value, field) &&
                    <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 pt-8 text-xs" onClick={(e) => e.stopPropagation()}>
                        <div className='col-span-4 flex flex-col'>
                        <label>Frequência</label>
                        <select value={getValue(option.value, field).frequency}
                        className="bg-transparent rounded border-white"
                        onChange={(e) => {
                          //@ts-ignore
                          setNewValue(option.value, "frequency", e.target.value, field)
                        }}>
                          {props.frequecies.map((o) => 
                            <option value={o}>{o}</option>
                          )}
                        </select>
                        </div>
                        <div className='col-span-4 flex flex-col'>
                            <label>Período</label>
                            <select
                            className="bg-transparent rounded border-white text-white color-white"
                            value={getValue(option.value, field).period}
                            onChange={(e) => {
                            //@ts-ignore
                            setNewValue(option.value, "period", e.target.value, field)
                            }}
                            >
                            {props.periods.map((o) => 
                                <option value={o}>{o}</option>
                            )}
                            </select>
                        </div>
                    </div>
                  }
                </div>
              ))}
            </div>
            
          </div>
          {props.renderOther && (
              <div
                className="pt-2"
                style={{ display: isOther(field.value) ? "block" : "none" }}
              >
                {props.renderOther}
              </div>
            )}
        </>
      )}
      control={control}
      name={name}
    />
  );
};

export default CheckGroup;
