import CLIENT from "./types";
import Immutable from "seamless-immutable";

export const INITIAL_STATE = Immutable({
  loading: undefined,
  toggled: false,
  searchText: "",
  clients: [],
  client: null,
  lastAnamnesis: {},
});

export const setToggled = (state, { toggled }) => state.merge({ toggled });

export const setSearchText = (state, { text }) =>
  state.merge({ searchText: text });

export const saveRequest = (state) => state.merge({ loading: true });

export const saveSuccess = (state) => state.merge({ loading: false });

export const saveFail = (state, { error }) =>
  state.merge({ loading: undefined, error });

export const setAnamnesisIndex = (state, { anamnesisIndex }) =>
  state.merge({ anamnesisIndex });

export const requestClients = (state) => state.merge({ loading: true });

export const requestClientsSuccess = (state, { clients }) =>
  state.merge({ loading: false, clients });

export const setCurrentClient = (state, { client }) =>
  state.merge({ client, searchText: "" });

export const setLastAnamnesis = (state, { anamnesis }) =>
  state.merge({ lastAnamnesis: anamnesis });

const ClientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLIENT.SET_TOGGLED:
      return setToggled(state, action);
    case CLIENT.SET_SEARCH_TEXT:
      return setSearchText(state, action);
    case CLIENT.GET_ALL:
      return requestClients(state, action);
    case CLIENT.GET_ALL_SUCCESS:
      return requestClientsSuccess(state, action);
    case CLIENT.SET_CURRENT_CLIENT:
      return setCurrentClient(state, action);
    case CLIENT.SET_LAST_ANAMNESIS:
      return setLastAnamnesis(state, action);
    default:
      return state;
  }
};

export default ClientReducer;
