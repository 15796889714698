import axios from "../middlewares/axios";

class AnamnesisService {
  static getAllAnamnesis(anamnesis, clientId) {
    const url = `/anamnesis/${anamnesis}?client=${clientId}`;
    return axios.get(url);
  }

  static getAll(anamnesis) {
    const url = `/anamnesis/${anamnesis}`;
    return axios.get(url);
  }

  static getById(anamnesis, id) {
    const url = `/anamnesis/${anamnesis}/${id}`;
    return axios.get(url);
  }

  static getFinishedAnamnesis(anamnesis, clientId) {
    const url = `/anamnesis/${anamnesis}?client=${clientId}&finish=true`;
    return axios.get(url);
  }

  static insertAnamnesis(anamnesis, data) {
    const url = `/anamnesis/${anamnesis}`;
    return axios.post(url, data);
  }

  static updateAnamnesis(anamnesis, id, data) {
    const url = `/anamnesis/${anamnesis}/${id}`;
    return axios.put(url, data);
  }

  static deleteAnamnesis(anamnesis, anamnesisId) {
    const url = `/anamnesis/${anamnesis}/${anamnesisId}`;
    return axios.delete(url);
  }
}

export default AnamnesisService;
