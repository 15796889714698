import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

interface IToggle {
  toggled: boolean;
}
export const ToggleContainer = styled.div<IToggle>`
  position: relative;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  width: ${({ toggled }) => (toggled ? 370 : 20)}px;
  background-color: var(--purple);
  transition: all 0.3s ease, padding 0.8s linear;
  user-select: none;
`;

export const PageContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  height: 100vh;
`;

export const ListAnamnesisContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 17px;
  border-radius: 8px;
  background: var(--card-background);
  overflow: hidden;
`;

export const ListAnamnesisItem = styled.div`
  padding: 17px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-family: var(--font-heavy);
  color: var(--text);
  transition: all 0.3s ease;
  cursor: pointer;

  :hover {
    color: var(--purple-dark);
    background-color: var(--hover-background);
  }
`;

export const Encontro = styled.button`
  font-family: var(--font-heavy);
  color: white;
  background-color: var(--pink);
  height: 38px;
  width: 38px;
  border-radius: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  transition: all 0.3s ease, padding 0.8s linear;

  :disabled {
    background-color: var(--text-light);
  }
`;

export const Section = styled.div`
  align-items: flex-start;
  padding: 32px 32px 16px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export {};
