import { call, put } from "redux-saga/effects";
import { path } from "ramda";
import {
  getAllAnamnesisSuccess,
  saveAnamnesisSuccess,
  saveAnamnesisFail,
} from "../ducks/Anamnesis/actions";
import AnamnesisService from "../../services/AnamnesisService";
import MealsService from "../../services/MealsService";

export function* getAllAnamnesis({ anamnesis, clientId }) {
  try {
    const response = yield call(
      AnamnesisService.getAllAnamnesis,
      anamnesis,
      clientId
    );
    if (response.problem) throw new Error(response.problem);

    let list = path(["data", "data", "items"], response).reverse();
    if (list.length > 0) {
      const { _id, ...lastItemData } = list[list.length - 1];
      list.push(lastItemData);
    } else {
      list.push({});
    }

    const minListLength = 6;
    if (list.length < minListLength) {
      for (let i = list.length; i < minListLength; i++) {
        list.push({ disabled: true });
      }
    }

    yield put(getAllAnamnesisSuccess(list));
  } catch (e) {
    console.error(e);
  }
}

export function* insertAnamnesis({ anamnesis, data }) {
  try {
    const response = yield call(
      AnamnesisService.insertAnamnesis,
      anamnesis,
      data
    );
    if (response.problem) throw new Error(response.problem);

    if (anamnesis === "nutri" && !data._id) {
      yield call(MealsService.generateWeekMeals, data.client);
    }
    yield put(saveAnamnesisSuccess());
  } catch (e) {
    yield put(saveAnamnesisFail());
  }
}
