import { reloadAxiosInstance } from "../../middlewares/axios";
import { call, put } from "redux-saga/effects";
import { path } from "ramda";
import { authSuccess, authFail } from "../ducks/Auth/actions";
import AuthService from "../../services/AuthService";

export function* auth({ username, password }) {
  try {
    const response = yield call(AuthService.auth, username, password);

    const token = path(["data", "data", "token"], response);
    const user = path(["data", "data", "user"], response);
    localStorage.setItem("TOKEN_KEY_V3", token);
    reloadAxiosInstance();
    yield put(authSuccess(user, token));
  } catch (e) {
    const error = path(["response", "data", "data"], e);
    yield put(authFail(error));
  }
}
