import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { selectUser } from "../redux/ducks/Auth/selectors";
import { authSuccess } from "../redux/ducks/Auth/actions";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { Menu } from "../components";
import { Container, PageContainer } from "./styles";
import SignIn from "../pages/SignIn";
import Anamnesis from "../pages/Anamnesis";
import Chat from "../pages/Chat";
import Client from "../pages/Client";
import User from "../pages/User";

import ProfessionalsRoutes from "./Professionals";

function Routes() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  return (
    <Container>
      <Menu
        onUserClick={() => {
          localStorage.clear();
          dispatch(authSuccess(null));
        }}
      />
      <PageContainer>
        <Router>
          <Switch>
            <PublicRoute path="/login" component={SignIn} />
            <PrivateRoute path="/anamnesis" component={Anamnesis} />
            <PrivateRoute path="/chat" component={Chat} />
            <PrivateRoute path="/planoalimentar" component={Anamnesis} />
            <PrivateRoute
              path="/client/:clientId?/:anamnesisType?/:anamnesisId?/:meetingNumber?"
              component={Client}
            />
            <PrivateRoute path="/user/:userId?" component={User} />
            {!user && <Redirect from="/" to="login" />}
            {ProfessionalsRoutes.map((route) => route)}
          </Switch>
        </Router>
      </PageContainer>
    </Container>
  );
}

export default Routes;
