import styled from "styled-components";

export const SuccessButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-heavy);
  font-size: 15px;
  background-color: var(--success);
  color: #fff;
  padding: 5px 24px;
  min-height: 50px;
  border-radius: 8px;
  text-transform: uppercase;

  :hover {
    background-color: var(--success-light);
  }
`;
