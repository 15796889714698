import axios from "../middlewares/axios";

class FileService {
  static uploadImage(form) {
    const url = "/file/uploadImage";
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, form, config);
  }

  static uploadClientAttachment(form, onUploadProgress) {
    const url = "/file/uploadClientAttachment";
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress,
    };
    return axios.post(url, form, config);
  }

  static deleteClientAttachment(fileName) {
    const url = `/file/deleteClientAttachment?fileName=${fileName}`;
    return axios.delete(url);
  }

  static getAllClientAttachments(client, preffix) {
    const url = `/file/getAllClientAttachments?client=${client}&preffix=${
      preffix ? preffix : ""
    }`;

    return axios.get(url);
  }
}

export default FileService;
