import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Content,
  Header,
  Title,
  Subtitle,
  ArrowDown,
} from "./styles";
import _ from "lodash";

function Collapse({ title, subtitle, color, children, collapse = false }) {
  const [collapsed, setCollapsed] = useState(collapse);

  return (
    <Container>
      <Header type="button" onClick={() => setCollapsed(!collapsed)}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!_.isEmpty(subtitle) && <Subtitle>{subtitle}</Subtitle>}
          <Title style={{ color: `var(--${color})` }}>{title}</Title>
        </div>
        <ArrowDown collapsed={collapsed} />
      </Header>
      <Content collapsed={collapsed}>
        <div>{children}</div>
      </Content>
    </Container>
  );
}

PropTypes.defaultprops = {
  subtitle: "",
  color: "purple",
};

PropTypes.propTypes = {
  title: PropTypes.string,
  collapse: PropTypes.bool,
  children: PropTypes.node,
};

export default Collapse;
