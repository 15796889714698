import React, { useRef } from "react";

import {
  Row,
  ItemWrapper,
  Title,
  Tag,
  Description,
  Value,
  Calories,
  EnergyIcon,
  Input,
} from "./styles";

function FoodItem({ food, selected, canEdit, onClick, onChange }) {
  const inputRef = useRef();
  return (
    <ItemWrapper
      selected={selected}
      onClick={onClick}
      onMouseEnter={() => {
        if (inputRef.current) inputRef.current.focus();
      }}
    >
      <Row>
        <Title>{food.description}</Title>

        <Row>
          <Row>
            <EnergyIcon />
            <Value>
              {Intl.NumberFormat("pt-BR", {
                maximumFractionDigits: 1,
              }).format(
                food.energyKcal *
                  (food.portionProportion === undefined
                    ? 1
                    : food.portionProportion)
              )}
            </Value>
            <Calories>KCAL</Calories>
          </Row>
        </Row>
      </Row>

      <Row style={{ alignItems: "start" }}>
        <Description>{food.category}</Description>
      </Row>

      <Row>
        {food.tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </Row>

      {canEdit && (
        <Row style={{ marginTop: -25 }}>
          <div></div>
          <div>
            <Input
              ref={inputRef}
              defaultValue={100}
              value={food.portion}
              onChange={(e) => {
                const portion = isNaN(
                  Number(e.target.value.length === 0 ? 0 : e.target.value)
                )
                  ? 100
                  : Number(e.target.value);
                const portionProportion = portion / 100;
                onChange({
                  ...food,
                  portion,
                  portionProportion,
                });
              }}
            />
            <Calories>gramas</Calories>
          </div>
        </Row>
      )}
    </ItemWrapper>
  );
}

export default FoodItem;
