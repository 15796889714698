import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0px solid var(--neutral-color);
  border-bottom-width: 1px;
  padding-bottom: 16px;
`;
