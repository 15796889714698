import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 17px;
  width: 100%;
  margin-bottom: ${(props) => (props.compact ? 0 : 20)}px;
  border-radius: 8px;
  transition: all 0.3s ease, padding 0.8s linear;
  background-color: ${({ error }) =>
    error ? "var(--danger-form)" : "transparent"};

  :hover {
    background-color: ${({ error }) =>
      error ? "var(--danger-form)" : "var(--hover-background)"};
  }
`;

export const Label = styled.label`
  font-family: var(--font-heavy);
  font-size: ${(props) => (props.compact ? 12 : 15)}px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
  margin-bottom: 0;
`;

export const Input = styled.input`
  font-family: ${(props) =>
    props.compact ? "var(--font-heavy)" : "var(--font-medium)"};
  font-size: ${(props) => (props.compact ? 20 : 16)}px;
  letter-spacing: -0.01em;
  line-height: 16px;
  text-align: left;
  color: var(--purple);
  margin-top: ${(props) => (props.compact ? 0 : 7)}px;

  ::placeholder {
    font-family: var(--font-medium);
  }
`;
