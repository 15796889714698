import React from "react";
import Loading from "../Loading";
import { FooterContainer, SaveButton } from "./styles";

interface ISaveBottom {
  saving: boolean;
  touched: boolean;
  showPublish?: boolean;
  clientToggled?: boolean;
  color?: string;
  onSave?: Function;
  onDelete?: Function;
  published?: boolean;
  style?: any;
}

const SaveBottom: React.FC<ISaveBottom> = ({
  saving,
  touched,
  clientToggled,
  showPublish,
  color,
  onSave,
  onDelete,
  published,
  style
}) => {
  return (
    <FooterContainer visible={touched} clientToggled={clientToggled || true} style={style}>
      VOCÊ FEZ ALTERAÇÕES
      <div style={{ display: "flex" }}>
        <SaveButton
          type={onSave ? "button" : "submit"}
          //@ts-ignore
          onClick={onSave}
        >
          {saving && <Loading />}
          {saving ? "Salvando" : "Salvar"} Alterações
        </SaveButton>
        {onDelete && <SaveButton
          type="button"
          //@ts-ignore
          onClick={onDelete}
          style={{
            marginLeft: 10,
            backgroundColor: `var(--danger-form)`,
          }}
        >
          Deletar
        </SaveButton>}
        {showPublish && (
          <SaveButton
            type="submit"
            style={{
              marginLeft: 10,
              backgroundColor: `var(--${color || "success"})`,
            }}
          >
            {published ? (<>Publicado</>) : <>Publicar Ficha</>}
          </SaveButton>
        )}
      </div>
    </FooterContainer>
  );
};

export const ButtonSave = SaveButton;

export default SaveBottom;
