import React from "react";
import PropTypes from "prop-types";
import { Container, HR } from "./styles";

const H1 = ({ children, showLine, color, ...props }) => {
  return (
    <Container color={color} {...props}>
      {children}
      {showLine && <HR />}
    </Container>
  );
};

H1.defaultProps = {
  color: "tech",
  showLine: true,
};

H1.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  showLine: PropTypes.bool,
};

export default H1;
