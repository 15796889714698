import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "@unform/core";
import NumberFormat from "react-number-format";
import { Row, Col } from "reactstrap";
import { Select } from "../../components";
import { Container, Label, Input, InputCondensed } from "./styles";

const NumberInput = ({
  label,
  name,
  showInput,
  showMinMax,
  minMaxNames,
  minMaxIds,
  showClassification,
  classificationId,
  classifications,
  compact,
  ...rest
}) => {
  const inputRef = useRef();
  const minRef = useRef();
  const maxRef = useRef();
  const field = useField(name);
  const minField = useField(
    minMaxIds[0] ? minMaxIds[0] : `references.${name}.min`
  );
  const maxField = useField(
    minMaxIds[1] ? minMaxIds[1] : `references.${name}.max`
  );

  const getValue = useCallback(
    (ref) => {
      if (ref.value.length === 0) {
        return;
      }

      const number = ref.value.replace(/[./A-Za-z %/]+/g, "").replace(",", ".");
      return isNaN(number) || name === "bloodPressure"
        ? ref.value.replace(rest.suffix, "").trim()
        : Number(number);
    },
    [rest.suffix, name]
  );

  useEffect(() => {
    if (showInput) {
      field.registerField({
        name: field.fieldName,
        ref: inputRef.current,
        path: "value",
        getValue: (ref) => {
          return getValue(ref);
        },
        setValue: (ref, value) => {
          ref.value =
            Number(value).toFixed(2).replace(".", ",") + (rest.suffix || "");
        },
      });
    }
  }, [showInput, field, getValue, rest.suffix]);

  useEffect(() => {
    if (showMinMax) {
      minField.registerField({
        name: minField.fieldName,
        ref: minRef.current,
        path: "value",
        getValue: (ref) => {
          return getValue(ref);
        },
      });
      maxField.registerField({
        name: maxField.fieldName,
        ref: maxRef.current,
        path: "value",
        getValue: (ref) => {
          return getValue(ref);
        },
      });
    }
  }, [minField, maxField, getValue, showMinMax]);

  return (
    <Container
      error={!!field.error || !!minField.error || !!maxField.error}
      onMouseEnter={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      <Label style={{ marginBottom: showInput ? 0 : 8 }} compact={compact}>
        {label}
      </Label>
      {showInput && (
        <NumberFormat
          decimalSeparator=","
          thousandSeparator="."
          customInput={Input}
          compact={compact}
          getInputRef={(ref) => (inputRef.current = ref)}
          placeholder={`__ ${rest.suffix || ""}`}
          {...rest}
          defaultChecked={false}
          defaultValue={field.defaultValue}
        />
      )}
      {showMinMax && (
        <Row>
          <Col
            xs="12"
            sm="12"
            lg="6"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Label style={{ fontSize: 12 }}>{minMaxNames[0]}</Label>
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              customInput={InputCondensed}
              getInputRef={(ref) => (minRef.current = ref)}
              placeholder={`__ ${rest.suffix || ""}`}
              {...rest}
              defaultValue={minField.defaultValue}
            />
          </Col>
          <Col
            xs="12"
            sm="12"
            lg="6"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Label style={{ fontSize: 12 }}>{minMaxNames[1]}</Label>
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              customInput={InputCondensed}
              getInputRef={(ref) => (maxRef.current = ref)}
              placeholder={`__ ${rest.suffix || ""}`}
              {...rest}
              defaultValue={maxField.defaultValue}
            />
          </Col>
        </Row>
      )}
      {showClassification && (
        <Row>
          <Select
            name={classificationId}
            label="Classificação"
            options={classifications}
          />
        </Row>
      )}
    </Container>
  );
};

NumberInput.defaultProps = {
  label: "",
  showInput: true,
  showMinMax: false,
  minMaxNames: ["Min", "Máx"],
  minMaxIds: [],
  showClassification: false,
  classificationId: "",
  compact: false,
};

NumberInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  compact: PropTypes.bool,
  showInput: PropTypes.bool,
  showMinMax: PropTypes.bool,
  minMaxNames: PropTypes.arrayOf(PropTypes.string),
  minMaxIds: PropTypes.arrayOf(PropTypes.string),
  showClassification: PropTypes.bool,
  classificationId: PropTypes.string,
  classifications: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
};

export default NumberInput;
