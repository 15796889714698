import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";
import PropTypes from "prop-types";
import { Container, Label, Input } from "./styles";

const TextInput = ({ label, name, compact, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container
      compact={compact}
      error={!!error}
      onMouseEnter={() => {
        inputRef.current.focus();
      }}
    >
      <Label compact={compact}>{label}</Label>
      <Input
        compact={compact}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </Container>
  );
};

TextInput.defaultProps = {
  compact: false,
};

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  compact: PropTypes.bool,
};

export default TextInput;
