import { useState, useCallback, useMemo } from 'react';
import Swal from "sweetalert2";
import { Modal } from 'molecules/Modal';
import { TextInput, TextArea } from "atoms";
import {
	FormProvider,
	useForm,
} from "react-hook-form";

interface UseOneFieldModalData {
	title?: string,
	label: string,
	name: string,
	multiline?: boolean;
	onSubmit?: (value: string) => void;
}

export const useOneFieldModal = ({ 
	title,
	label,
	name,
	multiline,
	onSubmit
}: UseOneFieldModalData): [
	React.FunctionComponent,
	Function,
] => {
	const methods = useForm();
	const { handleSubmit } = methods;
	const [isOpen, setIsOpen] = useState(false);

	const onFormSubmit = useCallback(async (data) => {
		try {
			const fieldValue = data[name];
			Swal.fire({
				title: 'Salvando',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			})
			await onSubmit?.(fieldValue);
			Swal.close();
			setIsOpen(false)
		} catch(e) {
			Swal.fire('Erro ao salvar', '', 'error')
		}
	}, []);

	const toggle = useCallback(() => {
		setIsOpen(state => !state);
	}, [])

	const OneFieldModal = useMemo(() => {

		const InputComponent = multiline ? TextArea : TextInput;

		const OneFieldComponent: React.FC = (() => {
			return (
				<Modal
					open={isOpen}
					title={title}
					toggleModal={toggle}
					onSubmit={() => handleSubmit(onFormSubmit)()}
				>
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onFormSubmit)}>
							<InputComponent label={label} name={name} />
						</form>
					</FormProvider>
				</Modal>
			)
		});

		return OneFieldComponent;
	}, [isOpen]);

	return ([
		OneFieldModal,
		toggle
	]);
  };