import NavBar from "molecules/NavBar";

const Chat: React.FC = () => {
  return (
  <div className="w-full h-full relative">
    <NavBar />
    <iframe className="-mt-4" src={`${process.env.REACT_APP_URL}/login?redirect=messages`} title="Chat" area-frameborder="0" style={{ overflow: "hidden", maxHeight:"calc(100% - 80px)",  height:"calc(100% - 80px)", width:"100%" }}>
    </iframe>
  </div>
)};

export default Chat;