import styled from "styled-components";
import ProfileSVG from "../../../assets/images/profile.svg";
import SearchSVG from "../../../assets/icons/search.svg";

interface IProfile {
  visible: boolean;
}
export const ProfileContainer = styled.div<IProfile>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 370px;
  max-height: 100%;
  padding-top: 28px;
  padding-left: 28px;
  padding-right: 48px;
  z-index: 1000;
  transition: all 0.3s ease;
  transform: ${({ visible }) =>
    !visible ? "translateX(100%)" : "translateX(0)"};
`;

export const ListContainer = styled(ProfileContainer)`
  position: absolute;
  padding-top: 28px;
  align-items: flex-start;
  transition: all 0.3s ease;
  transform: ${({ visible }) =>
    !visible ? "translateX(-100%)" : "translateX(0)"};
`;

export const ListTitle = styled.h1`
  font-family: var(--font-heavy);
  font-size: 32px;
  color: #fff;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 43px;
  min-height: 43px;
  background-color: #fff;
  border-radius: 8px;
  margin: 15px 0;
  padding: 0 10px;
`;

export const SearchIcon = styled.img.attrs({
  src: SearchSVG,
})`
  width: 14px;
  margin: 0 10px;
`;

export const SearchInput = styled.input`
  font-family: var(--font-heavy);
  font-size: 14px;
  height: 43px;
  width: 100%;
  color: var(--text);
  outline: none;

  ::placeholder {
    font-family: var(--font-medium);
    color: var(--text);
  }
`;

export const ClientListContainer = styled.div`
  max-height: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ClientItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  :hover {
    background-color: var(--purple-dark);
  }
`;

export const ClientItemImage = styled.img.attrs({
  src: ProfileSVG,
})`
  border-width: 2px;
  border-style: solid;
  border-color: #c4c4c4;
  border-radius: 27px;
  width: 54px;
  height: 54px;
  margin-right: 14px;
`;

export const ClientName = styled.p`
  font-family: var(--font-regular);
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
  align-items: center;

  strong {
    font-family: var(--font-heavy);
  }
`;

export const ClientPlan = styled.p`
  font-family: var(--font-heavy);
  font-size: 13px;
  color: var(--text);
  margin-bottom: 0;
`;

export {};
