import styled from "styled-components";

export const Container = styled.h1`
  font-family: var(--font-heavy);
  font-size: 26px;
  color: var(--${(props) => props.color});
  padding: 17px;
  margin-bottom: 0;
`;

export const HR = styled.hr`
  margin-top: 12px;
  margin-bottom: 0px;
  border-top: 1px solid var(--text-light);
`;
