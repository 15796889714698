import React from "react";
import {
  Row,
  ItemWrapper,
  Title,
  Description,
  Value,
  Calories,
} from "./styles";

interface ITraining {
  name: string;
  description?: string;
  when: string;
  minutes: number;
}

const Food: React.FC<ITraining> = ({ name, description, minutes, when }) => {
  return (
    <ItemWrapper>
      <Row>
        <Row
          style={{ flex: 1, flexDirection: "column", alignItems: "flex-start" }}
        >
          <Row style={{ width: "100%" }}>
            <Title style={{ marginRight: 35 }}>{(name || "").toUpperCase()}</Title>

            <Row>
              <Row>
                <Value>
                  {Intl.NumberFormat("pt-BR", {
                    maximumFractionDigits: 1,
                  }).format(minutes)}
                </Value>
                <Calories>MINUTOS</Calories>
              </Row>
            </Row>
          </Row>

          {description && description.split("\n").map((desc) => (
            <Row key={desc} style={{ alignItems: "start" }}>
              <Description>{desc}</Description>
            </Row>
          ))}

          <Row style={{ alignItems: "start", marginTop: description ? 20 : 0 }}>
            <Description>{when}</Description>
          </Row>
        </Row>
      </Row>
    </ItemWrapper>
  );
};

export default Food;
