import { useCallback } from "react";
import * as Yup from "yup";

const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        console.log("errr", {
          values: {},
          //@ts-ignore
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        })
        return {
          values: {},
          //@ts-ignore
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
  );

const FoodSchema = Yup.object().shape({
  description: Yup.string()
    .required("Campo obrigatório"),
  portion: Yup.number().required("Campo obrigatório").min(1, "Valor mínino é 1"),
  calories: Yup.number().required("Campo obrigatório"),
  protein: Yup.number().required("Campo obrigatório"),
  lipids: Yup.number().required("Campo obrigatório"),
  carbohydrate: Yup.number().required("Campo obrigatório"),
});

export { useYupValidationResolver, FoodSchema };