import styled from "styled-components";
import UploadSVG from "../../assets/icons/upload.svg";
import CheckSVG from "../../assets/icons/check.svg";
import LoadingSVG from "../../assets/icons/loading.svg";

export const Container = styled.div`
  padding: 0 17px;
  font-family: var(--font-medium);
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 16px;
  text-align: center;
  color: var(--text);
  margin-bottom: 50px;
`;

export const Label = styled.label`
  font-family: var(--font-heavy);
  width: 100%;
  font-size: 15px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
  margin-bottom: 15px;
`;

export const DragContainer = styled.div`
  height: 296px;
  background-color: ${({ error }) =>
    error ? "var(--danger-form)" : "var(--card-background)"};
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ error }) =>
    error ? "var(--danger)" : "rgba(112, 112, 112, 0.34)"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;

  :hover {
    opacity: 0.48;
  }
`;

export const UploadImage = styled.img.attrs({
  src: UploadSVG,
})`
  margin-bottom: 13px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 37px;
  min-width: 120px;
  cursor: pointer;
`;

export const ListItemCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border-style: solid;
  border-width: ${({ checked }) => (checked ? 0 : 1)}px;
  border-color: var(--text);
  background-color: ${({ checked }) =>
    checked ? "var(--success)" : "transparent"};
  margin-right: 11px;
`;

export const Check = styled.img.attrs({
  src: CheckSVG,
})`
  display: none;
`;

export const Loading = styled.img.attrs({
  src: LoadingSVG,
})`
  width: 25px;
  margin-left: 7px;
  display: none;
`;
