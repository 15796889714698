import * as Yup from "yup";
import moment from "moment";

const REQUIRED = 'Campo obrigatório'

const schema = Yup.object().shape({
    name: Yup.string().required(REQUIRED),
    birthDate: Yup.string().required(REQUIRED).test('formato', 'Formato inválido, DD/MM/YYYY', (value) => {
        return moment(value, "DD/MM/YYYY").isValid
    }),
    franchise: Yup.number().required(REQUIRED),
    rg: Yup.string(), 
    cpf: Yup.string().required(REQUIRED).test('formato', 'Digite um CPF válido', (value) => {
        return value?.match(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/)
    }), 
    biologicalSex: Yup.string().required(REQUIRED),
    cellphone: Yup.string().required(REQUIRED).min(15, 'Digite um celular válido'),
    email: Yup.string().required(REQUIRED).test('formato', 'Digite um e-mail válido', (value) => {
        return value?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    }),
    address: Yup.object().shape({
        street: Yup.string().required(REQUIRED),
        number: Yup.string().required(REQUIRED),
        cep: Yup.string().required(REQUIRED).min(9, 'Digite um CEP válido'),
        state: Yup.string().required(REQUIRED),
        city: Yup.string().required(REQUIRED),
        district: Yup.string().required(REQUIRED),
        complement: Yup.string(),
    })
});

export const ProductContractSchema = Yup.object().shape({
    paymentMethod: Yup.string().required(REQUIRED),
    products: Yup.array()
                .min(1, "Adicione pelo menos 1 produto para efetuar o contrato")
                .of(Yup.object().shape({
                    product: Yup.string().required(REQUIRED),
                    quantity: Yup.number().required(REQUIRED).min(1, "A quantidade precisa ser maior que 0"),
                    value: Yup.number().required(REQUIRED).moreThan(0, "O valor precisa ser maior que R$ 0,00"),
                }))
})

export default schema;
