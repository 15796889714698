import AUTH from "./types";

export const auth = (username, password) => ({
  type: AUTH.REQUEST,
  username,
  password,
});

export const authSuccess = (user, token) => ({
  type: AUTH.REQUEST_SUCCESS,
  user,
  token,
});

export const authFail = (errors) => ({
  type: AUTH.REQUEST_FAIL,
  errors,
});
