const Types = {
  GET: "haux/client/GET",
  SET_TOGGLED: "haux/client/SET_TOGGLED",
  SET_SEARCH_TEXT: "haux/client/SET_SEARCH_TEXT",
  GET_ALL: "haux/client/GET_ALL",
  GET_ALL_SUCCESS: "haux/client/GET_ALL_SUCCESS",
  SET_CURRENT_CLIENT: "haux/client/SET_CURRENT_CLIENT",
  SET_LAST_ANAMNESIS: "haux/client/SET_LAST_ANAMNESIS",
};

export default Types;
