const healthProblems = [
  "Nenhum",
  "Hipertensão",
  "Dislipidemia",
  "Cardiovascular",
  "Diabetes",
  "Obesidade",
  "Sobrepeso",
  "Outros",
];

export default healthProblems;
