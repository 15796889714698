import axios from "../middlewares/axios";

class ClientService {
  static getLast(client) {
    const url = `/goal/last?client=${client}`;
    return axios.get(url);
  }

  static update(id, data) {
    const url = `/goal/update/${id}`;
    return axios.put(url, data);
  }

  static calculate(
    client,
    leisureTimeActivity,
    workActivity,
    weight,
    goalWeight,
    height,
    imc
  ) {
    const url = `/goal/calculate?client=${client}&leisureTimeActivity=${leisureTimeActivity}&workActivity=${workActivity}&weight=${weight}&goalWeight=${goalWeight}&height=${height}&imc=${imc}`;
    return axios.get(url);
  }
}

export default ClientService;
