import { useCallback, useEffect, useState } from 'react';
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/ducks/Auth/selectors";
import GoalsService from 'services/GoalsService';

const TaskTypes = [
  { anamnese: 'Tech', name: 'Tech' },
  { anamnese: 'Nutri', name: 'Nutri' },
  { anamnese: 'Med', name: 'Med' },
  { anamnese: 'Psico', name: 'Psico' },
  { anamnese: 'Training', name: 'Training' },
  { anamnese: 'Physical', name: 'Physical' }
] as const

type Type = typeof TaskTypes[number]['anamnese']
type Task = {
  _id?: string;
  client: string;
  goal: string;
  completed: boolean;
  user: string;
  type: Type;
}

interface ClientParams {
  clientId: string;
}

const ToDoList = () => {
  const user = useSelector(selectUser);
  const { clientId } = useParams<ClientParams>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState('');
  const [newTaskType, setNewTaskType] = useState<Type>('Tech');

  const getAll = useCallback(async () => {
    try {
      Swal.fire({
				title: 'Obtendo metas',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			});

      const { data } = await GoalsService.getAll(clientId);
      setTasks(data.data.items);

      Swal.close();
    } catch(e) {
      Swal.fire('Erro ao obter dados', '', 'error')
    }
  }, []);

  useEffect(() => {
    getAll();
  }, [])

  const handleInputChange = (event) => {
    setNewTask(event.target.value);
  };

  const handleTypeChange = (event) => {
    setNewTaskType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newTask.trim() === '') return;
    try {
			Swal.fire({
				title: 'Salvando',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			})
      const taskToInsert: Task = {
        client: clientId,
        user: user._id,
        goal: newTask.trim(),
        type: newTaskType,
        completed: false
      }
      const { data } = await GoalsService.insert(taskToInsert);
      const { _id } = data.data;
			Swal.close();
			setTasks([...tasks, { _id, ...taskToInsert }]);
      setNewTask('');
		} catch(e) {
			Swal.fire('Erro ao salvar', '', 'error')
		}
  };

  const toggleTask = async (index) => {
    try{
      Swal.fire({
				title: 'Salvando',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading()
				}
			});
      Swal.close();
      let taskToEdit = tasks[index];
      taskToEdit = { ...taskToEdit, completed: !taskToEdit.completed }
      await GoalsService.update(taskToEdit._id, taskToEdit);

      const updatedTasks = tasks.map((task, i) =>
        i === index ? { ...task, completed: !task.completed } : task
      );
      setTasks(updatedTasks);
    } catch (e) {
      Swal.fire('Erro ao salvar', '', 'error')
    }
  }

  const removeTask = async (index) => {

    try {
      const confirmResponse = await Swal.fire({
        title: 'Você tem certeza que deseja excluir?',
        html: `Essa ação não poderá ser desfeita`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir meta!'
      });
      if (!confirmResponse.isConfirmed) {
        return;
      }

      Swal.fire({
        title: 'Deletando meta',
        text: 'Em progresso...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
        }
      })
      const taskToDelete = tasks[index];
      await GoalsService.delete(taskToDelete._id);
      const updatedTasks = tasks.filter((meta) => meta._id !== taskToDelete._id);
      setTasks(updatedTasks);
      Swal.close();
    } catch {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi possível excluir',
        timerProgressBar: true,
        icon: 'error',
        didOpen: () => {
            Swal.hideLoading()
        }
      })
    }
  };

  return (
    <div className="max-w-lg mx-auto">
      <form onSubmit={handleSubmit} className="mb-4 flex">
        <input
          type="text"
          placeholder="Adicione o texto da meta"
          className="flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-light focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 transition-all"
          value={newTask}
          onChange={handleInputChange}
        />
        <select
          value={newTaskType}
          onChange={handleTypeChange}
          className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
        >
          <option value="Tech">Tech</option>
          <option value="Nutri">Nutri</option>
          <option value="Med">Med</option>
          <option value="Psico">Psico</option>
          <option value="Training">Training</option>
          <option value="physical">Educação Física</option>
        </select>
        <button type="submit" className="px-4 py-2 min-w-[130px] bg-purple-700 text-white rounded-r">
          Nova meta
        </button>
      </form>
      <ul className="list-none p-0">
        {tasks.map((task, index) => (
          <li key={index} className="flex items-center justify-between mb-2">
            <span
              className={`flex-1 ${task.completed ? 'line-through text-gray-500' : ''}`}
              onClick={() => toggleTask(index)}
            >
              {task.goal} - {task.type}
            </span>
            <div>
              <button
                onClick={() => toggleTask(index)}
                className={`px-2 py-1 mr-2 ${task.completed ? 'bg-gray-300' : 'bg-green-500'} text-white rounded`}
              >
                {task.completed ? 'Desfazer' : 'Feito'}
              </button>
              <button
                onClick={() => removeTask(index)}
                className="px-2 py-1 bg-red-500 text-white rounded"
              >
                Remover
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ToDoList;


