import CLIENT from "./types";

export const setToggled = (toggled) => ({
  type: CLIENT.SET_TOGGLED,
  toggled,
});

export const setSearchText = (text) => ({
  type: CLIENT.SET_SEARCH_TEXT,
  text,
});

export const requestClients = () => ({
  type: CLIENT.GET_ALL,
});

export const requestClientsSuccess = (clients) => ({
  type: CLIENT.GET_ALL_SUCCESS,
  clients,
});

export const setCurrentClient = (client) => ({
  type: CLIENT.SET_CURRENT_CLIENT,
  client,
});

export const setLastAnamnesis = (anamnesis) => ({
  type: CLIENT.SET_LAST_ANAMNESIS,
  anamnesis,
});
