import React from "react";
import {
    Row,
    ItemWrapper,
    Title,
    Description
} from "../Food/styles";

interface IUsedMedicines {
    name: string;
    observations: string;
    dosage: string;
    status: string;
}

const UsedMedicines: React.FC<IUsedMedicines> = ({
    name,
    observations,
    dosage,
    status
}) => {
    return (
        <ItemWrapper>
            <Row>
                <Row
                    style={{ flex: 1, flexDirection: "column", alignItems: "flex-start" }}
                >
                    <Row style={{ width: "100%" }}>
                        <Title style={{ marginRight: 35 }}>{(name || "").toUpperCase()}</Title>
                    </Row>

                    <Row style={{ alignItems: "start" }}>
                        <Description>{status}</Description>
                    </Row>
                    <Row style={{ alignItems: "start" }}>
                        <Description>{dosage}</Description>
                    </Row>
                    <Row style={{ alignItems: "start" }}>
                        <Description>{observations}</Description>
                    </Row>
                </Row>
            </Row>
        </ItemWrapper>
    );
};

export default UsedMedicines;