import React, { useCallback, useEffect, useState, useReducer, useRef } from 'react'
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/ducks/Auth/selectors";
import { useParams } from "react-router-dom";
import { useOneFieldModal } from "hooks/useOneFieldModal";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import {
  Button,
  NumberInput,
  Menu,
  Modal,
} from '../../components/3.0';
import { TextArea, TextInput, Select } from 'atoms';
import { LoadingPurple } from "../../components/Loading";
import {
  FormProvider,
  useForm,
  useWatch,
} from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import DAYS from '../../utils/daysOfWeek';
import { TYPE_OF_MEALS, HOURS_OF_MEALS } from '../../utils/meals';
import { FoodSchema, useYupValidationResolver } from './schema';
import Taco from './taco';
import Macronutrients from './macronutrients';
import Diary from './diary';
import NutritionalPlanService from '../../services/NutritionalPlan';
import { IFood, IMeal } from 'context/SystemContext';
import PreMeals from './premeals';

interface IFOOD {
    _id: String;
    description: String;
    protein: String;
    carbohydrate: String;
    lipids: String;
    parent?: String;
}

interface IMealForm {
  planId: string;
  weekday: string;
  onFinish?: (any) => any;
  isOpen: boolean;
  setIsOpen(open: boolean): any;
  current?: any;
}

const MealForm: React.FC<IMealForm> = ({
  planId,
  weekday,
  onFinish,
  isOpen,
  setIsOpen,
  current,
}) => {
  const methods = useForm();
  const [preMeal, setPreMeal] = useState<IMeal | undefined>();
  const [showPreMeals, setShowPreMeals] = useState(false);
  const [saving, setSaving] = useState(false);
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    setPreMeal(undefined);
    setShowPreMeals(false);
    if (current._id) {
      reset({ ...current, ...(current.replace ? { _id: undefined, foods: [], observations: ''} : {})})
    } else {
      reset({ description: 'Café da manhã', hour: '07:00'})
    }
  }, [current, reset]);

  const toNumber = (str: String) => {
    if (!str) {
      return 0;
    }
    let inNumber = parseFloat(str.replace(",", "."));
    return isNaN(inNumber) ? 0 : inNumber;
  }

  const onSubmit = useCallback(async (data) => {
    try {
      Swal.fire({
          title: 'Salvando',
          text: 'Em progresso...',
          timerProgressBar: true,
          didOpen: () => {
              Swal.showLoading()
          }
      })
      const foods: any[] = [];
      preMeal?.foods.forEach((item: { food: IFood, portion: number}) => {
        const { food, portion } = item;
        foods.push({
          description: food.description,
          calories: toNumber(food.energyKcal) * (portion / 100),
          protein: toNumber(food.protein),
          lipids: toNumber(food.lipids),
          carbohydrate: toNumber(food.carbohydrate),
          portion: portion,
          measure: 'Grama'
        })
      });
      const meal = { plan: planId, weekday, foods, ...data }
			if (current.replace) {
				meal.parentId = current._id;
			}
      const res = await NutritionalPlanService.patchMeal(meal)
			const resData = _.get(res, ["data", "data", "form"])
      onFinish?.(resData);
      Swal.close();
    } catch (e) {
      Swal.fire({
          title: 'Erro',
          text: 'Não foi possível editar',
          timerProgressBar: true,
          icon: 'error',
          didOpen: () => {
              Swal.hideLoading()
          }
      })
    }
  }, [planId, preMeal, current]);

	const getTitle = useCallback(() => {
		if (current._id) {
			if (current.replace) {
				return `Adicionar substituição à '${current.hour} - ${current.description}'`
			}
			return (
				current.parentId ? 
				`Editar substituição à '${current.hour} - ${current.description}'` : 
				"Editar refeição"
			);
		}

		return (
			current.parentId ?
			`Adicionar substituição à '${current.hour} - ${current.description}'` : 
			"Nova refeição"
		)
	}, [current]);

  return (
    <FormProvider {...methods}>
      <PreMeals
        isOpen={showPreMeals}
        onClick={(meal) => {
          setPreMeal(meal)
          setShowPreMeals(false)
          reset({ observations: meal?.description })
        }}
        onCancel={() => setShowPreMeals(false)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={getTitle()}
          okButtonTitle="Salvar"
          okButtonLoading={saving}
          onOk={() => handleSubmit(onSubmit)()}
        >
          <div className='grid grid-rows-2 grid-cols-4 grid-flow-row-dense gap-4'>
						<div className={`col-span-1 ${current.replace || current.parentId ? 'hidden' : ''}`}>
            	<Select label='Horário' name='hour' options={HOURS_OF_MEALS} />
						</div>
            <div className={`col-span-3 ${current.replace || current.parentId ? 'hidden' : ''}`}>
              <Select label='Descrição' name='description' options={TYPE_OF_MEALS}/>
            </div>
            <div className='col-span-4'>
              <TextArea label='Observações' name='observations' />
            </div>
            <div className='col-span-4'>
              <button className={`
                flex items-center mb-4 p-2 px-4 rounded transition-all
                ${preMeal ? "bg-red-50 text-red-400 hover:bg-purredple-100 hover:text-red-500" : "bg-purple-50 text-purple-400 hover:bg-purple-100 hover:text-purple-500"}
              `} onClick={() => {
                if (preMeal) {
                  setPreMeal(undefined)
                  reset({ observations: '' })
                } else {
                  setShowPreMeals(true)
                }
              }}>
                
                {preMeal ?
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                  </svg>
                  {`Remover predefinição de '${preMeal.name}'`}
                </>
                : 
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                  Selecionar refeição predefinida
                </>
                }
              </button>
            </div>
          </div>
        </Modal>
      </form>
    </FormProvider>
  );
}

interface IFoodForm {
  mealId: string;
  parentId: string;
  food?:  any;
  onFinish?: (string, any) => any;
  isOpen: boolean;
  setIsOpen(open: boolean): any;
}

const FoodForm: React.FC<IFoodForm> = ({
  mealId,
  food,
  parentId,
  onFinish,
  isOpen,
  setIsOpen,
}) => {
  const [tacoOpen, setTacoOpen] = useState(false);
  const formRef = useRef(null)
  const selectFoodRef = useRef<any>(null)
  const resolver = useYupValidationResolver(FoodSchema);
  const methods = useForm({ 
    resolver,
    mode: "onSubmit"
  });
  const { handleSubmit, reset, control, setValue, getValues } = methods;
  const quantidade = useWatch({
    control,
    name: "portion",
  });

  useEffect(() => {
    if (!_.isEmpty(food) && food.shouldEdit) {
      selectFoodRef.current = {
        portion: (food.portion || 100).toString(),
        energyKcal: (food.calories || 0).toString(),
        protein: (food.protein || 0).toString(),
        lipids: (food.lipids || 0).toString(),
        carbohydrate: (food.carbohydrate || 0).toString(),
        measure: 'Grama'
      };
      reset(food)
    } else {
      reset({ })
    }
  }, [isOpen, parentId, food, reset]);

  useEffect(() => {
    const portion = toNumber((selectFoodRef.current || {}).portion || "100")
    const calories = toNumber((selectFoodRef.current || {}).energyKcal || "0") ;
    const protein = toNumber((selectFoodRef.current || {}).protein || "0") ;
    const lipids = toNumber((selectFoodRef.current || {}).lipids || "0") ;
    const carbohydrate = toNumber((selectFoodRef.current || {}).carbohydrate || "0");

    setValue("calories", Math.round(((calories / portion) * quantidade) * 100) / 100)
    setValue("protein", Math.round(((protein / portion) * quantidade) * 100) / 100)
    setValue("lipids", Math.round(((lipids / portion) * quantidade) * 100) / 100)
    setValue("carbohydrate", Math.round(((carbohydrate / portion) * quantidade) * 100) / 100)
  }, [quantidade, setValue, getValues])

  const onSubmit = useCallback(async (data) => {
    try {
      Swal.fire({
        title: 'Editando',
        text: 'Em progresso...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
        }
    })
      const values = data;
      values.measure = values.measure || 'Grama'
      if (!parentId) {
        values._id = food._id;
      }
      values.parentId = parentId;
      const response = await NutritionalPlanService.patchFood(mealId, values)
      let foodId = values._id || _.get(response, ["data", "data", "_id"]);
      onFinish?.(mealId, { ...values, _id: foodId, shouldEdit: false});
      reset({})
      Swal.close();
    } catch {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi possível editar',
        timerProgressBar: true,
        icon: 'error',
        didOpen: () => {
            Swal.hideLoading()
        }
    })
    }
    
  }, [mealId, parentId, food, reset]);

  const toNumber = (str: String) => {
    return parseFloat(str.replace(",", "."))
  }

  return (
    <>
      <Taco
        isOpen={tacoOpen}
        onClick={(food) => {
          if (food) {
            selectFoodRef.current = food;
            //@ts-ignore
            reset({ 
              description: food.description,
              calories: toNumber(food.energyKcal),
              protein: toNumber(food.protein),
              lipids: toNumber(food.lipids),
              carbohydrate: toNumber(food.carbohydrate),
              portion: 100,
              measure: 'Grama'
            })
          }
          setTacoOpen(false)
        }}
      />
      <FormProvider {...methods}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={"Adicionar alimento"}
            okButtonTitle="Salvar"
            onOk={() => handleSubmit(onSubmit)()}
          >
            <button className="flex items-center bg-purple-50 text-purple-400 hover:bg-purple-100 hover:text-purple-500 mb-4 p-2 px-4 rounded transition-all" onClick={() => setTacoOpen(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
              Buscar da tabela TACO
            </button>
            <div className='grid grid-rows-2 grid-cols-6 grid-flow-row-dense gap-4'>
              <div className='col-span-6'>
                <TextInput label='Alimento' name='description' />
              </div>
              <div className='col-span-3'>
                <NumberInput label='Quantidade' name='portion' suffix=' g' />
              </div>
              <div className='col-span-3'>
                <NumberInput label='Calorias' name='calories' suffix=' Kcal' />
              </div>
              <div className='col-span-2'>
                <NumberInput label='Proteínas' name='protein' suffix=' g' />
              </div>
              <div className='col-span-2'>
                <NumberInput label='Lipídeos' name='lipids' suffix=' g' />
              </div>
              <div className='col-span-2'>
                <NumberInput label='Carboidratos' name='carbohydrate' suffix=' g' />
              </div>
            </div>
          </Modal>
        </form>
      </FormProvider>
    </>
  );
}

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  planId: string;
  meetingNumber?: string;
}

interface IPlan {
  _id: string;
  title?: string;
  observations?: string;
  goals?: string;
  createdAt: string;
  createdBy?: string;
  updatedAt: string;
  updatedBy?: string;
  uniqueDay?: boolean;
  client: {
     name: string; 
  };
  active?: boolean;
}

const PlanoAlimentar = () => {
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [showDiary, setShowDiary] = useState(false);
  const [weekday, setWeekday] = useState('sunday')
  const [mealIsOpen, setMealIsOpen] = useState<boolean>(false)
  const [foodIsOpen, setFoodIsOpen] = useState<boolean>(false)
  const [copyFromDayIsOpen, setCopyFromDayIsOpen] = useState<any>()
  const [currentMeal, setCurrentMeal] = useState<any>({})
  const [currentFood, setCurrentFood] = useState<any>({})
  const [plan, setPlan] = useState<IPlan | undefined>()
  const [meals, setMeals] = useState<any[]>([])
  const { clientId, planId } = useParams<ClientParams>();

  const [Title, toggleTitle] = useOneFieldModal({
    title: 'Insira um novo título',
    label: 'Título',
    name: 'title',
    onSubmit: (async (value) => {
      await NutritionalPlanService.insertPlan({
        _id: planId,
        title: value,
      })
      getAll();
    })
  });
  const [Observations, toggleObservations] = useOneFieldModal({
    title: 'Insira uma nova observação',
    label: 'Observações',
    name: 'observations',
    multiline: true,
    onSubmit: (async (value) => {
      await NutritionalPlanService.insertPlan({
        _id: planId,
        observations: value,
      })
      getAll();
    })
  });
  const [Goals, toggleGoals] = useOneFieldModal({
    title: 'Insira a meta',
    label: 'Meta',
    name: 'goals',
    multiline: true,
    onSubmit: (async (value) => {
      await NutritionalPlanService.insertPlan({
        _id: planId,
        goals: value,
      })
      getAll();
    })
  });

  const sums = useCallback(() => {
    var result = {};
    meals.forEach((m) => {
      if (!m.parentId) {
        m.foods.forEach((f) => {
          if (!f.parentId) {
            if (!result[m.weekday]) {
              result[m.weekday] = { calories: 0, protein: 0, carbohydrate: 0, lipids: 0}
            }
            if(!result[m.weekday][m._id]) {
              result[m.weekday][m._id] = { calories: 0, protein: 0, carbohydrate: 0, lipids: 0 }
            }
            result[m.weekday].calories += f.calories
            result[m.weekday][m._id].calories += f.calories
            result[m.weekday].protein += f.protein
            result[m.weekday][m._id].protein += f.protein
            result[m.weekday].carbohydrate += f.carbohydrate
            result[m.weekday][m._id].carbohydrate += f.carbohydrate
            result[m.weekday].lipids += f.lipids
            result[m.weekday][m._id].lipids += f.lipids
          }
        })
      }
    })

    return result;
  }, [meals])

  const getAll = useCallback(async () => {
    setLoading(true)
    try {
      var response  = await NutritionalPlanService.getPlan(planId);
      const plan = _.get(response, ["data", "data"])
      response  = await NutritionalPlanService.getMeal(planId, clientId);
      setPlan(plan)
      if (!_.isEmpty(planId)) {
        const data = _.get(response, ["data", "data", "items"])
        setMeals(data)
      }
    } catch(e) {
      console.log(e);
      NotificationManager.error(
        `Erro ao baixar plano`
      );
    }
    setLoading(false)
  }, [planId, clientId, setLoading, setPlan, setMeals])

	const toogleActive = useCallback(async () => {
		const result = await Swal.fire({
			title: 'Deseja ativar plano?',
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: 'Ativar plano',
			denyButtonText: 'Inativar plano',
			cancelButtonText: 'Cancelar ação',
		})
		if (!result.isDismissed) {
			const active = result.isConfirmed;
			await NutritionalPlanService.insertPlan({
        _id: planId,
        active,
      })
      getAll();
		}
	}, [planId])

  const toogleDayUnique = useCallback(async () => {
    const result = await Swal.fire({
			title: `Deseja ${plan?.uniqueDay ? 'desativar' : 'ativar'} o plano diário?`,
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: `${plan?.uniqueDay ? 'Desativar' : 'Ativar'}`,
			cancelButtonText: 'Cancelar ação',
		})
		if (!result.isDismissed) {
      await NutritionalPlanService.insertPlan({
        _id: planId,
        uniqueDay: !(plan?.uniqueDay),
      })
      setWeekday('sunday')
      getAll();
    }
		
	}, [plan])

  useEffect(() => {
    getAll();
  }, [planId, getAll])

  useEffect(() => {
    if (meals.length) {
      setCurrentMeal((meal) => {
        const newMeal = meals.find(m => m._id === meal._id);
        return newMeal || meal;
      })
      setMealIsOpen(false)
      setFoodIsOpen(false)
    }
  }, [meals, setCurrentMeal])

  

  const onDeleteMeal = useCallback(async (meal: any) => {
    try {
      const confirmResponse = await Swal.fire({
        title: 'Você tem certeza que deseja excluir?',
        html: `Essa ação não poderá ser desfeita<br /><br /> <small>Refeição a ser excluída: "${meal.hour} - ${meal.description}</small>"`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir refeição!'
      });
      if (!confirmResponse.isConfirmed) {
        return;
      }

      Swal.fire({
        title: 'Deletando refeição',
        text: 'Em progresso...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
        }
    })
      await NutritionalPlanService.deleteMeal(meal._id)
      setMeals((meals) => meals.filter((m) => m._id !== meal._id))
      Swal.close();
    } catch {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi possível excluir',
        timerProgressBar: true,
        icon: 'error',
        didOpen: () => {
            Swal.hideLoading()
        }
    })
    }
  }, [setMeals])

  const onChangeMeal = useCallback((meal: any) => {
    setMeals(meals => [...meals.filter((m) => m._id != meal._id), meal]);
  }, [setMeals, setMealIsOpen])

  const onChangeFood = useCallback((mealId: string, food: any) => {
    setMeals(meals => meals.map((m) => {
      if (m._id === mealId) {
        const {foods, ...restMeal} = m;
        var findIndex = _.findIndex(foods, { _id: food._id });
        let newFoods = foods;
        if (findIndex >= 0) {
          newFoods.splice(findIndex, 1, food)
        } else {
          newFoods.push(food)
        }
        return { ...restMeal, foods: newFoods }
      }
      return m;
    }));
  }, [setMeals])

  const onDeleteFood = useCallback(async (mealId, food) => {
    try {
      const confirmResponse = await Swal.fire({
        title: 'Você tem certeza que deseja excluir?',
        html: `Essa ação não poderá ser desfeita.<br /><br /><small>Alimento a ser excluído: "${food.description}"</small>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir alimento!'
      });
      if (!confirmResponse.isConfirmed) {
        return;
      }

      Swal.fire({
          title: 'Deletando alimento',
          text: 'Em progresso...',
          timerProgressBar: true,
          didOpen: () => {
              Swal.showLoading()
          }
      })
      await NutritionalPlanService.deleteFood(mealId, food._id);
      setMeals(meals => meals.map((m) => {
        if (m._id === mealId) {
          const {foods, ...restMeal} = m;
          return { ...restMeal, foods: foods.filter((f) => f._id !== food._id && f.parentId != food._id)}
        }
        return m;
      }));
      Swal.close();
    } catch {
      Swal.fire({
          title: 'Erro',
          text: 'Não foi possível excluir',
          timerProgressBar: true,
          icon: 'error',
          didOpen: () => {
              Swal.hideLoading()
          }
      })
    }
  }, [onChangeFood])

  

  const download = useCallback(
    async () => {
      try {
        const url =  NutritionalPlanService.download(planId, user.username)
        //@ts-ignore
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
        NotificationManager.success(
        "Download feito com sucesso"
        );
      } catch (errors) {
        NotificationManager.error(
          "Erro ao baixar laudo"
        );
      }
    },
    [planId]
  );

	const getParentMeals = useCallback(() => {
		return _.sortBy(meals.filter((m) => m.parentId === undefined), ['hour']);
	}, [meals]);

	const getChildrenMeals = useCallback((mealId) => {
		return meals.filter((m) => m.parentId === mealId);
	}, [meals]);

  const getColor = (mealType: string): string => {
    switch (mealType) {
      case 'Café da manhã':
        return "bg-[#0ea5e9]";
      case 'Colação':
        return "bg-[#f43f5e]";
      case 'Almoço':
        return "bg-[#0d9488]";
      case 'Lanche da tarde':
        return "bg-[#d946ef]";
      case 'Pré-treino':
        return "bg-[#8b5cf6]";
      case 'Pós-treino':
        return "bg-[#6b21a8]";
      case 'Jantar':
        return "bg-[#f59e0b]";
      case 'Ceia':
        return "bg-[#f87171]";
      default:
        return "bg-[#0ea5e9]";
    }
  }

  let report = sums();

  if (loading) {
    return (
      <div
        style={{
        display: "flex",
        justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100, alignSelf: "center" }} />
      </div>
    );
  }

	const renderMeal = (meal, index) => (
		<li className={`
			relative bg-white py-4 px-6 hover:bg-gray-200 hover:text-purple-800 transition-all
				${ meal._id === currentMeal._id ? "bg-gray-200 hover:text-black" : "hover:text-purple-800"}
		`}
			onClick={() => setCurrentMeal(meal)}
      key={meal._id}
		>
			<div className="flex transition-all overflow-hidden">
				<div className="flex-1 flex px-28">
					<div className={`absolute top-0 bottom-0 left-0 ${getColor(meal.description)} text-white`}>
						<div className="w-28 h-14 flex justify-center items-center pl-6">
							<Menu
								onEdit={() => {
									setCurrentMeal(meal)
									setMealIsOpen(true)
								}}
								onDelete={() => {
									onDeleteMeal(meal)
								}}
								onReplace={() => {
									setCurrentMeal({...meal, replace: true})
									setMealIsOpen(true)
								}}
                onCopy={() => {
                  if (!meal.parentId) {
                    setCopyFromDayIsOpen(meal)
                  } else {
                    Swal.fire({
                      title: 'Não foi possível copiar',
                      text: 'Apenas refeições PRINCIPAIS podem ser copiadas',
                      icon: 'warning'
                    })
                  }
                }}
								shouldReplace={meal.parentId === undefined}
							/>
							{meal.parentId ?
								<>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2">
										<path fillRule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clipRule="evenodd" />
									</svg>
								</> :
								<>
									{meal.hour}
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</>
							}
							
						</div>
					</div>
					<div>
						<h3 className='text-lg font-black'>
							{meal.parentId ? 
							<>
								<strong>Opção {index+2}:&nbsp;</strong>
								<small>{meal.description}</small>
							</> :
							<>
								{meal.description}
							</>
							}
						</h3>
						<p className={ meal._id === currentMeal._id ? "" : "truncate max-w-2xl"}>{meal.observations}</p>
					</div>
				</div>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`transition-all w-5 h-5 ${currentMeal._id === meal._id ? "-rotate-90": "rotate-90"}`}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
				</svg>
			</div>
			<div className={`px-28 text-sm font-thin transition-all ${ meal._id === currentMeal._id ? "opacity-1" : "max-h-0 opacity-0"}`}>
					<ul>
						{(currentMeal.foods || []).filter((f) => !f.isDeleted && !f.parentId).map((food, foodIndex) => (
							<React.Fragment key={food._id}>
							<li className={`flex items-center ${foodIndex !== 0 ? 'border-t-2': ''} border-gray-400 border-dashed mt-3 pt-3`}>
								{food.description} ({food.calories.toFixed(0)}Kcal - {food.measure}: {food.portion})
								<button
									className="ml-4 hover:bg-gray-400 hover:text-white p-2 flex items-center rounded transition-all"
									onClick={() => onDeleteFood(meal._id, food)}
									>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
										<path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
									</svg>
									Excluir
								</button>
                <button
                  className="ml-4 hover:bg-gray-400 hover:text-white p-2 flex items-center rounded transition-all"
                  onClick={() => {
							      setCurrentFood({ ...food, shouldEdit: true })
                    setFoodIsOpen(true)
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-2 h-4 w-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                  </svg>
                  Editar
                </button>
								<button
									className="ml-4 hover:bg-gray-400 hover:text-white p-2 flex items-center rounded transition-all"
									onClick={() => {
										setCurrentFood({ ...food, parentId: food._id})
										setFoodIsOpen(true)
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
										<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
									</svg>
									Adicionar substituição
								</button>
							</li>
							{(currentMeal.foods || []).filter((f) => !f.isDeleted && f.parentId === food._id).map((food) => (
								<li className="flex items-center text-gray-500" key={food._id}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-6 w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 19.5l-15-15m0 0v11.25m0-11.25h11.25" />
									</svg>
									Substituição:{' '} 
									{food.description} ({food.calories.toFixed(0)}Kcal - {food.measure}: {food.portion})
									<button
										className="ml-4 hover:bg-gray-400 hover:text-white p-2 flex items-center rounded transition-all"
										onClick={() => onDeleteFood(meal._id, food)}
										>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
											<path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
										</svg>
										Excluir
									</button>
                  <button
                    className="ml-4 hover:bg-gray-400 hover:text-white p-2 flex items-center rounded transition-all"
                    onClick={() => {
                      setCurrentFood({...food, shouldEdit: true})
                      setFoodIsOpen(true)
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-2 h-4 w-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>
                    Editar
                  </button>
								</li>
							))}
							</React.Fragment>
						))}
						<Button selected className={`transition-all ${getColor(meal.description)} hover:${getColor(meal.description)} ${currentMeal._id === meal._id ? 'my-12' : 'my-0'}`} onClick={() => {
							setFoodIsOpen(true)
							setCurrentFood({})
						}}>
								<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
								</svg>
								Adicionar alimento
						</Button>
					</ul>
				</div>
		</li>
	);

  const showMeals = () => (
    <>
      <MealForm
        planId={planId}
        weekday={weekday}
        onFinish={onChangeMeal}
        isOpen={mealIsOpen}
        setIsOpen={setMealIsOpen}
        current={currentMeal}
      />

      <FoodForm
        mealId={currentMeal._id}
        parentId={currentFood.parentId}
        food={currentFood}
        isOpen={foodIsOpen}
        setIsOpen={setFoodIsOpen}
        onFinish={onChangeFood}
      />

      <div className="pt-12 pb-3 text-gray-900 font-bold text-xl">Refeições</div>
        <div className="rounded overflow-hidden shadow-lg">
          <ul>
            {getParentMeals().filter((m) => m.weekday === weekday).map((meal, index) => (
							<div key={meal._id}>
                {renderMeal(meal, index)}
								{getChildrenMeals(meal._id).map((child, childIndex) => renderMeal(child, childIndex))}
							</div>
            ))}

          </ul>
      </div>

      <div className='flex justify-between my-5'>
        <div className='flex'>
          <Button selected onClick={() => {
            setCurrentMeal({})
            setMealIsOpen(true)
          }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Nova refeição
          </Button>
          </div>

          <Button selected onClick={download}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
              Baixar plano
          </Button>
      </div>

      <Modal
        title={`Copiar refeição ${copyFromDayIsOpen?.description} ${copyFromDayIsOpen?.hour} da origem:`}
        isOpen={!!copyFromDayIsOpen}
        setIsOpen={() => setCopyFromDayIsOpen(undefined)}
      >
          <h1 className='text-gray-700 -mt-4 mb-8'>Selecione o dia para o qual deseja copiar a refeição</h1>
          <div className="grid grid-cols-3">
            {Object.keys(DAYS).map((day) => (
              <button
                key={day}
                className="font-medium text-sm text-purple-400 hover:bg-purple-100 hover:text-purple-500 p-2 px-4 rounded transition-all"
                onClick={async () => {
                    try {
                      Swal.fire({
                          title: 'Salvando',
                          text: 'Em progresso...',
                          timerProgressBar: true,
                          didOpen: () => {
                              Swal.showLoading()
                          }
                      })
                      
                      const oldId = copyFromDayIsOpen._id;
                      const meal = { ...copyFromDayIsOpen, _id: undefined, parentId: undefined, weekday: day }
                      const resp = await NutritionalPlanService.patchMeal(meal)
                      const newId = _.get(resp, ["data", "data", "form", "_id"]);
                      const childrenToCopy = meals.filter((m) => m.parentId === oldId);
                      const copyChildrenPromises = childrenToCopy.map((m) => {
                        const childMeal = ({ ...m, _id: undefined, parentId: newId, weekday: day});
                        return NutritionalPlanService.patchMeal(childMeal);
                      })
                      await Promise.all(copyChildrenPromises);
                      Swal.close();
                      setCopyFromDayIsOpen(undefined)
                      getAll()
                      setWeekday(day)
                    } catch {
                      Swal.fire({
                        title: 'Erro',
                        text: 'Erro ao copiar sua refeição completa, verifique e exclua qualquer refeição temporária copiada',
                        timerProgressBar: true,
                        icon: 'error',
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                    }
                    
                  }}
              >
                {DAYS[day]}
              </button>
            ))}
        </div>
      </Modal>
    </>
  );

  return (
    <div className="w-full" key={weekday}>
      <Title />
      <Observations />
      <Goals />
      
      <div className="rounded overflow-hidden shadow-lg bg-white mb-4 pb-4">
        <div className="px-6 pt-3 transition-all text-gray-900 font-bold text-xl flex items-center cursor-pointer hover:text-purple-900"
          onClick={() => toggleTitle()}
        >
          Título:&nbsp;
          <span>{plan?.title ?? '(Sem título)'}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
          </svg>
        </div>
        <div className="px-6 pt-3 transition-all text-gray-900 font-bold text-xl flex cursor-pointer hover:text-purple-900"
          onClick={() => toggleObservations()}
        >
          Observações:&nbsp;
          <span>{plan?.observations ?? '(Sem observações)'}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
          </svg>
        </div>
        <div className="px-6 pt-3 transition-all text-gray-900 font-bold text-xl flex cursor-pointer hover:text-purple-900"
          onClick={() => toggleGoals()}
        >
          Metas:&nbsp;
          <span>{plan?.goals ?? '(Sem metas)'}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
          </svg>
        </div>

        {plan?.active == true ?
						<span onClick={toogleActive} className="ml-6 mt-4 transition-all cursor-pointer hover:scale-105 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-green-200 text-green-700 rounded-full">
								Ativo
						</span>
				:
						<span onClick={toogleActive} className="ml-6 mt-4 transition-all cursor-pointer hover:scale-105 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-orange-200 text-orange-700 rounded-full">
								Inativo
						</span>
				} 
      </div>
      <div className="rounded overflow-hidden shadow-lg bg-white">
        {!plan?.uniqueDay && <div className="px-6 pt-3 text-gray-900 font-bold text-xl">Selecione o dia da semana</div>}
        
        <div className="px-6 pt-6">
          <label className="relative inline-flex items-center cursor-pointer" onClick={toogleDayUnique}>
            <input type="checkbox" checked={plan?.uniqueDay} className="sr-only peer" />
            <div className="w-11 h-6 bg-purple-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-purple-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
            <span className="ml-3 text-sm font-medium text-gray-900">Plano de único dia</span>
          </label>
        </div>
       
        {!plan?.uniqueDay && <div className="px-6 pt-4 pb-2 flex justify-between items-center">
          <div className="flex items-start">
            {Object.keys(DAYS).map((day) => (
              <Button
                key={day}
                compact
                selected={day == weekday && !showDiary}
                onClick={() => {
                  setWeekday(day)
                  setShowDiary(false)
                }}
              >
                {DAYS[day].replace("-feira", "")}
              </Button>
            ))}
          </div>

          <Button
            selected={showDiary}
            onClick={() => setShowDiary(!showDiary)}>
            Diário alimentar
          </Button>
        </div>}
        

        {!showDiary && <div className='flex w-full flex-col items-end pr-6 pb-6'>
          <Macronutrients
              protein={_.get(report,[weekday, 'protein'],0)}
              carbohydrate={_.get(report,[weekday, 'carbohydrate'],0)}
              lipids={_.get(report,[weekday, 'lipids'],0)}
              calories={_.get(report,[weekday, 'calories'],0)}
            />
        </div>}
      </div>

      {showDiary ? <Diary /> : showMeals()}
      
    </div>
  )
}

export default PlanoAlimentar