import { combineReducers } from "redux";
import Auth from "./Auth/reducers";
import Client from "./Client/reducers";
import Anamnesis from "./Anamnesis/reducers";

const reducers = combineReducers({
  auth: Auth,
  client: Client,
  anamnesis: Anamnesis,
});

export default reducers;
