import axios from "../middlewares/axios";

class ClientService {
  static get(id) {
    const url = `/client/${id}`;
    return axios.get(url);
  }

  static getClients() {
    const url = `/client?sortBy=name`;
    return axios.get(url);
  }

  static insert(data) {
    const url = `/client`;
    return axios.post(url, data);
  }

  static update(id, data) {
    const url = `/client/${id}`;
    return axios.put(url, data);
  }

  static info(id) {
    const url = `/client/${id}/info`;
    return axios.get(url);
  }

  static plan(id) {
    const url = `/client/${id}/plan`;
    return axios.get(url);
  }
}

export default ClientService;
