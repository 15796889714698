import styled from "styled-components";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";

export const Label = styled.label`
  font-family: var(--font-heavy);
  font-size: 15px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
  margin-bottom: 0;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 30px;
  border-radius: 8px;
`;

export const Dropdown = styled.div`
  margin-top: 7px;
  position: relative;
  display: flex;
  width: 100%;

  span {
    font-family: var(--font-heavy);
    font-size: 26px;
    letter-spacing: -0.01em;
    line-height: 16px;
    text-align: left;
    color: var(--purple);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px;
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease, padding 0.8s linear;

  background-color: ${({ error }) =>
    error ? "var(--danger-form)" : "transparent"};

  :hover {
    background-color: ${({ error }) =>
      error ? "var(--danger-form)" : "var(--hover-background)"};

    > ${Dropdown} ${DropdownContent} {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const DropdownButton = styled.button.attrs({
  type: "button",
})`
  font-family: var(--font-heavy);
  font-size: 16px;
  color: var(--text);
  padding: 10px;

  :hover {
    color: var(--purple);
  }
`;

export const ArrowDown = styled.img.attrs({
  src: ArrowDownIcon,
})`
  position: absolute;
  right: 0;
`;
