import axios from "../middlewares/axios";

class FoodsService {
  static getFoods() {
    const url = `/hauxfoods/food`;
    return axios.get(url);
  }
  static getMeals() {
    const url = `/hauxfoods/meal`;
    return axios.get(url);
  }
}

export default FoodsService;
