import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { Row, Col } from "reactstrap";
import { DateInput, NumberInput, TextInput } from "../../../components/Formik";
import { H1, LeftPanel, Loading } from "../../../components";
import { LoadingOrange } from "../../../components/Loading";
import { SearchInput } from "../styles";
import {
  Title,
  Subtitle,
  RemoveIcon,
  AddIcon,
  AddButton,
  ExerciseWrapper,
  Section,
} from "./styles";
import { FooterContainer, SaveButton } from "../styles";
import { selectToggled } from "../../../redux/ducks/Client/selectors";
import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";
import TrainingService from "../../../services/TrainingService";
// import ExerciseSVG from "../../../assets/images/exercise.svg";
import { useParams } from "react-router-dom";

const Exercise = ({ name, index, exercise, onClick, onRemove }) => (
  <div style={{ position: "relative" }}>
    {index >= 0 && <RemoveIcon onClick={onRemove} />}
    <ExerciseWrapper hover={index < 0} onClick={onClick}>
      <div style={{ paddingLeft: 17, paddingTop: 10 }}>
        <Title style={{ fontSize: 16, marginBottom: 0 }}>
          {_.get(exercise, ["title"])}
        </Title>
        <Subtitle style={{ fontSize: 11, textTransform: "uppercase" }}>
          {_.get(exercise, ["category"])} - {_.get(exercise, ["time"])} MIN -{" "}
          {_.get(exercise, ["difficulty"])}
        </Subtitle>

        <Subtitle style={{ fontSize: 11 }}>
          {_.get(exercise, ["description"])}
        </Subtitle>
        <Subtitle style={{ fontSize: 11 }}>
          Vídeo:{" "}
          <a href={_.get(exercise, ["video"])} target="_blank" rel="noreferrer">
            {_.get(exercise, ["video"])}
          </a>
        </Subtitle>
      </div>
      {index >= 0 && (
        <>
          <div style={{ display: "flex" }}>
            <NumberInput
              name={`${name}.${index}.series`}
              label="Séries"
              format="## séries"
              compact
              style={{ width: "50%" }}
            />
            <NumberInput
              name={`${name}.${index}.repetitions`}
              label="Repetições"
              format="## repetições"
              compact
              style={{ width: "50%" }}
            />
          </div>
          <div>
            <TextInput
              name={`${name}.${index}.specifications`}
              label="Especificações sobre o exercício"
            />
          </div>
        </>
      )}
    </ExerciseWrapper>
  </div>
);

const AddExercises = ({
  name,
  model,
  exercises,
  label,
  madeTraining,
  values,
}) => {
  const [showExercises, setShowExercises] = useState(null);
  const [searchText, setSearchText] = useState("");

  const getCategories = useCallback(() => {
    const categoriesNames = _.get(model, ["categoriesExercises"], []).map(
      (name) => name.split("-")[0]
    );

    var unique = categoriesNames.filter(
      (value, index, self) => self.indexOf(value) === index
    );

    return unique.map((value) => ({
      name: value,
      times: categoriesNames.filter((name) => name === value).length,
    }));
  }, [model]);

  const getExercises = useCallback(() => {
    let result = exercises.filter((e) => e.category.startsWith(showExercises));
    if (_.isEmpty(searchText)) {
      return result;
    }

    return result.filter(
      (e) =>
        _.get(e, "title", "")
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        _.get(e, "category", "")
          .toUpperCase()
          .includes(searchText.toUpperCase()) ||
        _.get(e, "description", "")
          .toUpperCase()
          .includes(searchText.toUpperCase())
    );
  }, [exercises, showExercises, searchText]);

  return (
    <FieldArray
      name={`${name}.${model._id}.exercises`}
      render={({ push, remove, form }) => (
        <>
          <div style={{ padding: 17 }}>
            <Row>
              <Col>
                <Title>{model.title}</Title>
                <Subtitle>{model.description}</Subtitle>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <AddButton
                  onClick={() =>
                    madeTraining(model._id, values.consultationDate)
                  }
                  style={{ borderStyle: "none" }}
                >
                  Marcar como feito "{values.consultationDate}"
                </AddButton>
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {_.get(form, ["values", name, model._id, "exercises"], []).map(
                  (exercise, exerciseIndex) => (
                    <Exercise
                      key={exerciseIndex}
                      name={`${name}.${model._id}.exercises`}
                      index={exerciseIndex}
                      exercise={exercise}
                      onRemove={() => remove(exerciseIndex)}
                    />
                  )
                )}
              </Col>
            </Row>

            <Row>
              <Col style={{ display: "flex", flexWrap: "wrap" }}>
                {getCategories().map((category) => (
                  <AddButton
                    key={category.name}
                    style={{
                      minWidth: 30,
                      width: "auto",
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 12,
                      height: 40,
                      marginRight: 20,
                      marginBottom: 10,
                      textTransform: "none",
                      color: "var(--text)",
                    }}
                    onClick={() => setShowExercises(category.name)}
                  >
                    <AddIcon style={{ height: 15 }} />
                    Adicione {category.times} exercício
                    {category.times > 1 ? "s" : ""} de {category.name}
                  </AddButton>
                ))}
              </Col>
            </Row>

            <Row>
              <Col>
                <TextInput
                  name={`${name}.${model._id}.observations`}
                  label={`Observações do ${model.title}`}
                  placeholder="Descreva aqui observações adicionais"
                />
              </Col>
            </Row>
          </div>
          <LeftPanel
            large
            open={!_.isEmpty(showExercises)}
            onClose={() => {
              setShowExercises(null);
              setSearchText("");
            }}
          >
            <Row>
              <Col>
                <Title>Banco de exercícios</Title>
                <Subtitle>
                  Você está adicionando a{" "}
                  <strong>{_.get(model, ["title"])}</strong> - {showExercises}
                </Subtitle>
              </Col>
              <Col>
                <SearchInput
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Pesquise exercícios"
                />
              </Col>
            </Row>
            <SaveButton
              type="button"
              style={{ width: 140 }}
              onClick={() => {
                setShowExercises(null);
                setSearchText("");
              }}
            >
              Voltar
            </SaveButton>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {getExercises().map((exercise) => (
                <Exercise
                  key={exercise._id}
                  exercise={exercise}
                  name={name}
                  index={-1}
                  onClick={() => {
                    push(exercise);
                    setShowExercises(null);
                  }}
                />
              ))}
            </div>
          </LeftPanel>
        </>
      )}
    />
  );
};

function Training() {
  const { clientId } = useParams();
  const clientToggled = useSelector(selectToggled);
  const [loadingTrainings, setLoadingTrainings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [data, setData] = useState({
    models: [],
    current: {},
    made: [],
  });

  const getData = useCallback(async () => {
    setLoadingTrainings(true);
    try {
      let response = await TrainingService.getAllExercises();
      let newExercises = _.get(response, ["data", "data", "items"]);
      setExercises(newExercises);

      response = await TrainingService.getAllModelTrainings();
      const models = _.get(response, ["data", "data", "items"]).reverse();

      response = await TrainingService.getAll(clientId, models.length);

      let observations = "";
      let trainingsData = await _.get(response, ["data", "data", "items"]);
      const trainings = {};

      trainingsData = trainingsData.filter((e) => e.modelTraining).reverse();
      trainingsData.forEach((t) => {
        observations = t.observations;
        trainings[t.modelTraining._id] = t;
      });

      response = await TrainingService.getAllMadeTraining(clientId);
      let made = await _.get(response, ["data", "data", "items"]);

      made = made.map((m) => {
        const filtered = models.filter((t) => t._id === m.training);
        return filtered.length ? { ...m, training: filtered[0] } : m;
      });

      Object.keys(trainings).forEach((key) => {
        const categories = _.get(trainings[key], [
          "modelTraining",
          "categoriesExercises",
        ]);

        const exercisesMap = trainings[key].exercises
          .map((e) => {
            const exercise = _.first(
              newExercises.filter((ex) => ex._id === e.exercise)
            );

            return { ...exercise, ...e };
          })
          .sort(
            (a, b) =>
              categories.indexOf(a.category) - categories.indexOf(b.category)
          );
        trainings[key].exercises = exercisesMap;
      });

      setData({
        ...data,
        models,
        current: { trainings, observations },
        made,
      });
    } catch (e) {}

    setLoadingTrainings(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const save = useCallback(
    async (values) => {
      if (_.isEmpty(clientId)) return;

      setLoading(true);
      try {
        const { trainings } = values;

        Object.keys(trainings).map(async (modelTraining) => {
          const training = trainings[modelTraining];
          const { exercises, observations } = training;
          const exerciseData = (exercises || []).map((e) => {
            const { series, repetitions, specifications, _id, exercise } = e;
            return {
              series,
              repetitions,
              specifications,
              exercise: _id || exercise,
            };
          });

          const saveData = {
            client: clientId,
            modelTraining,
            exercises: exerciseData,
            observations,
          };
          await TrainingService.insert(saveData);
        });
        getData();
      } catch (e) {}
      setLoading(false);
    },
    [clientId, getData]
  );

  const made = useCallback(
    async (training, consultationDate) => {
      setLoading(true);
      try {
        const data = {
          client: clientId,
          responsibleProfessional: "5e67b452d01e87c53f7ee97d",
          training,
          when: moment(consultationDate, "DD/MM/YYYY").format(),
          weekDay: moment(consultationDate, "DD/MM/YYYY")
            .locale("en-US")
            .format("dddd")
            .toLowerCase(),
        };
        await TrainingService.insertMadeTraining(data);
        getData();
        Swal.fire("Realizado!", "Treino realizado com sucesso!", "success");
      } catch (e) {
        Swal.fire("Erro!", "Não foi possível realizar o treino.", "error");
      }
      setLoading(false);
    },
    [clientId, getData]
  );

  const deleteTraining = useCallback(
    async (training) => {
      setLoading(true);
      try {
        await TrainingService.deleteMadeTraining(training);
        getData();
        Swal.fire("Excluído!", "Treino excluído com sucesso!", "success");
      } catch (e) {
        Swal.fire("Erro!", "Não foi possível excluir o treino.", "error");
      }
      setLoading(false);
    },
    [getData]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const renderResumo = () => (
    <Row>
      <Col>
        <DateInput name="consultationDate" label="Data do Encontro" />
      </Col>
      <Col />
    </Row>
  );

  const renderTraining = ({ values }) => (
    <>
      <Row>
        <Col>
          <H1 color="training">Haux Training</H1>
        </Col>
      </Row>
      <FieldArray
        name={"trainings"}
        render={({ push, remove, form }) => (
          <>
            {data.models.map((model, index) => (
              <div key={model._id} style={{ marginBottom: 70 }}>
                <AddExercises
                  model={model}
                  exercises={exercises}
                  values={values}
                  name="trainings"
                  index={index}
                  label="Treino 01 - Força"
                  madeTraining={made}
                />
              </div>
            ))}
          </>
        )}
      />
    </>
  );

  const renderHistorico = () => (
    <>
      <Row>
        <Col>
          <H1 color="training">Histórico</H1>
        </Col>
      </Row>
      {data.made.map((made, index) => (
        <Row key={index} style={{ paddingLeft: 17, paddingRight: 17 }}>
          <Col>
            <Title>
              {moment(made.when)
                .locale("pt-BR")
                .format("DD/MM/YYYY dddd")}{" "}
              - {made.time} MIN - {_.get(made, ["training", "title"])}
            </Title>
            <button
              type="button"
              style={{
                color: "#ff4548",
                fontFamily: "var(--font-heavy)",
                marginBottom: 20,
              }}
              onClick={() => {
                Swal.fire({
                  title: `Deseja realmente excluir '${moment(made.when)
                    .locale("pt-BR")
                    .format("DD/MM/YYYY dddd")}'?`,
                  text: "Você não poderá desfazer esta ação!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "var(--success)",
                  confirmButtonText: "Sim, excluir!",
                  cancelButtonText: "Desistir",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteTraining(made._id);
                  }
                });
              }}
            >
              Excluir
            </button>
          </Col>
        </Row>
      ))}
    </>
  );

  const renderSave = (dirty) => (
    <FooterContainer visible={dirty} clientToggled={clientToggled}>
      VOCÊ FEZ ALTERAÇÕES NA FICHA
      <SaveButton type="submit">
        {loading && <Loading />}
        {loading ? "Salvando" : "Salvar"} Alterações
      </SaveButton>
    </FooterContainer>
  );

  if (loadingTrainings) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingOrange style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        consultationDate: moment().format("DD/MM/YYYY"),
        ...data.current,
      }}
      enableReinitialize={true}
      onSubmit={(values) => {
        save(values);
      }}
      // validationSchema={MeetingSchema}
      render={({ values, dirty }) => {
        return (
          <Form
            onKeyDown={(keyEvent) => {
              if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                keyEvent.preventDefault();
              }
            }}
          >
            <Section>
              {renderResumo({ values })}
              {renderTraining({ values })}
              {renderHistorico()}
              {renderSave(dirty)}
            </Section>
          </Form>
        );
      }}
    />
  );
}

export default Training;
