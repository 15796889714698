import { createSelector } from "reselect";

export const selectAnamnesisState = createSelector(
  (state) => state.anamnesis,
  (anamnesis) => anamnesis
);

export const selectLoading = createSelector(
  selectAnamnesisState,
  (anamnesis) => anamnesis.loading
);

export const selectParQuiz = createSelector(
  selectAnamnesisState,
  (anamnesis) => anamnesis.parquiz
);

export const selectAnamnesis = createSelector(
  selectAnamnesisState,
  (anamnesis) => anamnesis.anamnesis
);

export const selectAnamnesisIndex = createSelector(
  selectAnamnesisState,
  (anamnesis) => anamnesis.anamnesisIndex
);

export const selectCurrentAnamnesis = createSelector(
  selectAnamnesisState,
  (anamnesis) =>
    anamnesis.anamnesisIndex < anamnesis.anamnesis.length
      ? anamnesis.anamnesis[anamnesis.anamnesisIndex].asMutable({ deep: true })
      : {}
);
