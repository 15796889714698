import React, { useCallback, useEffect, useState } from "react";
import FoodItem from "../FoodItem";
import {
  Container,
  AddButton,
  AddIcon,
  Row,
  EnergyOrangeIcon,
  FoodsWrapper,
  CardWrapper,
  CardTitle,
  CardValue,
  CardUnit,
  MealWrapper,
  RemoveButton,
} from "./styles";

export const MealItem = ({ meal, onClick, onRemove }) => (
  <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
    {onRemove && (
      <RemoveButton
        style={{ marginRight: 20 }}
        onClick={() => {
          onRemove(meal);
        }}
      />
    )}
    <MealWrapper onClick={onClick} style={{ width: "100%" }}>
      <img src={meal.image} alt={meal.name} />
      <div>
        <p>{meal.name}</p>
        <span>
          {meal.difficulty} - {meal.calories}Kcal
        </span>
      </div>
    </MealWrapper>
  </div>
);

function FoodList({ onAdd, foods, meals, onChangeFood, onRemove }) {
  const [listFoods, setListFoods] = useState([]);

  useEffect(() => {
    const newFoods = [...foods];

    setListFoods(newFoods);
  }, [foods, meals]);

  const getInfo = useCallback(() => {
    const toNumber = (str) => {
      const n = Number(str.replace(",", "."));

      return isNaN(n) ? 0 : n;
    };

    return {
      energyKcal: listFoods.reduce(
        (sum, food) =>
          sum +
          toNumber(food.energyKcal) *
            (food.portionProportion === undefined ? 1 : food.portionProportion),
        0
      ),
      carbohydrate: listFoods.reduce(
        (sum, food) =>
          sum +
          toNumber(food.carbohydrate) *
            (food.portionProportion === undefined ? 1 : food.portionProportion),
        0
      ),
      protein: listFoods.reduce(
        (sum, food) =>
          sum +
          toNumber(food.protein) *
            (food.portionProportion === undefined ? 1 : food.portionProportion),
        0
      ),
      lipids: listFoods.reduce(
        (sum, food) =>
          sum +
          toNumber(food.lipids) *
            (food.portionProportion === undefined ? 1 : food.portionProportion),
        0
      ),
      food_fiber: listFoods.reduce(
        (sum, food) =>
          sum +
          toNumber(food.food_fiber) *
            (food.portionProportion === undefined ? 1 : food.portionProportion),
        0
      ),
      cholesterol: listFoods.reduce(
        (sum, food) =>
          sum +
          toNumber(food.cholesterol) *
            (food.portionProportion === undefined ? 1 : food.portionProportion),
        0
      ),
    };
  }, [listFoods]);

  const renderCard = (name, value, unit, showIcon) => (
    <CardWrapper>
      <CardTitle highlight={showIcon}>{name}</CardTitle>
      <Row>
        <div style={{ display: "flex" }}>
          <CardValue highlight={showIcon}>
            {Intl.NumberFormat("pt-BR", { maximumSignificantDigits: 3 }).format(
              value
            )}
          </CardValue>
          <CardUnit highlight={showIcon}>{unit}</CardUnit>
        </div>
        {showIcon && <EnergyOrangeIcon />}
      </Row>
    </CardWrapper>
  );

  const renderResumo = () => (
    <Row
      style={{
        justifyContent: "flex-start",
        paddingLeft: 17,
        paddingRight: 17,
      }}
    >
      {renderCard(
        "Média calórica da refeição",
        getInfo().energyKcal,
        "KCal",
        true
      )}
      {renderCard("Carboidratros", getInfo().carbohydrate, "g")}
      {renderCard("Proteínas", getInfo().protein, "g")}
      {renderCard("Lipídeos", getInfo().lipids, "g")}
      {renderCard("Fibras", getInfo().food_fiber, "g")}
      {renderCard("Colesterol", getInfo().cholesterol, "g")}
    </Row>
  );

  return (
    <Container>
      {meals && (
        <AddButton
          style={{ right: 350, width: 200 }}
          onClick={() => {
            window
              .open("http://44.192.81.173/admin/login/?next=/admin/", "_blank")
              .focus();
          }}
        >
          Cadastrar
        </AddButton>
      )}

      <AddButton onClick={onAdd}>
        <AddIcon />
        Adicionar
      </AddButton>

      {meals.map((meal) => (
        <MealItem key={meal.id} meal={meal} onRemove={onRemove} />
      ))}

      <FoodsWrapper>
        {listFoods.map((food, index) => (
          <div
            key={food.id}
            style={{ display: "flex", alignItems: "center", marginBottom: 25 }}
          >
            <RemoveButton
              onClick={() => {
                if (onRemove) {
                  onRemove(food);
                }
              }}
            />
            <FoodItem
              food={food}
              canEdit={true}
              onChange={(food) => {
                const newListFood = [...listFoods];
                newListFood[index] = food;
                setListFoods(newListFood);
                onChangeFood(food);
              }}
            />
          </div>
        ))}
      </FoodsWrapper>

      {!meals && renderResumo()}
    </Container>
  );
}

FoodList.defaultProps = {
  foods: [],
  meals: [],
  onAdd: () => {},
};

export default FoodList;
