import { takeLatest, all } from "redux-saga/effects";

/* ------------- Types ------------- */

import AuthTypes from "../ducks/Auth/types";
import ClientTypes from "../ducks/Client/types";
import AnamnesisTypes from "../ducks/Anamnesis/types";

/* ------------- Sagas ------------- */

import { auth } from "./AuthSagas";
import { getAllClients, setCurrentClient } from "./ClientSagas";
import { getAllAnamnesis, insertAnamnesis } from "./AnamnesisSagas";

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([takeLatest(AuthTypes.REQUEST, auth)]);
  yield all([takeLatest(ClientTypes.GET_ALL, getAllClients)]);
  yield all([takeLatest(ClientTypes.SET_CURRENT_CLIENT, setCurrentClient)]);
  yield all([takeLatest(AnamnesisTypes.GET_ALL, getAllAnamnesis)]);
  yield all([takeLatest(AnamnesisTypes.SAVE, insertAnamnesis)]);
}
