import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "@unform/core";
import FileService from "../../services/FileService";
import {
  Container,
  Label,
  DragContainer,
  UploadImage,
  ListContainer,
  ListItemWrapper,
  ListItemCheck,
  Check,
  Loading,
} from "./styles";
import { isString } from "lodash";

function UploadFiles({ name, label, files }) {
  const filesRef = useRef();
  const valuesRef = useRef({});
  const containersRef = useRef({});
  const checksRef = useRef({});
  const loadingsRef = useRef({});
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: filesRef.current,
      path: "files",
      getValue: () => {
        if (files.length === 1) {
          return valuesRef.current[files[0].name];
        }
        return valuesRef.current;
      },
    });
  }, [fieldName, registerField, files]);

  useEffect(() => {
    if (defaultValue) {
      if (isString(defaultValue)) {
        setFieldValue(files[0].name, defaultValue, false, true);
      } else {
        Object.keys(defaultValue).forEach((key) => {
          setFieldValue(key, defaultValue[key], false, true);
        });
      }
    }
  }, [defaultValue, files]);

  const setFieldValue = (name, value, uploading, checked) => {
    if (uploading) {
      containersRef.current[name].style.backgroundColor = "var(--success)";
      containersRef.current[name].style.borderWidth = 0;
      loadingsRef.current[name].style.display = "block";
      checksRef.current[name].style.display = "none";
    } else if (checked) {
      containersRef.current[name].style.backgroundColor = "var(--success)";
      containersRef.current[name].style.borderWidth = 0;
      loadingsRef.current[name].style.display = "none";
      checksRef.current[name].style.display = "block";
    }

    if (value) {
      valuesRef.current[name] = value;
    }
  };

  const getFileSettingsWithName = (fileToUpload) => {
    let result = null;
    files.reverse().forEach((file) => {
      if (file.valid(fileToUpload.name)) {
        result = file;
      }
    });

    return result;
  };

  const uploadFiles = async () => {
    const filesToUpload = [...filesRef.current.files];
    filesRef.current.value = "";
    filesToUpload.forEach(async (file) => {
      const fileSetting = getFileSettingsWithName(file);
      if (fileSetting) {
        setFieldValue(fileSetting.name, undefined, true, false);

        const formData = new FormData();
        formData.append("image", file);
        try {
          const response = await FileService.uploadImage(formData);

          const link = response.data.data.file.url;
          setFieldValue(fileSetting.name, link, false, true);
        } catch (e) {}
      }
    });
  };

  return (
    <Container>
      <input
        ref={filesRef}
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={() => {
          uploadFiles();
        }}
      />
      <Label>{label}</Label>
      <DragContainer
        error={!!error}
        onClick={() => {
          filesRef.current.click();
        }}
      >
        <UploadImage />
        Clique nessa área para fazer upload
      </DragContainer>
      <ListContainer>
        {files.map((file, index) => (
          <ListItemWrapper
            key={file.name}
            onClick={() => {
              if (valuesRef.current[file.name]) {
                window.open(valuesRef.current[file.name], "_blank");
              }
            }}
          >
            <ListItemCheck
              ref={(ref) => (containersRef.current[file.name] = ref)}
            >
              <Loading ref={(ref) => (loadingsRef.current[file.name] = ref)} />
              <Check ref={(ref) => (checksRef.current[file.name] = ref)} />
            </ListItemCheck>
            {index + 1} - {file.label}
          </ListItemWrapper>
        ))}
      </ListContainer>
    </Container>
  );
}

UploadFiles.defaultProps = {
  name: "",
  files: [],
};

UploadFiles.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      valid: PropTypes.func,
    })
  ),
};

export default UploadFiles;
