import * as Yup from "yup";
const REQUIRED = 'Campo obrigatório'

const schema = Yup.object().shape({
    username: Yup.string().required(REQUIRED),
    name: Yup.string().required(REQUIRED),
    profileType: Yup.string().required(REQUIRED),
    email: Yup.string().required(REQUIRED).test('formato', 'Digite um e-mail válido', (value) => {
        return value?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    }),
    register: Yup.string().required(REQUIRED),
    password: Yup.string().required(REQUIRED).min(4),
    passwordConfirm: Yup.string().required(REQUIRED).min(4).oneOf([Yup.ref('password'), null], 'A senha e confirmação não correspondem'),
    franchisesExternal: Yup.array(Yup.number()).required("Selecione pelo menos uma franquia").min(1,"Selecione pelo menos uma franquia"),
});

export default schema;
