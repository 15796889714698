import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import Select from "../Select";
import {
  Container,
  Wrapper,
  Option,
  Label,
  InputContainer,
  Input,
} from "./styles";
import { isArray } from "lodash";

const getDefaultValues = (leftOptions, options, rightOptions, defaultValue) => {
  const newValues = {};

  options.forEach((option) => {
    let defaultItem = {};
    if (isArray(defaultValue)) {
      const filtered = defaultValue.filter(
        (d) => d.evaluation === option.value
      );
      defaultItem = filtered.length > 0 ? filtered[0] : {};
    } else {
      defaultItem =
        defaultValue.evaluation === option.value ? defaultValue : {};
    }
    newValues[option.value] = {
      evaluation: option.value,
      period: defaultItem.period || rightOptions[0],
      frequency: defaultItem.frequency || leftOptions[0],
    };
  });
  return newValues;
};

const Options = ({
  label,
  name,
  multiple,
  otherId,
  showOther,
  leftAndRightLabels,
  leftOptions,
  rightOptions,
  onChange,
  options,
  condensed,
}) => {
  const inputRefs = useRef([]);
  const otherContainerRef = useRef(null);
  const otherInputRef = useRef(null);
  const leftAndRightRefs = useRef([]);

  const { fieldName, registerField, defaultValue = [], error } = useField(name);
  const otherField = useField(otherId);

  const [selected, setSelected] = useState([]);
  const [values, setValues] = useState(
    getDefaultValues(leftOptions, options, rightOptions, defaultValue) || {}
  );
  const valuesRef = useRef({});

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: () => {
        const currentValues = valuesRef.current;
        const transformedSelectedOptions = selected.map((o) => {
          return {
            ...currentValues[o],
          };
        });

        if (multiple) {
          return transformedSelectedOptions;
        } else if (transformedSelectedOptions.length === 0) {
          return undefined;
        } else {
          const item = transformedSelectedOptions[0];

          return item;
        }
      },
      clearValue: () => {
        setSelected([]);
      },
      setValue: (_, values) => {
        if (isArray(values)) {
          setSelected(values);
        } else {
          setSelected([String(values)]);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerField]);

  useEffect(() => {
    otherField.registerField({
      name: otherField.fieldName,
      ref: otherInputRef.current,
      path: "value",
    });
  }, [otherField]);

  return (
    <Container error={!!error}>
      <Label>{label}</Label>
      <Wrapper>
        {options.map((option, index) => (
          <Option
            key={option.value}
            onClick={() => {
              setTimeout(() => {
                onChange();
              }, 0);

              const noneOptions = ["Nenhum", "Não"];
              const isNone = noneOptions.includes(option.value);
              const items = selected.filter((e) => !noneOptions.includes(e));

              if (selected.includes(String(option.value))) {
                setSelected([...selected.filter((e) => e !== option.value)]);
              } else {
                setSelected([
                  ...(multiple && !isNone ? items : []),
                  String(option.value),
                ]);
              }
            }}
            condensed={condensed}
            selected={selected.includes(String(option.value))}
          >
            {option.label}

            <div
              ref={(ref) => {
                leftAndRightRefs.current[index] = ref;
              }}
              style={{
                marginLeft: -17,
                marginRight: -17,
                display: selected.includes(String(option.value))
                  ? "flex"
                  : "none",
              }}
            >
              <Row>
                <Col style={{ minWidth: 250 }}>
                  <Select
                    label={leftAndRightLabels[0]}
                    options={leftOptions.map((e) => ({ label: e, value: e }))}
                    value={(values[option.value] || {}).frequency}
                    onChange={(v) => {
                      values[option.value].frequency = v;
                      setValues({ ...values });
                    }}
                  />
                </Col>
                <Col>
                  <Select
                    label={leftAndRightLabels[1]}
                    options={rightOptions.map((e) => ({
                      label: e,
                      value: e,
                    }))}
                    value={(values[option.value] || {}).period}
                    onChange={(v) => {
                      values[option.value].period = v;
                      setValues({ ...values });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Option>
        ))}
      </Wrapper>

      <InputContainer
        style={{
          display:
            showOther &&
            (selected.includes("Outro") || selected.includes("Outros"))
              ? "flex"
              : "none",
        }}
        ref={otherContainerRef}
        onMouseEnter={() => otherInputRef.current.focus()}
      >
        <Label>Outros</Label>
        <Input
          ref={otherInputRef}
          name={otherId}
          defaultValue={otherField.defaultValue}
        />
      </InputContainer>
    </Container>
  );
};

Options.defaultProps = {
  label: "",
  value: "",
  onChange: () => {},
  options: [],
  condensed: false,
  multiple: true,
  showOther: true,
  otherId: "other",
  showLeftAndRight: false,
  leftAndRightLabels: ["Frequência", "Período"],
  leftAndRightIds: ["leftId", "rightId"],
  leftOptions: [],
  rightOptions: [],
};

Options.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string,
    })
  ),
  condensed: PropTypes.bool,
  multiple: PropTypes.bool,
  showOther: PropTypes.bool,
  otherId: PropTypes.string,
  showLeftAndRight: PropTypes.bool,
  leftAndRightLabels: PropTypes.arrayOf(PropTypes.string),
  leftOptions: PropTypes.arrayOf(PropTypes.string),
  rightOptions: PropTypes.arrayOf(PropTypes.string),
};

export default Options;
