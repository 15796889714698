import React, { useCallback, useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { selectUser } from "redux/ducks/Auth/selectors";
import {
  FormProvider,
  useForm,
  useWatch,
  Control,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useHistory } from "react-router-dom";
import { LoadingPurple } from "../../../components/Loading";
import { NumberInput, CheckGroup, RadioGroup } from "../../../components/3.0";
import { TextInput, TextArea, Select } from "atoms";
import { PhysicalTitle, HR } from "../../../components/new/Title";
import SaveBottom from "../../../components/SaveBottom";
import { Section } from "./styles";
import HEALTH_PROBLEMS from "../../../utils/healthProblems";
import MUSCLE_ACHES from "../../../utils/muscleAches";
import MOVEMENT_DIFFICULTY from "../../../utils/movementDifficulty";
import PHYSICAL_ACTIVITIES from "../../../utils/physicalActivities";
import ClientContext from "../../../context/ClientContext";
import AnamnesisService from "../../../services/AnamnesisService";
import _ from "lodash";
import PhysicalSchema, { IPhysical } from "./schema";
import FileCenter from "components/3.0/FileCenter";

interface MethodsProps {
  control: Control<IPhysical>;
  setValue: UseFormSetValue<IPhysical>;
}

const ConsultationDate: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <PhysicalTitle>Consulta atual</PhysicalTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12">
        <div className="col-span-8">
          <TextInput
            name="consultationDate"
            label="Data da Consulta"
            type="date"
          />
        </div>
      </div>
    </Section>
  );
};

const InformacoesGerais: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <PhysicalTitle>Informações Gerais</PhysicalTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12">
        <div className="col-span-8">
          <TextArea name="goal" label="Objetivos do Cliente" />
        </div>
        <div className="col-span-8">
          <CheckGroup
            name="healthProblems.value"
            label="Problemas de Saúde"
            options={HEALTH_PROBLEMS}
            renderOther={
              <TextInput name="healthProblems.other" label="Outros" />
            }
          />
        </div>
        <div className="col-span-8">
          <RadioGroup
            name="stressLevel"
            label="Nível de Estresse"
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
              label: `${value}`,
              value: value,
            }))}
            compact
          />
        </div>
        <div className="col-span-8">
          <CheckGroup
            name="musclePains.value"
            label="Possui dores articulares ou musculares frequentes?"
            options={MUSCLE_ACHES}
            renderOther={<TextInput name="musclePains.other" label="Outro" />}
          />
        </div>
        <div className="col-span-8">
          <CheckGroup
            name="difficultiesInMoving"
            label="Possui alguma dificuldade de se movimentar?"
            options={MOVEMENT_DIFFICULTY}
            renderOther={
              <TextInput name="difficultiesInMovingOther" label="Outro" />
            }
          />
        </div>
        <div className="col-span-8">
          <CheckGroup
            name="pastActivities.value"
            label="Praticou alguma atividade física?"
            options={PHYSICAL_ACTIVITIES}
            renderOther={
              <TextInput name="pastActivities.other" label="Outro" />
            }
          />
        </div>
        <div className="col-span-8">
          <CheckGroup
            name="yourActivities.value"
            label="Pratica alguma atividade física?"
            options={PHYSICAL_ACTIVITIES}
            renderOther={
              <TextInput name="yourActivities.other" label="Outro" />
            }
          />
        </div>
        <div className="col-span-8">
          <RadioGroup
            name="leisureTimeActivity"
            label="Nível de Atividade Física atual"
            options={[
              { label: "Nenhuma / Muito Leve", value: 1 },
              { label: "Leve", value: 2 },
              { label: "Moderada", value: 3 },
              { label: "Intensa", value: 4 },
              { label: "Muito Intensa", value: 5 },
            ]}
          />
        </div>
        <div className="col-span-8">
          <RadioGroup
            name="workActivity"
            label="Nível de Atividade Física no Trabalho"
            options={[
              { label: "Nenhuma / Muito Leve", value: 1 },
              { label: "Leve", value: 2 },
              { label: "Moderada", value: 3 },
              { label: "Intensa", value: 4 },
            ]}
          />
        </div>
        <div className="col-span-8">
          <TextArea
            name="physicalEducatorObservations"
            label="Observações do Educador Físico"
          />
        </div>
      </div>
    </Section>
  );
};

const Metas: React.FC<MethodsProps> = (props) => {
  return (
    <>
      <Section>
        <PhysicalTitle>Metas</PhysicalTitle>
        <HR />
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className="col-span-3">
            <NumberInput
              name="weeklyAerobicMinutes"
              label="Atividade Aeróbica"
              suffix=" minutos semanais"
            />
          </div>
          <div className="col-span-2">
            <NumberInput
              name="weeklyForceTimes"
              label="Atividade de Força"
              suffix=" vezes por semana"
            />
          </div>
          <div className="col-span-3">
            <Select
              name="levelYourCurrentActivities"
              label="Nível Atual de Atividade Física"
              defaultValue={"Adaptação"}
              options={[
                { label: "Adaptação", value: "Adaptação" },
                { label: "Iniciante", value: "Iniciante" },
                { label: "Intermediário", value: "Intermediário" },
                { label: "Avançado", value: "Avançado" },
              ]}
            />
          </div>
          <div className="col-span-8">
            <TextArea name="goalObservations" label="Observações das metas" />
          </div>
        </div>
      </Section>
    </>
  );
};

const Observations: React.FC<MethodsProps> = (props) => {
  return (
    <Section>
      <PhysicalTitle>Observações</PhysicalTitle>
      <HR />
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
        <div className="col-span-8">
          <TextArea name="observations" label="Observações" />
        </div>
        <div className="col-span-8">
          <TextArea
            name="hauxApproach"
            label="Como você gostaria de ser abordado caso a equipe perceba que você não está seguindo o programa?"
          />
        </div>
      </div>
    </Section>
  );
};

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
}

const Anamnesis: React.FC = () => {
  const methods = useForm<IPhysical>({ resolver: yupResolver(PhysicalSchema) });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { reset, control, handleSubmit, formState } = methods;
  const data = useWatch({ control });
  const createdAt = useWatch({ control, name: "createdAt" });
  const finished = useWatch({ control, name: "finish" });
  const history = useHistory();
  const { clientId, anamnesisId } = useParams<ClientParams>();

  const getAnamnesisData = useCallback(async () => {
    if (anamnesisId) {
      setLoading(true);
      try {
        const response = await AnamnesisService.getById(
          "physical",
          anamnesisId
        );
        const item = _.get(response, ["data", "data"]);
        if (item) {
          reset({ ...item, client: clientId });
        } else {
          throw new Error();
        }
      } catch (e) {
        NotificationManager.error("Erro ao obter dados da anamnese");
        history.push(`/client/${clientId}/physical`);
      }
      setLoading(false);
    }
  }, [clientId, anamnesisId, reset, setLoading, history]);

  useEffect(() => {
    getAnamnesisData();
  }, [getAnamnesisData]);

  const getDataTransform = (data, finished = false) => {
    const { goals, ...values } = data;
    Object.keys(goals || {}).forEach((key) => {
      if (goals[key] === "undefined" || goals[key] === null) {
        goals[key] = undefined;
      }
    });

    if (finished) {
      values.finish = true;
    }

    return { goal: goals, ...values };
  };

  const onSave = useCallback(
    async (data, published = false) => {
      setSaving(true);
      try {
        const values = { ...data, finish:  published ?? data.finish };
        await AnamnesisService.updateAnamnesis("physical", anamnesisId, values);
        NotificationManager.success(
          `Ficha ${published ?  "publicada" : "salva"} com sucesso`
        );
      } catch (e) {
        NotificationManager.error("Erro ao salvar ficha");
      }
      setSaving(false);
    },
    [setSaving]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        await onSave(data, true);
        getAnamnesisData();
      } catch (errors) {
        //@ts-ignore
        console.log(errors.inner);
        NotificationManager.error(
          "Preencha todos os campos obrigatórios para publicar"
        );
      }
    },
    [onSave, getAnamnesisData]
  );

  const onError = () => {
    NotificationManager.error(
      "Preencha todos os campos obrigatórios para publicar"
    );

    setTimeout(() => {
      //@ts-ignore
      const [firstError]: [HTMLElement] = document.getElementsByClassName(
        "field-error"
      );
      //@ts-ignore
      firstError.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 1000);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingPurple style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ paddingBottom: 50 }}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {anamnesisId && (
          <>
            <ConsultationDate {...methods} />
            <InformacoesGerais {...methods} />
            <FileCenter
              clientId={clientId}
              color={"var(--physical)"}
              title="Arquivos da ficha de Educação Física"
              preffix={`physical/${(createdAt ?? "")}`}
            />
            <Metas {...methods} />
            <Observations {...methods} />
          </>
        )}
        <SaveBottom
          saving={saving}
          touched={formState.isDirty || !finished}
          showPublish={!finished}
          color={"physical"}
          onSave={() => {
            onSave(data, data.finish);
          }}
          published={finished}
        />
      </form>
    </FormProvider>
  );
};

export default Anamnesis;
