import axios from "../middlewares/axios";

export interface INotification {
    _id: string,
    client: {
        _id: string,
        franchise: number;
    },
    title: string,
    message: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    read?: boolean,
    __v: number
}

class NotificationsService {
  static async getNotifications() {
    const url = `/notifications`;
    const response = await axios.get<{
        data: INotification[]
    }>(url);

    return response.data.data;
  }

  static read(id: string) {
    const url = `/notifications/read`;
    return axios.post(url, {
        notifications: [id]
    });
  }
}

export default NotificationsService;