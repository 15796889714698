import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import NumberFormat from "react-number-format";
import { Container, Label, Input } from "./styles";

const NumberInput = ({ label, suffix, compact, style, ...props }) => {
  const inputRef = useRef();
  const fieldProps = useField(props);
  const [field, meta] = fieldProps;
  const { submitCount } = useFormikContext();

  return (
    <Container
      style={style}
      error={!!meta.error && submitCount}
      onMouseEnter={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      <Label compact={compact}>{label}</Label>
      <NumberFormat
        decimalSeparator=","
        thousandSeparator="."
        customInput={Input}
        compact={compact}
        getInputRef={(ref) => (inputRef.current = ref)}
        placeholder={`__ ${suffix || ""}`}
        defaultChecked={false}
        {...props}
        {...field}
      />
    </Container>
  );
};

NumberInput.defaultProps = {
  label: "",
  compact: false,
};

NumberInput.propTypes = {
  label: PropTypes.string,
  compact: PropTypes.bool,
};

export default NumberInput;
