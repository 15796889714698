// @ts-nocheck
import { useState } from "react";
import NumberInput from "./NumberInput";
import TextInput from "../../atoms/TextInput";

const Exam = ({ label, name, references, ...props }) => {
  const [referenceIndex, setReferenceIndex] = useState(-1);

  const Class = props.suffix ? NumberInput : TextInput;

  return (
    <div className="grid grid-rows-1 grid-cols-9 grid-flow-row-dense gap-8 pl-4 pr-4 pt-6">
        <div className='col-span-3'>
          <Class
            // @ts-ignore
            label={label}
            // @ts-ignore
            name={name}
            // @ts-ignore
            {...props}
            // @ts-ignore
            onChange={(e) => {
              if (props.suffix) {
                const number = e.target.value
                  .replace(/[./A-Za-z %/]+/g, "")
                  .replace(",", ".");
                let rIndex = -1;
                references.forEach((r, i) => {
                  if (r.min <= number && r.max > number) {
                    rIndex = i;
                  }
                });
                setReferenceIndex(rIndex);
              }
            }}
          />
        </div>
        <div className='col-span-3'>
          <TextInput name={`${name}Date`} label="Data de realização" type="date"/>
        </div>
        <div className='col-span-3'>
          <div className="flex flex-col justify-center h-full opacity-30">
            {references.map((r, i) => (
              <label key={i} selected={referenceIndex === i}
                className={`
                  text-sm
                  mb-0
                  ${referenceIndex === i ? "text-purple-400 font-bold": "text-gray-400 font-medium"}
                `}
              >
                {r.label}
              </label>
            ))}
          </div>
        </div>
    </div>
  );
};

export default Exam;
