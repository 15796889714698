import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import PropTypes from "prop-types";
import {
  Container,
  Wrapper,
  Option,
  Label,
  InputContainer,
  Input,
} from "./styles";
import { isArray } from "lodash";

const Options = ({
  label,
  name,
  multiple,
  otherId,
  otherLabel,
  showOther,
  onChange,
  options,
  condensed,
}) => {
  const inputRefs = useRef([]);
  const otherContainerRef = useRef(null);
  const otherInputRef = useRef(null);

  const { fieldName, registerField, defaultValue = [], error } = useField(name);
  const otherField = useField(otherId);

  const selectedRef = useRef([]);
  const [selected, setSelected] = useState(
    isArray(defaultValue)
      ? defaultValue
      : String(defaultValue)
      ? [String(defaultValue)]
      : []
  );

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: () => {
        const selectedItems = selectedRef.current;
        if (multiple) {
          return selectedItems;
        } else if (selectedItems.length === 0) {
          return undefined;
        } else {
          const item = selectedItems[0];
          if (item === "true") {
            return true;
          }
          if (item === "false") {
            return false;
          }

          if (!isNaN(item)) {
            return Number(item);
          }

          return item;
        }
      },
      clearValue: () => {
        setSelected([]);
      },
      setValue: (_, values) => {
        if (isArray(values)) {
          setSelected(values);
        } else {
          setSelected([String(values)]);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, fieldName, registerField]);

  useEffect(() => {
    otherField.registerField({
      name: otherField.fieldName,
      ref: otherInputRef.current,
      path: "value",
    });
  }, [otherField]);

  return (
    <Container error={!!error}>
      <Label>{label}</Label>
      <Wrapper>
        {options.map((option, index) => (
          <Option
            key={option.value}
            onClick={() => {
              setTimeout(() => {
                onChange();
              }, 0);

              const noneOptions = ["Nenhum", "Não"];
              const isNone = noneOptions.includes(option.value);
              const items = selected.filter((e) => !noneOptions.includes(e));

              if (selected.includes(String(option.value))) {
                setSelected([...selected.filter((e) => e !== option.value)]);
              } else {
                setSelected([
                  ...(multiple && !isNone ? items : []),
                  String(option.value),
                ]);
              }
            }}
            condensed={condensed}
            selected={selected.includes(String(option.value))}
          >
            {option.label}
          </Option>
        ))}
      </Wrapper>

      <InputContainer
        style={{
          display:
            showOther &&
            (selected.includes("Outro") ||
              selected.includes("Outra") ||
              selected.includes("Outras") ||
              selected.includes("Outros"))
              ? "flex"
              : "none",
        }}
        ref={otherContainerRef}
        onMouseEnter={() => otherInputRef.current.focus()}
      >
        <Label>{otherLabel}</Label>
        <Input
          ref={otherInputRef}
          name={otherId}
          defaultValue={otherField.defaultValue}
          placeholder="Escreva aqui..."
        />
      </InputContainer>
    </Container>
  );
};

Options.defaultProps = {
  label: "",
  value: "",
  onChange: () => {},
  options: [],
  condensed: false,
  multiple: true,
  showOther: true,
  otherId: "other",
  otherLabel: "Outros",
  showLeftAndRight: false,
  leftAndRightLabels: ["Frequência", "Período"],
  leftAndRightIds: ["leftId", "rightId"],
  leftOptions: [],
  rightOptions: [],
};

Options.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string,
    })
  ),
  condensed: PropTypes.bool,
  multiple: PropTypes.bool,
  showOther: PropTypes.bool,
  otherId: PropTypes.string,
  otherLabel: PropTypes.string,
};

export default Options;
