import AUTH from "./types";
import Immutable from "seamless-immutable";

export const INITIAL_STATE = Immutable({
  user: null,
  token: null,
  loading: false,
  errors: null,
});

export const request = (state) => state.merge({ loading: true });

export const success = (state, { user, token }) =>
  state.merge({ loading: false, user, token, errors: null });

export const fail = (state, { errors }) =>
  state.merge({ loading: false, errors });

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH.REQUEST:
      return request(state, action);
    case AUTH.REQUEST_SUCCESS:
      return success(state, action);
    case AUTH.REQUEST_FAIL:
      return fail(state, action);
    default:
      return state;
  }
};

export default AuthReducer;
