import { useContext, useState } from "react";
import {
  useWatch,
  useFormContext,
} from "react-hook-form";
import { 
  UploadFiles,
} from "../../../../components/3.0";
import { TechTitle, HR } from "../../../../components/new/Title";
import { LoadingPurple } from "../../../../components/Loading";
import { Section } from "../styles";
import FileService from "../../../../services/FileService";
import _ from "lodash";

export const StykuFiles: React.FC = () => {
  const { register } = useFormContext();

  return (
    <Section>
      <TechTitle>Upload de arquivos</TechTitle>
      <HR />
      <input hidden {...register('tech3D')} value="Styku"  />
      <div className='grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8'>
        <div className='col-span-8'>
          <UploadFiles
            name="stykuMeasure"
            label="Upload de Arquivos Styku"
            files={[
              {
                name: "body_back",
                label: "Body Back",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_BACK")
                  );
                },
              },
              {
                name: "body_front",
                label: "Body Front",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_FRONT")
                  );
                },
              },
              {
                name: "body_right",
                label: "Body Right",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_RIGHT")
                  );
                },
              },
              {
                name: "body_left",
                label: "Body Left",
                valid: (filename: string) => {
                  return (
                    filename && filename.toUpperCase().includes("BODY_LEFT")
                  );
                },
              },
              {
                name: "silhouette_front",
                label: "Silhouette Front",
                valid: (filename: string) => {
                  return (
                    filename &&
                    filename.toUpperCase().includes("SILHOUETTE_FRONT")
                  );
                },
              },
              {
                name: "silhouette_left",
                label: "Silhouette Left",
                valid: (filename: string) => {
                  return (
                    filename &&
                    filename.toUpperCase().includes("SILHOUETTE_LEFT")
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </Section>
  );
}

export const CampoGrandeFiles: React.FC = () => {
  const { control, setValue, register } = useFormContext();
  const bodyFront = useWatch({ control, name: "stykuMeasure.body_front" });
  const [states, setStates] = useState({ });

  const uploadFiles = async (files, name) => {
    setStates({ ...states, [name]: { saving: true }})
    await [...files].forEach(async (file) => {
      //@ts-ignore
      const formData = new FormData();
      formData.append("image", file);
        try {
          const response = await FileService.uploadImage(formData);
          const link = response.data.data.file.url;
          setStates({ ...states, [name]: { link }})
          setValue(`stykuMeasure.${name}`, link, { shouldDirty: true });
        } catch (e) {
          console.error(e);
          setStates({ ...states, [name]: { saving: false, error: 'Erro ao enviar arquivo' }})
        }
    });
  };

  return (
    <Section>
      <TechTitle>Upload de arquivos</TechTitle>
      <HR />
      <input hidden {...register('tech3D')} value="BodyGee"  />
      <div className='gap-8'>
          {[
            { id: 'body_front', name: 'Imagem (BodyGee)', fieldValue: bodyFront },
          ].map((f) => (
            <div className='col-span-8' key={f.id}>
              <p className="mt-8">{f.name}</p>
              <input
                id={f.id}
                name={f.id}
                type="file"
                onChange={(e) => {
                  //@ts-ignore
                  uploadFiles(e.target.files, f.id);
                }}
              />
              {_.get(states, [f.id, 'saving'], false) && <LoadingPurple style={{ height: 60, marginTop: -10 }} />}
              {_.get(states, [f.id, 'error']) && <p style={{color: 'var(--danger-form)'}}>{_.get(states, [f.id, 'error'])}</p>}
              <br />
              {!_.isEmpty(f.fieldValue) && (
                <>
                  <img alt={f.id} src={f.fieldValue} style={{ height: 200 }}/>
                  <a href={f.fieldValue} target="_blank" style={{ color: 'var(--purple-light)', cursor: 'pointer'}}>{f.fieldValue}</a>
                </>
              )}
            </div>
          ))}
      </div>
    </Section>
  )
}

export const SalvadorFiles: React.FC = () => {
  const { control, setValue, register } = useFormContext();
  const bodyFront = useWatch({ control, name: "stykuMeasure.body_front" });
  const bodyLeft = useWatch({ control, name: "stykuMeasure.body_left" });
  const [states, setStates] = useState({ });


  const uploadFiles = async (files, name) => {
    setStates({ ...states, [name]: { saving: true }})
    await [...files].forEach(async (file) => {
      //@ts-ignore
      const formData = new FormData();
      formData.append("image", file);
        try {
          const response = await FileService.uploadImage(formData);
          const link = response.data.data.file.url;
          setStates({ ...states, [name]: { link }})
          setValue(`stykuMeasure.${name}`, link, { shouldDirty: true });
        } catch (e) {
          console.error(e);
          setStates({ ...states, [name]: { saving: false, error: 'Erro ao enviar arquivo' }})
        }
    });
  };

  return (
    <Section>
      <TechTitle>Upload de arquivos</TechTitle>
      <HR />
      <input hidden {...register('tech3D')} value="fit3D" />
      <div className='gap-8'>
          {[
            { id: 'body_front', name: 'Imagem FRONTAL (Fit3D)', fieldValue: bodyFront },
            { id: 'body_left', name: 'Imagem LATERAL ESQUERDA (Fit3D)', fieldValue: bodyLeft },
          ].map((f) => (
            <div className='col-span-8' key={f.id}>
              <p className="mt-8">{f.name}</p>
              <input
                id={f.id}
                name={f.id}
                type="file"
                onChange={(e) => {
                  //@ts-ignore
                  uploadFiles(e.target.files, f.id);
                }}
              />
              {_.get(states, [f.id, 'saving'], false) && <LoadingPurple style={{ height: 60, marginTop: -10 }} />}
              {_.get(states, [f.id, 'error']) && <p style={{color: 'var(--danger-form)'}}>{_.get(states, [f.id, 'error'])}</p>}
              <br />
              {!_.isEmpty(f.fieldValue) && (
                <>
                  <img alt={f.id} src={f.fieldValue} style={{ height: 200 }}/>
                  <a href={f.fieldValue} target="_blank" style={{ color: 'var(--purple-light)', cursor: 'pointer'}}>{f.fieldValue}</a>
                </>
              )}
              
            </div>
          ))}
      </div>
    </Section>
  )
}

interface UploadImagesProps {
  franchise: number;
}

const  UploadImages: React.FC<UploadImagesProps> = ({ franchise }) => {

  const renderSwitch = () => {
    switch(franchise) {
      case 1000004:
        return <CampoGrandeFiles />
      case 1000009:
        return <SalvadorFiles />
      case -1:
        return null;
      default:
        return <StykuFiles />
    }
  }

  return (
    <>
      {renderSwitch()}
    </>
  )
}

export default UploadImages