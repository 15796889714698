import axios from "../middlewares/axios";

class FranchiseService {
  static getFranchises() {
    const url = `/franchise/mine`;
    return axios.get(url);
  }
}

export default FranchiseService;
