import React, {} from "react";
import { VictoryPie } from "victory";

const PROTEIN_COLOR = "#eb548e";
const CARBO_COLOR = "#7A6AF7";
const LIPIDS_COLOR = "#FD9A4C";

interface IMacronutrients {
    protein: number;
    carbohydrate: number;
    lipids: number;
    calories: number;
}

const Macronutrients: React.FC<IMacronutrients> = ({
    protein,
    carbohydrate,
    lipids,
    calories,
}) => {
    return (
        <div className="block max-w-[25%]">
                <div>
                    <VictoryPie
                        colorScale={[PROTEIN_COLOR, CARBO_COLOR, LIPIDS_COLOR]}
                        padding={0}
                        height={80}
                        width={80}
                        data={[
                            { x: "Proteínas", y: protein },
                            { x: "Carboitratos", y: carbohydrate },
                            { x: "Lipídeos", y: lipids }
                        ]}
                        labelRadius={() => 20 }
                        labels={({ datum }) => `${(datum.y * 100 / (protein + carbohydrate + lipids)).toFixed(2)}%`}
                        style={{ labels: { fill: "white", fontSize: 5, fontWeight: "bold" } }}
                    />
                </div>
                <div className="mt-2">
                    <p className="text-[#eb548e]"><strong>Proteínas:</strong> {protein.toFixed(2)}g</p>
                    <p className="text-[#7A6AF7]"><strong>Carboidratos:</strong> {carbohydrate.toFixed(2)}g</p>
                    <p className="text-[#FD9A4C]"><strong>Lipídeos:</strong> {lipids.toFixed(2)}g</p>
                    <p><strong>Total de calorias:</strong> {calories.toFixed(2)}Kcal</p>
                </div>
        </div>
    )
}

  export default Macronutrients;