import axios from "../middlewares/axios";

class TrainingService {
  static getAll(clientId, limit) {
    const url = `/training?client=${clientId}&sortBy=-createdAt&limit=${limit}`;
    return axios.get(url);
  }

  static insert(data) {
    const url = `/training`;
    return axios.post(url, data);
  }

  static getAllExercises() {
    const url = `/exercises`;
    return axios.get(url);
  }

  static getAllModelTrainings() {
    const url = `/model-training`;
    return axios.get(url);
  }

  static getModelTraining(modelTrainingId) {
    const url = `/model-training/${modelTrainingId}`;
    return axios.get(url);
  }

  static getAllMadeTraining(clientId) {
    const url = `/training/made?client=${clientId}`;
    return axios.get(url);
  }

  static getResume(clientId, startDate, endDate) {
    const url = `/training/resume?client=${clientId}&weekStart=${startDate}&weekEnd=${endDate}`;
    return axios.get(url);
  }

  static insertMadeTraining(data) {
    const url = `/training/made`;
    return axios.post(url, data);
  }

  static deleteMadeTraining(id) {
    const url = `/training/made/${id}`;
    return axios.delete(url);
  }
}

export default TrainingService;
