import React, { useRef } from "react";
import { useField, useFormikContext } from "formik";
import {
  Container,
  Label,
  Dropdown,
  DropdownContent,
  DropdownButton,
  ArrowDown,
} from "./styles";

function Select({ name, label, renderItem, options }) {
  const selectRef = useRef(null);
  const spanRef = useRef(null);
  const contentRef = useRef(null);
  const [, meta, helpers] = useField(name);

  const value = meta.value;
  const { setValue } = helpers;
  const { submitCount } = useFormikContext();

  const setContentVisible = (visibility) => {
    contentRef.current.style.opacity = visibility ? 1 : 0;
    contentRef.current.style.maxHeight = visibility ? "300px" : 0;
  };

  return (
    <Container
      error={!!meta.error && submitCount}
      onMouseEnter={() => setContentVisible(true)}
      onMouseLeave={() => setContentVisible(false)}
    >
      <Label>{label}</Label>
      <Dropdown ref={selectRef}>
        {renderItem ? (
          renderItem(value || "Selecione...")
        ) : (
          <span ref={spanRef} style={{ marginRight: 30 }}>
            {options[0].label}
          </span>
        )}

        <ArrowDown />
        <DropdownContent ref={contentRef}>
          {options.map((option, index) => (
            <DropdownButton
              key={option.value}
              data-value={option.value}
              onClick={(e) => {
                setValue(option.value);
                setContentVisible(false);
                e.stopPropagation();
              }}
            >
              {renderItem ? renderItem(option.value) : option.label}
            </DropdownButton>
          ))}
        </DropdownContent>
      </Dropdown>
    </Container>
  );
}

export default Select;
