import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";
import { store, persistor } from "./redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./theme/global.css";
import { UserProvider } from "context/UserContext";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <Provider store={store}>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <PersistGate loading={null} persistor={persistor}>
              <Routes />
            </PersistGate>
          </QueryClientProvider>
        </UserProvider>
      </Provider>
    </>
  );
}
export default App;
