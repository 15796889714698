import { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import _ from "lodash";
import {
  FormProvider,
  useForm,
  useWatch,
} from "react-hook-form";
import { Select } from 'atoms';
import { FaCheck, FaBan } from 'react-icons/fa';
import SaveBottom from 'components/SaveBottom';
import Pagination from './pagination';
import PermissionService, { IPermission } from 'services/PermissionService';
import UserService, { IUser } from 'services/UserService';

interface UserParams {
  userId: string;
}

const PermissionList = () => {
  const history = useHistory();
  const methods = useForm();
  const { reset, control, formState: { isDirty } } = methods;
  const { userId } = useParams<UserParams>();
  const [user, setUser] = useState<IUser | undefined>()
  const [permissions, setPermissions] = useState<IPermission[]>([]);
	const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);
  const [currentAllowedPermissions, setCurrentAllowedPermissions] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modulesPerPage] = useState(7);
	const permissionType =  useWatch({
    control,
    name: "permissionType",
  });

  const indexOfLastModule = currentPage * modulesPerPage;
  const indexOfFirstModule = indexOfLastModule - modulesPerPage;
  let modules = _.uniq(permissions.map((p) => p.slug.split("_")[0]))
  const currentModules = modules.slice(indexOfFirstModule, indexOfLastModule);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchPermissions = async () => {
			Swal.fire({
				title: 'Obtendo dados',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
						Swal.showLoading()
				}
			});
      const user = await UserService.getById(userId)
      const { items: permissions } = await PermissionService.getAll();
      const profilePermissions = await UserService.getProfilePermissions(user.profileType)
      const userPermissions = await UserService.getProfilePermissions(userId)

			reset({ permissionType: !!userPermissions ? 'custom' : 'profile' })
      setAllowedPermissions(userPermissions?.permissions ?? profilePermissions?.permissions ?? [])
			setCurrentAllowedPermissions(userPermissions?.permissions ?? profilePermissions?.permissions ?? [])
      setUser(user)
      setPermissions(permissions);

			Swal.close();
    };

    fetchPermissions();
  }, []);

	useEffect(() => {
		if (permissionType === 'profile') {
			setCurrentAllowedPermissions(allowedPermissions)
		}
	}, [permissionType])

	const changePermission = useCallback((permission: string) => {
		const index = currentAllowedPermissions.indexOf(permission)
		if (index >= 0) {
			const newAllowed = [...currentAllowedPermissions]
			newAllowed.splice(index, 1)
			setCurrentAllowedPermissions(newAllowed)
		} else {
			setCurrentAllowedPermissions([...currentAllowedPermissions, permission])
		}
	}, [currentAllowedPermissions])

	const savePermissions = useCallback(async () => {
		try {
			Swal.fire({
				title: 'Salvando permissões',
				text: 'Em progresso...',
				timerProgressBar: true,
				didOpen: () => {
						Swal.showLoading()
				}
			});
			await UserService.updateUserPermissions(userId, permissionType === "profile" ? [] : currentAllowedPermissions)
			Swal.fire({
				title: 'Sucesso',
				text: 'Permissões salvas com sucesso',
				icon: 'success',
				didClose: () => {
					history.goBack()
				}
			})
		} catch {
			Swal.fire('Erro ao salvar', 'Verifique sua conexão e tente novamente', 'error')
		}
	}, [userId, currentAllowedPermissions, permissionType])

	if (!user) {
		return;
	}

  return (
    <div className="container mx-auto pb-24">
			<h1 className="text-4xl capitalize mb-2" style={{
				fontFamily: "var(--font-heavy)",
				color: `var(--purple-dark)`
			}}>
				Permissões do usuário
			</h1>
			<div className='py-8 max-w-md'>
				<FormProvider {...methods}>
					<form>
						<label className='text-gray-700 uppercase'>
							Usuário:&nbsp;
							<strong style={{ fontFamily: 'var(--font-heavy)' }}>
								{user?.name}
							</strong>
						</label> <br />
						<label className='text-gray-700 uppercase'>
							Login:&nbsp;
							<strong style={{ fontFamily: 'var(--font-heavy)' }}>
								{user?.username}
							</strong>
						</label> <br />
						<div className='mt-8'>
							<Select
								name='permissionType'
								label='Perfil de permissões'
								options={[
									{ label: `Permissões do perfil ${user?.profileType}`, value: 'profile'},
									{ label: 'Permissões customizadas', value: 'custom' }
								]}
								showSelect={false}
							/>
						</div>
					</form>
				</FormProvider>
			</div>
				
			<table className="mt-8 table-auto w-full">
				<thead>
					<tr>
						<th className="px-4 py-2 w-3/12">Módulo</th>
						<th className="px-4 py-2 w-5/12">Lista de permissões</th>
					</tr>
				</thead>
				<tbody className=''>
					{currentModules.map((module) => {
						const modulePermissions = permissions.filter((p) => p.slug.startsWith(`${module}_`))
						return (
							<tr key={module} className='border'>
								<td className="border px-4 py-2 uppercase">{module}</td>
								<td className="border px-4 py-2">
									{modulePermissions.map((p) => (
										<div key={p._id} className="px-6 py-2">
											{permissionType === 'profile' 
												? (
													<label className="relative inline-flex items-center">
														{allowedPermissions.includes(p._id) ? <FaCheck color='green' /> : <FaBan color='red'/>}
														<span className="ml-3 text-sm font-medium text-gray-900">{p.name}</span>
													</label>
												) : (
													<label className="relative inline-flex items-center cursor-pointer">
														<input type="checkbox" checked={currentAllowedPermissions.includes(p._id)} className="sr-only peer" onClick={() => { changePermission(p._id) }} />
														<div className="w-11 h-6 bg-purple-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-purple-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#624E92]"></div>
														<span className="ml-3 text-sm font-medium text-gray-900">{p.name}</span>
													</label>
											)}
											
										</div>
									))}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			<Pagination
				itemsPerPage={modulesPerPage}
				totalItems={modules.length}
				paginate={paginate}
				currentPage={currentPage}
			/>

			<SaveBottom
					saving={false}
					touched={isDirty || currentAllowedPermissions !== allowedPermissions}
					showPublish={false}
					style={{left: 60}}
					onSave={savePermissions}
			/>	
  	</div>
  );
};

export default PermissionList;
