import React, { useEffect, useState } from "react";

import {
  Container,
  Wrapper,
  Option,
  Label,
  Input,
  Row,
  Col,
  CloseButton,
} from "./styles";

function Medicines({ medicines, values, onChange }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(
      medicines.map((item) => {
        let valueItem = {};
        const filtered = (values || []).filter((v) => v.value === item.value);
        if (filtered.length > 0) {
          valueItem = {
            selected: true,
            ...filtered[0],
          };
        }
        return {
          ...item,
          ...valueItem,
        };
      })
    );
  }, [medicines, values]);

  useEffect(() => {
    onChange(items.filter((item) => item.selected));
  }, [items, onChange]);

  const onClick = (medicine) => {
    setItems(
      items.map((item) => {
        return item.value !== medicine.value
          ? item
          : {
              ...item,
              selected: !item.selected,
            };
      })
    );
  };

  const onChangeProperty = (medicine, key, value) => {
    setItems(
      items.map((item) => {
        return item.value !== medicine.value
          ? item
          : {
              ...item,
              [key]: value,
            };
      })
    );
  };

  return (
    <Container>
      <Wrapper>
        {items.map((medicine) => (
          <Option
            key={medicine.value}
            selected={medicine.selected}
            onClick={() => {
              if (!medicine.selected) {
                onClick(medicine);
              }
            }}
          >
            {medicine.label}
            {medicine.selected && (
              <>
                <CloseButton
                  onClick={() => {
                    onClick(medicine);
                  }}
                />
                <br />
                <Row>
                  <Col>
                    <Label>Qual?</Label>
                    <Input
                      value={medicine.status}
                      placeholder="Digite aqui..."
                      onChange={(e) =>
                        onChangeProperty(medicine, "status", e.target.value)
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                  <Col>
                    <Label>Dose</Label>
                    <Input
                      value={medicine.dosage}
                      placeholder="Digite aqui..."
                      onChange={(e) =>
                        onChangeProperty(medicine, "dosage", e.target.value)
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Observações</Label>
                    <Input
                      value={medicine.observation}
                      placeholder="Digite aqui..."
                      onChange={(e) =>
                        onChangeProperty(
                          medicine,
                          "observation",
                          e.target.value
                        )
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Option>
        ))}
      </Wrapper>
    </Container>
  );
}

export default Medicines;
