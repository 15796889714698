import styled from "styled-components";

export const Container = styled.div`
  padding: 17px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: ${({ error }) =>
    error ? "var(--danger-form)" : "transparent"};
`;

export const Option = styled.div`
  font-family: var(--font-heavy);
  height: fit-content;
  text-transform: uppercase;
  text-align: ${({ condensed }) => (condensed ? "center" : "left")};
  color: ${({ selected }) => (selected ? "var(--success)" : "var(--text)")};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ selected }) =>
    selected ? "var(--success)" : "var(--text-light)"};
  border-radius: 8px;
  padding: ${({ condensed }) => (condensed ? 8 : 21)}px;
  margin-top: 12px;
  margin-right: 12px;
  min-width: 36px;
  cursor: pointer;
  transition: all 0.3s ease, padding 0.8s linear;

  :hover {
    border-color: var(${({ selected }) => (selected ? "--success" : "--text")});
  }
`;

export const Label = styled.label`
  font-family: var(--font-heavy);
  font-size: 15px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ${Option} + ${Option} {
    /* margin-right: 18px; */
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const Input = styled.input`
  font-family: var(--font-medium);
  font-size: 16px;
  letter-spacing: -0.01em;
  line-height: 16px;
  text-align: left;
  color: var(--purple);
  margin-top: 7px;
`;
