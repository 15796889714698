import React, { useEffect, useState, useCallback, useContext } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { useParams, useHistory } from "react-router-dom";
import { LoadingOrange } from "../../../components/Loading";
import SaveBottom from "../../../components/SaveBottom";
import { CheckGroup, RadioGroup } from "../../../components/3.0";
import { TextInput, TextArea } from "atoms";
import { Collapse } from "../../../components";
import AnamnesisService from "../../../services/AnamnesisService";
//import "./styles.scoped.scss";
import ClientContext from "../../../context/ClientContext";
import FileCenter from "components/3.0/FileCenter";
import { getBingeEatingsOptions } from "./bingeEatingItems";

const depressionItems = [
  "feelSad",
  "lowInterest",
  "weightLossOrGain",
  "insomniaOrHypersomnia",
  "agitation",
  "fadigue",
  "feelingsOfWorthlessness",
  "decreasedConcentration",
  "deathThoughts",
  "sufferingSymptoms",
];

const anxietyItems = [
  "numbnessOrTingling",
  "heatOrSweat",
  "inabilityToRelax",
  "feelingFaint",
  "stunnedOrDizzy",
  "palpitation",
  "outOfBalance",
  "scaredOrTerrified",
  "nervous",
  "feelingOfSuffocation",
  "tremors",
  "fearOfLosingControl",
  "difficultyBreathing",
  "abdomenDiscomfort",
  "flushedFace",
];

interface ClientParams {
  clientId?: string;
  anamnesisType?: string;
  anamnesisId?: string;
  meetingNumber?: string;
}

function Anamnesis() {
  const methods = useForm();
  const history = useHistory();
  const { isMaisMulher } = useContext(ClientContext);
  const { reset, control } = methods;
  const [depression, setDepression] = useState("");
  const [anxiety, setAnxiety] = useState("");
  const [bingeEating, setBingeEating] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const data = useWatch({ control });
  const useMedicine = useWatch({ control, name: "useMedicine" });
  const createdAt = useWatch({ control, name: "createdAt" });
  const { clientId, anamnesisId, meetingNumber } = useParams<ClientParams>();

  const getAnamnesisData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AnamnesisService.getAllAnamnesis(
        "psico",
        clientId
      );
      const item = _.find(_.get(response, ["data", "data", "items"], []), [
        "_id",
        anamnesisId,
      ]);

      if (item) {
        const { _id, createdAt, anamnesi } = item;
        reset({ _id, createdAt, ...anamnesi, client: clientId });
      } else {
        throw new Error();
      }
    } catch (e) {
      NotificationManager.error("Erro ao obter dados da anamnese");
      history.push(`/client/${clientId}/psico`);
    }
    setLoading(false);
  }, [clientId, anamnesisId, reset, setLoading, history]);

  useEffect(() => {
    getAnamnesisData();
  }, [getAnamnesisData]);

  const getDataTransform = (data, finished = false) => {
    const { ...anamnesi } = data;

    const values = {
      ...data,
      _id: anamnesisId,
      client: clientId,
      anamnesi,
    };

    if (finished) {
      values.finish = true;
    }

    return values;
  };

  const onSave = useCallback(
    async (data, published = true) => {
      setSaving(true);
      try {
        const values = getDataTransform(data, published);
        await AnamnesisService.insertAnamnesis("psico", values);
        NotificationManager.success(`Ficha salva com sucesso`);
      } catch (e) {
        NotificationManager.error("Erro ao salvar ficha");
      }
      setSaving(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSaving]
  );

  useEffect(() => {
    let sum = 0;
    depressionItems.forEach((item) => {
      if (data[item] === "Sim") sum++;
    });

    if (sum < 2) {
      setDepression("Sem depressão");
    } else if (sum < 4) {
      setDepression("Depressão leve");
    } else if (sum < 7) {
      setDepression("Depressão moderada");
    } else {
      setDepression("Depressão severa");
    }
  }, [data, setDepression]);

  useEffect(() => {
    let sum = 0;
    anxietyItems.forEach((item) => {
      const answers = [
        "Absolutamente, não",
        "Levemente, não me incomodou muito",
        "Moderadamente, foi muito desagradável mas pude suportar",
        "Gravemente, dificilmente pude suportar",
      ];
      if (answers.indexOf(data[item]) >= 0) sum += answers.indexOf(data[item]);
    });

    if (sum < 6) {
      setAnxiety("Nível mínimo de ansiedade");
    } else if (sum < 13) {
      setAnxiety("Ansiedade leve");
    } else if (sum < 23) {
      setAnxiety("Ansiedade moderada");
    } else {
      setAnxiety("Ansiedade severa");
    }
  }, [data, setAnxiety]);

  useEffect(() => {
    const bingeItems = getBingeEatingsOptions(createdAt);
    let sum = 0;
    if (data.bingeEating) {
      for (let i = 0; i < bingeItems.length; i++) {
        let answerIndex = bingeItems[i].options.indexOf(data.bingeEating[i]);
        sum += answerIndex === undefined ? 0 : answerIndex + 1;
      }
    }

    if (sum <= 17) {
      setBingeEating("Sem CAP");
    } else if (sum <= 26) {
      setBingeEating("CAP Moderada");
    } else {
      setBingeEating("CAP Grave");
    }
  }, [data, setBingeEating]);

  const renderResume = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Anamnese Psicologia"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8">
          <div className="col-span-8">
            <TextInput
              name="consultationDate"
              label="Data da Consulta"
              type="date"
            />
          </div>
          <div className="col-span-8">
            <RadioGroup
              name="hadTherapy"
              label="Já fez/faz terapia?"
              options={["Já fez", "Nunca", "Faz atualmente"]}
            />
          </div>
          <div className="col-span-8">
            <TextArea name="therapyReasons" label="Se sim, qual o motivo?" />
          </div>
          <div className="col-span-8">
            <TextArea
              name="gains"
              label="Como foi/está sendo o processo? Ganhos percebidos?"
            />
          </div>
          <div className="col-span-4">
            <RadioGroup
              name="mentalHealthDiagnosis"
              label="Já recebeu algum diagnóstico de saúde mental? "
              options={["Sim", "Não"]}
            />
          </div>
          <div className="col-span-4">
            <TextInput name="whichDiagnoses" label="Qual?" />
          </div>
          <div className="col-span-8">
            <TextArea
              name="treatments"
              label="Faz algum tratamento nessa área?"
            />
          </div>
          <div className="col-span-4">
            <RadioGroup
              name="useMedicine"
              label="Usa algum medicamento"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </div>
          <div
            className="col-span-4"
            style={{
              opacity: useMedicine ? 1 : 0.2,
              pointerEvents: useMedicine ? "all" : "none",
            }}
          >
            <TextInput name="medicines" label="Qual medicamento?" />
          </div>
        </div>
      </Collapse>
      <div className="grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-8 mb-8">
        <div className="col-span-8">
          <TextArea
            name="psicoAnamnesisObservations"
            label="Observações Anamnese Psicologia"
          />
        </div>
      </div>
    </div>
  );

  const renderHistoricoFamiliar = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Configuração e histórico familiar"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        <Row>
          <Col>
            <TextInput
              name="relativesYouLive"
              label="Com quem mora, nome e grau de parentesco"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="relationship"
              label="Como é a relação com os familiares (próxima, distante, conflituosa"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="weightGainHistoric"
              label="Histórico de ganho de peso, ansiedade, depressão e outras questões psicológicas na família"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="eatingHabits"
              label="Como são os hábitos familiares de alimentação"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="childEatingHabits"
              label="Como eram os hábitos familiares relacionados à alimentação na família de origem, quando você era criança"
            />
          </Col>
          <Col>
            <TextArea name="teenageEatingHabits" label="E na adolescência?" />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="traumas"
              label="Você vivenciou traumas e/ou perdas e/ou outros eventos significativos que considere representativos ao longo da vida (ex. algum tipo de violência, acidente, separações, morte...)"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Você passou por situações de bulling quando criança e/ou adolescente?"
              name="bulling"
              options={["Sim", "Não"]}
            />
          </Col>
          <Col>
            <TextArea
              name="bullingAffects"
              label="Como sente que isso o afeta hoje?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="significantPeople"
              label="Quem são as pessoas mais significativas na sua vida?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Como avalia sua rede de apoio social?"
              name="socialSupportNetwork"
              direction={"column"}
              options={[
                "Possuo um bom número de amigos e/ou pessoas confiáveis com quem posso contar",
                "Possuo poucos amigos e/ou pessoas confiáveis com quem posso contar",
                "Não possuo amigos e/ou pessoas confiáveis com quem posso contar",
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Algum membro da sua família já teve uma tentativa de suicídio?"
              name="familySuicideAttempt"
              options={["Sim", "Não"]}
            />
          </Col>
          <Col>
            <TextInput
              name="kinshipFamilySuicideAttempt"
              label="Em caso afirmativo, qual seu grau de parentesco com essa pessoa?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Algum membro da sua família já morreu por suicídio?"
              name="familySuicide"
              options={["Sim", "Não"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Você já pensou em suicídio?"
              name="suicideAttempt"
              options={["Sim", "Não"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              name="numberSuicideAttempts"
              label="Em caso afirmativo, quantas vezes?"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="suicideAttemptsDescriptions"
              label="Descreva as tentativas de suicídio (Data aproximada, o que exatamente fez, se foi hospitalizado ou não)"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              name="leisureActivities"
              label="Quais são suas atividades de lazer e principais interesses (sociais, intelectuais, como passa o tempo livre)? "
            />
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col>
          <TextArea
            name="familiarHistoryObservations"
            label="Observações configuração e histórico familiar"
          />
        </Col>
      </Row>
    </div>
  );

  const renderRastreioDepressao = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Rastreio depressão"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        <Row>
          <Col>
            <h3 className="psico">Considerando as duas últimas semanas</h3>
          </Col>
        </Row>
        {[
          {
            question: "Sente-se triste, vazio, desanimado ou sem esperança?",
            id: "feelSad",
          },
          {
            question:
              "Acentuada diminuição do interesse ou prazer em todas ou quase todas as atividades na maior parte do dia, quase todos os dias?  ",
            id: "lowInterest",
          },
          {
            question:
              "Teve perda ou ganho significativo de peso sem estar fazendo dieta ou redução ou aumento do apetite?",
            id: "weightLossOrGain",
          },
          {
            question: "Insônia ou hipersonia?",
            id: "insomniaOrHypersomnia",
          },
          {
            question:
              "Agitação/ inquietação ou lentidão percebida por outras pessoas em suas atividades?",
            id: "agitation",
          },
          {
            question: "Fadiga ou perda de energia quase todos os dias?",
            id: "fadigue",
          },
          {
            question: "Sentimentos de inutilidade ou culpa excessiva?",
            id: "feelingsOfWorthlessness",
          },
          {
            question:
              "Capacidade diminuída para pensar ou se concentrar, ou indecisão?",
            id: "decreasedConcentration",
          },
          {
            question:
              "Pensamentos recorrentes de morte (não somente medo de morrer), ideias sobre morrer, tentativa ou plano específico para cometer suicídio?",
            id: "deathThoughts",
          },
          {
            question:
              "Esses sintomas têm lhe causado sofrimento significativo ou prejuízo no funcionamento social, profissional ou em outras áreas importantes da sua vida?",
            id: "sufferingSymptoms",
          },
        ].map(({ question, id }) => (
          <Row key={id}>
            <Col>
              <RadioGroup label={question} name={id} options={["Sim", "Não"]} />
            </Col>
            <Col>
              <TextArea label="Observações" name={`${id}Observations`} />
            </Col>
          </Row>
        ))}
        <h3 className="psico" style={{ marginBottom: 30 }}>
          Rastreio: <strong>{depression}</strong>
        </h3>
      </Collapse>
      <Row>
        <Col>
          <TextArea
            name="depressionScreeningObservations"
            label="Observações Rastreio Depressão"
          />
        </Col>
      </Row>
    </div>
  );

  const renderRastreioAnsiedade = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Rastreio ansiedade"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        <Row>
          <Col>
            <h3 className="psico">
              Considerando os últimos sete dias, o quanto se sentiu incomodado
              pelos seguintes sintomas
            </h3>
          </Col>
        </Row>
        {[
          { question: "Dormência ou formigamento", id: "numbnessOrTingling" },
          {
            question: "Sensação de calor ou suor (não devido ao calor)",
            id: "heatOrSweat",
          },
          { question: "Incapacidade de relaxar", id: "inabilityToRelax" },
          { question: "Sensação de desmaio", id: "feelingFaint" },
          { question: "Atordoado ou tonto", id: "stunnedOrDizzy" },
          {
            question: "Palpitação ou aceleração do coração",
            id: "palpitation",
          },
          { question: "Sem equilíbrio", id: "outOfBalance" },
          { question: "Assustado ou aterrorizado", id: "scaredOrTerrified" },
          { question: "Nervoso", id: "nervous" },
          { question: "Sensação de sufocação", id: "feelingOfSuffocation" },
          { question: "Tremores (pernas, mãos)", id: "tremors" },
          { question: "Medo de perder o controle", id: "fearOfLosingControl" },
          { question: "Dificuldade de respirar", id: "difficultyBreathing" },
          {
            question: "Indigestão ou desconforto no abdômen",
            id: "abdomenDiscomfort",
          },
          { question: "Rosto afogueado", id: "flushedFace" },
        ].map(({ question, id }) => (
          <Row key={id}>
            <Col>
              <RadioGroup
                label={question}
                name={id}
                options={[
                  "Absolutamente, não",
                  "Levemente, não me incomodou muito",
                  "Moderadamente, foi muito desagradável mas pude suportar",
                  "Gravemente, dificilmente pude suportar",
                ]}
              />
            </Col>
          </Row>
        ))}
        <h3 className="psico" style={{ marginBottom: 30 }}>
          Rastreio: <strong>{anxiety}</strong>
        </h3>
      </Collapse>
      <Row>
        <Col>
          <TextArea
            name="anxietyScreeningObservations"
            label="Observações Rastreio Ansiedade"
          />
        </Col>
      </Row>
    </div>
  );

  const renderRastreioCompulsaoAlimentar = () => {
    const bingeItems = getBingeEatingsOptions(createdAt);

    return (
      <div className="bg-white rounded p-4 px-8 mb-8">
        <Collapse
          title="Rastreio de Compulsão Alimentar"
          subtitle=""
          color={"psico"}
          collapse={meetingNumber !== "1" && true}
        >
          <Row>
            <Col>
              <RadioGroup
                label="Percebe dificuldades para controlar sua ingesta de alimentos?"
                name="difficultToControlFood"
                options={["Sim", "Não"]}
              />
            </Col>
            <Col>
              <RadioGroup
                label="Tem episódios de começar a comer e ter dificuldade para parar?"
                name="difficultToStopEaten"
                options={["Sim", "Não"]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="psico">
                Vou lhe apresentar grupos de afirmações. Indique aquela que
                melhor descreve o modo como você se sente em relação aos
                problemas que tem para controlar seu comportamento alimentar.
              </h3>
            </Col>
          </Row>
          {bingeItems.map(({ options, id }, index) => (
            <Row key={id}>
              <Col>
                <RadioGroup
                  label={`#${index + 1}`}
                  name={`bingeEating.${index}`}
                  options={options}
                />
              </Col>
            </Row>
          ))}
        </Collapse>
        <h3 className="psico" style={{ marginBottom: 30 }}>
          Rastreio: <strong>{bingeEating}</strong>
        </h3>
        <Row>
          <Col>
            <TextArea
              name="compulsionScreeningObservations"
              label="Observações Rastreio Compulsão Alimentar"
            />
          </Col>
        </Row>
      </div>
    );
  };

  const renderHint = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <h6 className="psico">0 - De modo algum pronto para mudar;</h6>
      <h6 className="psico">1-3 - Pensando em mudar;</h6>
      <h6 className="psico">4-6 - Preparando-me para mudar;</h6>
      <h6 className="psico">
        7-10 - Trabalhando ativamente na mudança ou mantendo a mudança;
      </h6>
    </div>
  );

  const renderMudancas = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Réguas de prontidão para mudança"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        <Row>
          <Col>
            <h3 className="psico">Quanto à atividade física</h3>
          </Col>
        </Row>
        {[
          {
            question:
              "O quanto você se considera pronto neste momento para realizar pelo menos 30 minutos de atividade física (Definida como qualquer movimento corporal produzido em consequência da contração muscular que resulte em gasto calórico. Ex: subir escada, limpar a casa, etc) leve a moderada, de forma contínua ou acumulada na maioria dos dias da semana, incluindo mudanças no seu cotidiano?",
            id: "change1",
          },
          {
            question:
              "O quanto você se considera pronto neste momento para fazer exercício aeróbico (Tipo de atividade física com intencionalidade de movimento, com atividades planejadas, repetitivas e estruturadas, realizadas para a manutenção da saúde ou a melhora do condicionamento físico), 3 a 5 vezes por semana, com duração de 30 a 60 minutos contínuos?",
            id: "change2",
          },
        ].map(({ question, id }) => (
          <Row key={id}>
            <Col>
              <RadioGroup
                label={question}
                name={id}
                options={[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                ]}
                compact={true}
              />
              {renderHint()}
            </Col>
          </Row>
        ))}

        <Row>
          <Col>
            <h3 className="psico">Quanto à alimentação</h3>
          </Col>
        </Row>
        {[
          {
            question:
              "O quanto você se considera pronto neste momento para reduzir a ingestão de frituras, empanados, embutidos, folhados e produtos industrializados ricos em gorduras, como balas e sorvetes?",
            id: "change3",
          },
          {
            question:
              "O quanto você se considera pronto neste momento para mudar o tipo de gordura consumida (de manteigas, banha, margarina – para óleo vegetal de soja, canola, girassol, azeite de oliva)?",
            id: "change4",
          },
          {
            question:
              "O quanto você se considera pronto neste momento para aumentar a ingestão de frutas, verduras e legumes?",
            id: "change5",
          },
          {
            question:
              "O quanto você se considera pronto neste momento para reduzir a ingestão de açúcar (açúcar branco, refrigerantes, doces em geral)?",
            id: "change6",
          },
          {
            question:
              "O quanto você se considera pronto neste momento para reduzir a ingestão de sal adicionado na comida (caldo em tabletes, sopas de envelope, embutidos, alimentos em conserva)?",
            id: "change7",
          },
        ].map(({ question, id }) => (
          <Row key={id}>
            <Col>
              <RadioGroup
                label={question}
                name={id}
                options={[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                ]}
                compact={true}
              />
              {renderHint()}
            </Col>
          </Row>
        ))}
      </Collapse>
      <Row>
        <Col>
          <TextArea
            name="changesObservations"
            label="Observações Réguas de prontidão para mudança"
          />
        </Col>
      </Row>
    </div>
  );

  const renderPercepcoes = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Para preenchimento do psicólogo a partir de suas percepções"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        <Row>
          <Col>
            <h3 className="psico">Consciência</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Estado de consciência"
              name="stateOfConsciousness"
              options={["Satisfatório", "Insatisfatório"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Confusão Mental"
              name="mentalConfusion"
              options={["Sim", "Não"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Ideias delirantes"
              name="delusionalIdeas"
              options={["Sim", "Não"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Fluência Verbal (Início, meio e fim)"
              name="verbalFluency"
              options={["Satisfatório", "Regular", "Insatisfatório"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="psico">Pensamento e Linguagem</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Curso do pensamento"
              name="courseOfThought"
              options={["Normal", "Confuso", "Com fuga de ideias"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Distúrbios aparentes da fala/linguagem?"
              name="languageDisorders"
              options={["Sim", "Não"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Expressão verbal"
              name="verbalExpression"
              options={["Satisfatória", "Regular", "Insatisfatória"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="psico">Motricidade</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Gesticulações"
              name="gestures"
              options={["Em excesso", "Normal", "Não há"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Tremores"
              name="tremors"
              options={["Em excesso", "Não há"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RadioGroup
              label="Destreza"
              name="dexterity"
              options={[
                "Satisfatório",
                "Regular",
                "Insatisfatório",
                "Não avaliado",
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="psico">
              Observação de Estados Emocionais/Características
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckGroup
              label=""
              name="emotionalStates"
              options={[
                "Apatia",
                "Moralidade",
                "Irritabilidade",
                "Religiosidade",
                "Impulsividade",
                "Depressão",
                "Afetividade",
                "Agressividade",
                "Autoestima",
                "Ansiedade",
                "Euforia",
              ]}
            />
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col>
          <TextArea
            name="psychologistPerceptionsObservations"
            label="Observações Para preenchimento do psicólogo a partir de suas percepções"
          />
        </Col>
      </Row>
    </div>
  );

  const renderObservations = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Observações finais"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        <Row>
          <Col>
            <TextArea
              label="Você poderia nos contar alguma coisa sobre seus planos, suas esperanças e suas expectativas para o futuro?"
              name="futureExpatatives"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea label="Observações" name="observations" />
          </Col>
        </Row>
      </Collapse>
    </div>
  );

  const renderMaisMulher = () => (
    <div className="bg-white rounded p-4 px-8 mb-8">
      <Collapse
        title="Haux Mais Mulher"
        subtitle=""
        color={"psico"}
        collapse={meetingNumber !== "1" && true}
      >
        {[
          {
            id: "firstMenstruation",
            question:
              "1.	Conte como você se sentiu quando menstruou pela primeira vez. Você contou para alguém? Quem estava com você?",
          },
          {
            id: "emotionalSupport",
            question:
              "2.	As mulheres da sua família compartilham experiências? Você tem apoio emocional quando sente-se fragilizada?",
          },
          {
            id: "lastMenstruation",
            question:
              "3.	Como foi pra você quando percebeu que não estava mais menstruando?",
          },
          {
            id: "menopauseSymptoms",
            question:
              "4.	Além de alguns sintomas físicos, o que mais você sentiu entrando na menopausa?",
          },
          {
            id: "menopauseCuriosities",
            question:
              "5.	Que dúvidas ou curiosidades você tem sobre o período da menopausa?",
          },
          {
            id: "cognitiveFunctioning",
            question:
              "6.	Você observa/nota alguma alteração em seu funcionamento cognitivo (ex. piora da memória, concentração…)?",
          },
          {
            id: "moodChanges",
            question:
              "7.	Você nota/observa alterações de humor - percebe-se mais depressiva, ansiosa, irritada?",
          },
          {
            id: "moodChangesHandler",
            question: "8.	Caso positivo, como tem lidado com essas alterações?",
          },
          {
            id: "physicalChanges",
            question:
              "9.	Como tem sido sua relação com as alterações físicas observadas? (eram esperadas, a surpreenderam, a incomodam, as entende como normais/naturais do período…)?",
          },
          {
            id: "sexuality",
            question:
              "10.	Quanto a aspectos da sexualidade, como tem sido sua experiência? (libido, dificuldades…)",
          },
          {
            id: "selfEsteem",
            question:
              "11.	Como é sua relação com auto-estima e autoimagem? Sua relação com o seu corpo?",
          },
        ].map((question) => (
          <Row key={question.id}>
            <Col>
              <TextArea label={question.question} name={question.id} />
            </Col>
          </Row>
        ))}
      </Collapse>
      <Row>
        <Col>
          <TextArea
            name="hauxMaisMulherObservations"
            label="Observações Haux Mais Mulher"
          />
        </Col>
      </Row>
    </div>
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingOrange style={{ height: 100 }} />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        //@ts-ignore
        onSubmit={methods.handleSubmit(onSave)}
        style={{ paddingBottom: 80 }}
      >
        {renderResume()}
        {isMaisMulher && renderMaisMulher()}
        {renderHistoricoFamiliar()}
        {renderRastreioDepressao()}
        {renderRastreioAnsiedade()}
        {renderRastreioCompulsaoAlimentar()}
        {renderMudancas()}
        {renderPercepcoes()}
        <FileCenter
            clientId={clientId}
            color={"var(--psico)"}
            title="Arquivos da ficha Psico"
            preffix={`psico/${(createdAt ?? "")}`}
          />
        {renderObservations()}

        <SaveBottom
          saving={saving}
          touched={true}
          showPublish={false}
          color={"psico"}
        />
      </form>
    </FormProvider>
  );
}

export default Anamnesis;
