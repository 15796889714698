import styled from "styled-components";
import ToggleSVG from "../../assets/icons/toggle.svg";
import ProfileSVG from "../../assets/images/profile.svg";
import SearchSVG from "../../assets/icons/search.svg";
import BackSVG from "../../assets/icons/back.svg";
import AddSVG from "../../assets/icons/add.svg";
import RemoveSVG from "../../assets/icons/remove.svg";

export const Container = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: ${({ toggled }) => (toggled ? 370 : 20)}px;
  background-color: var(--purple);
  transition: all 0.3s ease, padding 0.8s linear;
  user-select: none;
`;

export const ToggleButton = styled.button`
  position: absolute;
  width: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: var(--purple-dark);
  z-index: 1000;

  :hover {
    background-color: var(--purple-light);
  }
`;

export const ToggleIcon = styled.img.attrs({
  src: ToggleSVG,
})`
  transition: all 0.8s ease, padding 0.8s linear;
  transform: ${({ toggled }) =>
    toggled ? "rotateZ(0deg)" : "rotateZ(180deg)"};
`;

export const BackIcon = styled.img.attrs({
  src: BackSVG,
})`
  height: 36px;
  width: 36px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.8s ease, padding 0.8s linear;
`;

export const ProfileContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 370px;
  max-height: 100%;
  padding-top: 28px;
  padding-left: 28px;
  padding-right: 48px;
  z-index: 1000;
  transition: all 0.3s ease;
  transform: ${({ visible }) =>
    !visible ? "translateX(100%)" : "translateX(0)"};
`;

export const ProfileImage = styled.img.attrs({
  src: ProfileSVG,
})`
  border-width: 3px;
  border-style: solid;
  border-color: #c4c4c4;
  border-radius: 63px;
  width: 126px;
  height: 126px;
`;

export const ProfilePlanTitle = styled.h1`
  font-family: var(--font-heavy);
  font-size: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: var(--success);
  margin-top: 16px;
  margin-bottom: 0px;
`;

export const ProfilePlanSubtitle = styled(ProfilePlanTitle)`
  font-size: 15px;
  color: var(--text);
  margin-top: 0px;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  font-family: var(--font-heavy);
  font-size: 13px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
`;

export const Value = styled(Label)`
  font-size: 16px;
  text-transform: none;
`;

export const ListContainer = styled(ProfileContainer)`
  position: absolute;
  padding-top: 28px;
  align-items: flex-start;
  transition: all 0.3s ease;
  transform: ${({ visible }) =>
    !visible ? "translateX(-100%)" : "translateX(0)"};
`;

export const ListTitle = styled.h1`
  font-family: var(--font-heavy);
  font-size: 32px;
  color: #fff;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 43px;
  min-height: 43px;
  background-color: #fff;
  border-radius: 8px;
  margin: 15px 0;
  padding: 0 10px;
`;

export const SearchIcon = styled.img.attrs({
  src: SearchSVG,
})`
  width: 14px;
  margin: 0 10px;
`;

export const SearchInput = styled.input`
  font-family: var(--font-heavy);
  font-size: 14px;
  height: 43px;
  width: 100%;
  color: var(--text);

  ::placeholder {
    font-family: var(--font-medium);
    color: var(--text);
  }
`;

export const ClientListContainer = styled.div`
  max-height: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ClientItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  :hover {
    background-color: ${({ light }) =>
      light ? "var(--hover-background)" : "var(--purple-dark)"};
  }
`;

export const ClientItemImage = styled.img.attrs({
  src: ProfileSVG,
})`
  border-width: 2px;
  border-style: solid;
  border-color: #c4c4c4;
  border-radius: 27px;
  width: 54px;
  height: 54px;
  margin-right: 14px;
`;

export const ClientName = styled.p`
  font-family: var(--font-regular);
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
  align-items: center;

  strong {
    font-family: var(--font-heavy);
  }
`;

export const ClientPlan = styled.p`
  font-family: var(--font-heavy);
  font-size: 13px;
  color: var(--text);
  margin-bottom: 0;
`;

export const AddIcon = styled.img.attrs({
  src: AddSVG,
})`
  margin-right: 25px;
`;

export const RemoveIcon = styled.img.attrs({
  src: RemoveSVG,
})`
  cursor: pointer;
`;

export const AddButton = styled.button.attrs({
  type: "button",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 321px;
  height: 68px;
  background: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-light);
  border-radius: 8px;
  transition: all 0.3s ease;
  font-family: var(--font-heavy);
  font-size: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: var(--purple);

  :hover {
    border-color: var(--purple);
  }
`;

export const ClientItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 17px;
  margin-right: 17px;
  border-style: solid;
  border-width: 0;
  border-bottom: 1px solid var(--text-light);
`;

export const ClientFormListContainer = styled.div`
  padding: 17px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: all 0.3s ease, padding 0.8s linear;

  background-color: ${({ error }) =>
    error ? "var(--danger-form)" : "transparent"};
`;
