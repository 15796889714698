import * as Yup from "yup";

const REQUIRED = 'Campo obrigatório'

const ProductContractSchema = Yup.object().shape({
  paymentMethod: Yup.string().required(REQUIRED),
  products: Yup.array()
              .min(1, "Adicione pelo menos 1 produto para efetuar o contrato")
              .of(Yup.object().shape({
                  product: Yup.string().required(REQUIRED),
                  quantity: Yup.number().required(REQUIRED).min(1, "A quantidade precisa ser maior que 0"),
                  value: Yup.number().required(REQUIRED).moreThan(0, "O valor precisa ser maior que R$ 0,00"),
              }))
})

export default ProductContractSchema;