import styled from "styled-components";
import HauxSVG from "../../assets/icons/haux-icon.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 19px 0;
  width: 60px;
  height: 100vh;
  background-color: var(--purple);
`;

export const HauxIcon = styled.img.attrs({
  src: HauxSVG,
})``;
