import React from "react";

export interface Challenge {
  _id: string;
  name: string;
  translation?: string;
  description: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  tip: string[];
  isDeleted: boolean;
}

export interface IFood {
  id: number;
  active: boolean;
  ashes: string;
  calcium: string;
  carbohydrate: string;
  category: string;
  cholesterol: string;
  copper: string;
  create_at: string;
  description: string;
  energyKcal: string;
  energyKj: string;
  food_fiber: string;
  image: string;
  iron: string;
  lipids: string;
  magnesium: string;
  manganese: string;
  moisture: string;
  niacin: string;
  number: string;
  phosphor: string;
  potassium: string;
  protein: string;
  pyridoxine: string;
  rae: string;
  re: string;
  retinol: string;
  riboflavin: string;
  sodium: string;
  tags: [];
  thiamine: string;
  vitamin_c: string;
  zinc: string;
}

export interface IMeal {
  id: number;
  active: boolean;
  calories: number;
  create_at: string;
  description: string;
  difficulty: string;
  foods: any[];
  image: string;
  name: string;
  tags: any[];
  time: number;
  type: string;
}

interface SystemContextInterface {
  challenges: Challenge[];
  stepChallenges: Challenge[];
  foods: IFood[];
  meals: IMeal[];
}

const SystemContext = React.createContext<SystemContextInterface>({
  challenges: [],
  stepChallenges: [],
  foods: [],
  meals: [],
});
export default SystemContext;
