import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "redux/ducks/Auth/selectors";
import Loading from "../../../components/Loading";
import {
  ListContainer,
  ListTitle,
  SearchContainer,
  SearchIcon,
  SearchInput,
  ClientListContainer,
  ClientItemContainer,
  ClientItemImage,
  ClientName,
  ClientPlan,
} from "./styles";
import ClientContext, { IClient } from "context/ClientContext";
import ClientService from "../../../services/ClientService";

interface ISearch {
  visible: boolean;
}

const Search: React.FC<ISearch> = ({ visible }) => {
  const user = useSelector(selectUser);
  const { getFanchiseName } = useContext(ClientContext)
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [searchText, setSearchText] = useState("");

  const getClients = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ClientService.getClients();
      const items = _.get(response, ["data", "data", "items"]);
      setClients(items);
    } catch (e) {}
    setLoading(false);
  }, [setClients, setLoading]);

  const getFilteredClients = useCallback(() => {
    return clients.filter((client) =>
      !client.isDeleted && client.name.toUpperCase().includes(searchText.toUpperCase())
    );
  }, [clients, searchText]);

  useEffect(() => {
    if (visible) {
      getClients();
    }
  }, [visible, getClients]);

  return (
    <ListContainer visible={visible} className="h-full w-full">
      <ListTitle>Clientes</ListTitle>
      <SearchContainer>
        <SearchIcon />
        <SearchInput
          placeholder="Pesquisar Clientes"
          onChange={(e) => {
            //@ts-ignore
            setSearchText(e.target.value);
          }}
        />
      </SearchContainer>

      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading style={{ height: 60 }} />
        </div>
      )}

      <ClientListContainer className="h-full w-full">
        {!loading && getFilteredClients().length === 0 &&
          <div className="text-white">Nenhum cliente encontrado</div>
        }
        {getFilteredClients().map((c) => (
          <ClientItemContainer
            key={c._id}
            onClick={() => {
              history.push(`/client/${c._id}`);
            }}
          >
            <ClientItemImage />
            <div>
              <ClientName>{c.name}</ClientName>
              <ClientPlan className="uppercase">{getFanchiseName(c.franchise)}</ClientPlan>
            </div>
          </ClientItemContainer>
        ))}
      </ClientListContainer>

      {(user.profileType.includes("Medical") || (user.franchisesExternal.filter((f) => f > 1000000).length > 0)) && <button
        className="w-full mb-4 transition-all bg-transparent hover:bg-purple-800 text-purple-300 font-semibold hover:text-white py-2 px-4 border border-purple-300 hover:border-transparent rounded"
        onClick={() => history.push('/client/form')}
      >
        Adicionar novo cliente
      </button>}
    </ListContainer>
  );
};

export default Search;
