import { KanbanBoard } from "./components/KanbanBoard";
import { BoardProvider } from "./components/board-provider";

function Template() {
  return (
    <>
      <BoardProvider type="Med">
        <KanbanBoard />
      </BoardProvider>
    </>
  );
}

export default Template;
