import React, { useCallback, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selectUser } from "redux/ducks/Auth/selectors";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import moment from "moment";
import { NumberInput, RadioGroup } from "components/3.0";
import { TextInput, TextArea, Select } from "atoms";
import SaveBottom from "components/SaveBottom";
import { Section } from "./styles";
import { PrimaryTitle, HR } from "components/new/Title";
import ClientService from "services/ClientService";
import ClientSchema from "./schema";
import { useUser } from "context/UserContext";

interface ClientParams {
  clientId?: string;
}

const ClientForm: React.FC<{}> = ({}) => {
  const user = useSelector(selectUser);
  const methods = useForm({
    resolver: yupResolver(ClientSchema),
  });
  const { medicalFranchises } = useUser();
  const { reset, control, handleSubmit, formState, setError } = methods;
  const { clientId } = useParams<ClientParams>();
  const birthDate = useWatch({
    control,
    name: "birthDate",
  });
  const history = useHistory();

  const getData = useCallback(async () => {
    if (clientId) {
      try {
        Swal.fire({
          title: "Obtendo dados",
          text: "Em progresso...",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const response = await ClientService.get(clientId);
        const data = _.get(response, ["data", "data"]);
        reset(data);
        Swal.close();
      } catch {
        Swal.fire("Erro ao obter dados", "", "error");
      }
    } else {
      if (user.franchisesExternal.length == 0) {
        const [franchise] = user.franchisesExternal;
        reset({ franchise });
      }
    }
  }, [user, clientId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        const values = { ...data, externalId: 0 };
        Swal.fire({
          title: "Salvando",
          text: "Em progresso...",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        var redirect = clientId;
        if (clientId) {
          await ClientService.update(clientId, values);
        } else {
          const response = await ClientService.insert(values);
          redirect = _.get(response, ["data", "data", "_id"]);
        }

        await Swal.fire({
          title: "Dados salvos com sucesso",
          icon: "success",
        });
        history.push(`/client/${redirect}`);
      } catch (errors) {
        Swal.fire("Erro ao salvar", "", "error");
      }
    },
    [clientId, user]
  );

  const onDelete = useCallback(async () => {
    try {
      const result = await Swal.fire({
        title: "Deseja realmente excluir?",
        text: "Você não poderá desfazer esta ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--success)",
        cancelButtonColor: "var(--danger)",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Desistir",
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: "Deletando",
          text: "Em progresso...",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await ClientService.update(clientId, { isDeleted: true });
        await Swal.fire({
          title: "Dados salvos com sucesso",
          icon: "success",
        });
        history.replace(`/client`);
      }
    } catch {
      Swal.fire("Erro ao deletar dados", "", "error");
    }
  }, [clientId]);

  return (
    <div className="p-8">
      <FormProvider {...methods}>
        <form style={{ paddingBottom: 50 }} onSubmit={handleSubmit(onSubmit)}>
          <Section className="grid grid-flow-row gap-3.5">
            <PrimaryTitle>Informações pessoais</PrimaryTitle>
            <HR />
            <div className="grid grid-cols-1">
              <TextInput name="name" label="Nome completo" />
            </div>
            <div className="grid grid-cols-3 gap-3">
              <TextInput
                name="birthDate"
                label="Data de nascimento"
                type="date"
              />
              <NumberInput name="cpf" label="CPF" format="###.###.###-##" />
            </div>
            <div className="">
              <RadioGroup
                label="Sexo biológico"
                name="biologicalSex"
                options={[
                  { label: "Masculino", value: "M" },
                  { label: "Feminino", value: "F" },
                ]}
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <TextInput name="email" label="E-mail" />
              <NumberInput
                name="cellphone"
                label="Celular"
                format="(##) #####-####"
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <Select
                name="franchise"
                label="Escolha a franquia"
                options={medicalFranchises.map((f) => ({
                  label: f.name,
                  value: f.internalId,
                }))}
              />
            </div>
          </Section>
          <Section className="grid grid-flow-row gap-3.5">
            <PrimaryTitle>Endereço</PrimaryTitle>
            <HR />
            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-3">
                <TextInput name="address.street" label="Rua" />
              </div>
              <NumberInput
                name="address.number"
                label="Número"
                format="#########"
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <NumberInput name="address.cep" label="CEP" format="#####-###" />
            </div>
            <div className="grid grid-cols-3 gap-3">
              <Select
                name="address.state"
                label="Estado"
                options={[
                  "Acre",
                  "Alagoas",
                  "Amapá",
                  "Amazonas",
                  "Bahia",
                  "Ceará",
                  "Distrito Federal",
                  "Espírito Santo",
                  "Goiás",
                  "Maranhão",
                  "Mato Grosso",
                  "Mato Grosso do Sul",
                  "Minas Gerais",
                  "Pará",
                  "Paraíba",
                  "Paraná",
                  "Pernambuco",
                  "Piauí",
                  "Rio de Janeiro",
                  "Rio Grande do Norte",
                  "Rio Grande do Sul",
                  "Rondônia",
                  "Roraima",
                  "Santa Catarina",
                  "São Paulo",
                  "Sergipe",
                  "Tocantins",
                ].map((e) => e.toUpperCase())}
              />
              <TextInput name="address.city" label="Cidade" />
              <TextInput name="address.district" label="Bairro" />
            </div>
            <div className="grid grid-cols-1 gap-3">
              <TextArea name="address.complement" label="Complemento" />
            </div>
          </Section>
          <Section
            className={`grid grid-flow-row gap-3.5 ${
              moment().diff(moment(birthDate, "DD/MM/YYYY"), "years") < 18
                ? "block"
                : "hidden"
            }`}
          >
            <PrimaryTitle>Informações do responsável</PrimaryTitle>
            <HR />
            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-3 ">
                <TextInput name="responsible.name" label="Nome completo" />
              </div>
              <TextInput
                name="responsible.typeResponsible"
                label="Parentesco"
              />
            </div>
            <div className="grid grid-cols-3 gap-3">
              <TextInput
                name="responsible.birthDate"
                label="Data de nascimento"
                type="date"
              />
              <TextInput name="responsible.rg" label="RG" />
              <NumberInput
                name="responsible.cpf"
                label="CPF"
                format="###.###.###-##"
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <TextInput name="responsible.email" label="E-mail" />
              <NumberInput
                name="responsible.cellphone"
                label="Celular"
                format="(##) #####-####"
              />
            </div>
          </Section>
          <Section className="grid grid-flow-row gap-3.5">
            <PrimaryTitle>Outras informações</PrimaryTitle>
            <HR />
            <div className="grid grid-cols-1">
              <Select
                name="sourceChannel"
                label="Como nos conheceu?"
                options={[
                  "INSTAGRAM",
                  "FACEBOOK",
                  "SITE",
                  "INFLUENCERS",
                  "WHATSAPP",
                  "INDICAÇÃO",
                  user.name.toUpperCase(),
                ]}
              />
            </div>
            <div className="grid grid-cols-1">
              <TextInput name="profession" label="Profissão" />
            </div>
          </Section>
          <Section className="grid grid-flow-row gap-3.5">
            <PrimaryTitle>Convênio</PrimaryTitle>
            <HR />
            <div className="grid grid-cols-1">
              <TextInput name="companion.name" label="Convênio" />
            </div>
            <div className="grid grid-cols-3 gap-3">
              <NumberInput
                name="companion.cnpj"
                label="CNPJ"
                format="##.###.###/####-##"
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <TextInput name="companion.email" label="E-mail" />
              <NumberInput
                name="companion.phone"
                label="Telefone"
                format="(##) #####-####"
              />
            </div>
          </Section>
          <Section className="grid grid-flow-row gap-3.5">
            <PrimaryTitle>Observações</PrimaryTitle>
            <HR />
            <div className="grid grid-cols-1">
              <TextArea name="observations" label="Observações do cliente" />
            </div>
          </Section>
          <SaveBottom
            saving={false}
            touched={true}
            showPublish={false}
            onDelete={clientId ? onDelete : undefined}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default ClientForm;
