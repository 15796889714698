import axios from "../middlewares/axios";

class ParquizService {
  static getAll(clientId) {
    const url = `/parquiz?client=${clientId}`;
    return axios.get(url);
  }

  static insert(data) {
    const url = `/parquiz`;
    return axios.post(url, data);
  }
}

export default ParquizService;
