import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { FooterContainer, SaveButton } from "../Anamnesis/styles";
import Loading from "../../components/Loading";
import { CheckGroup, RadioGroup}  from "../../components/3.0";
import { Section } from "./styles";
import { PrimaryTitle, HR } from "../../components/new/Title";
import SystemContext from "../../context/SystemContext";
import ClientContext from "../../context/ClientContext";
import ChallengeService from "../../services/ChallengeService";

interface ClientParams {
  clientId: string;
}

const Challenges: React.FC = () => {
  const methods = useForm();
  const { clientId } = useParams<ClientParams>();
  const { reset, handleSubmit, setValue } = methods;
  const { challenges, stepChallenges } = useContext(SystemContext);
  const { acceptedChallenges } = useContext(ClientContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let steps: string | undefined;
    stepChallenges.forEach((c) => {
      if (acceptedChallenges.includes(c._id)) {
        steps = c._id;
      }
    });
    reset({
      //@ts-ignore
      challenges: acceptedChallenges,
      steps,
    });
  }, [challenges, stepChallenges, acceptedChallenges, reset]);

  useEffect(() => { }, [stepChallenges, acceptedChallenges, setValue]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await ChallengeService.acceptMany({
        client: clientId,
        challenge: [...data.challenges, data.steps],
      });
      NotificationManager.success("Sucesso", "Desafios salvos");
    } catch (e) {
      NotificationManager.error("Erro ao salvar desafios");
    }

    setLoading(false);
  };

  const renderSave = () => (
    //@ts-ignore
    <FooterContainer visible={true} clientToggled={true}>
      VOCÊ FEZ ALTERAÇÕES NA FICHA
      <SaveButton type="submit">
        {loading && <Loading />}
        {loading ? "Salvando" : "Salvar"} Alterações
      </SaveButton>
    </FooterContainer>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{paddingBottom: 100}}>
      <Section>
        <PrimaryTitle>Desafios</PrimaryTitle>
        <HR />
        <div className='grid grid-rows-1 grid-cols-8 grid-flow-row-dense gap-12'>
          <div className='col-span-8'>
            <CheckGroup
              name="challenges"
              label="Desafios do cliente"
              options={challenges.map((challenge) => ({
                label: `${challenge.name} - ${challenge.translation}`,
                value: challenge._id,
              }))}
            />
          </div>
          <div className='col-span-8'>
            <RadioGroup
                name="steps"
                label="Desafios de passos"
                options={stepChallenges.map((challenge) => ({
                  label: `${challenge.translation} - ${challenge.description}`,
                  value: challenge._id,
                }))}
              />
          </div>
        </div>
      {renderSave()}
      </Section>
      </form>
    </FormProvider>
  );
};

export default Challenges;
