import React, { Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react'

interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  subtitle?: string;
	open: boolean;
  toggleModal?: () => void;
	onSubmit?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
	open,
	toggleModal,
  title,
  onSubmit,
}: ModalProps) => {
  return (
		<>
			<Transition appear show={open} as={Fragment}>
				<Dialog as="div" className="relative z-100000" onClose={() => {}}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
									
									<div className="mt-2 p-6">
									<Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-gray-900 mb-6"
									>
										{title}
									</Dialog.Title>
										{children}
									</div>

									<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
											<button
													className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
													type="button"
													onClick={toggleModal}
											>
												Cancelar
											</button>
											<button
												className="mt-3 w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
												type="button"
												onClick={onSubmit}
											>
												Salvar
											</button>
									</div>

									
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
  );
};
