import React, { InputHTMLAttributes } from "react";
import { useFormContext, Controller } from "react-hook-form";
import moment from "moment";
import _ from "lodash"

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
}

function getText(type, html){

  if (type === "date") {
    return moment(html, "DD/MM/YYYY").format("YYYY-MM-DD")
  }

  //@ts-ignore
  var divContainer= document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const { label, name, type, ...rest } = props;
  const { control, formState: { errors } } = useFormContext();
  const error: { message?: String} | undefined = _.get(errors, name.split('.'))

  const others = (type, field) => {
    return (type === "date" ? {
      type: "date",
      onChange: (e) => {
        //@ts-ignore
        field.onChange(moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY"));
      }
    }: {})
  }

  return (
    <div className="flex flex-col w-full">
      <Controller
        render={({ field }) => (
          <label className="font-bold uppercase">
            <span className="text-gray-700">{label}</span>
            <input className={`
                mt-1
                block
                w-full
                rounded-md
                border-gray-300
                shadow-sm
                font-normal
                ${error ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-2 focus:ring-opacity-30 focus:ring-red-500 focus:border-red-500': 'focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}
              `}
              {...rest}
              defaultValue={getText(type, field.value || "")}
              {...field}
              value={getText(type, field.value || "")}
              {...others(type, field)}
              type={type || "text"}
              />
              {error && error.message && <p className="mt-2 text-sm text-red-600 field-error">{error.message}</p>}
          </label>
        )}
        control={control}
        name={name}
      />
    </div>
  );
};

export default TextInput;