import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "@unform/core";
import {
  Container,
  Row,
  Col,
  Title,
  Status,
  Description,
  RangeContainer,
  Range,
  Circle,
  Value,
  Hint,
  Label,
} from "./styles";

function Classification({ name, title, unit, ranges }) {
  const containerRef = useRef(null);
  const statusRef = useRef(null);
  const value1Ref = useRef(null);
  const value2Ref = useRef(null);
  const descriptionRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  const onSetValue = useCallback(
    (ref, value) => {
      value1Ref.current.innerHTML = new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 1,
      }).format(value);
      value2Ref.current.innerHTML = new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 1,
      }).format(value);

      let range = ranges[0];
      ranges.forEach((r, rIndex) => {
        if (
          r.min <= value &&
          (r.max >= value || rIndex === ranges.length - 1)
        ) {
          range = r;
        }
      });

      let percentage =
        ((value - ranges[0].min) /
          (ranges[ranges.length - 1].max - ranges[0].min)) *
        100;

      if (percentage < 0) percentage = 0;
      if (percentage > 100) percentage = 100;

      statusRef.current.innerHTML = range.status;
      statusRef.current.style.color = range.color;
      descriptionRef.current.innerHTML = range.description;
      value1Ref.current.parentElement.style.backgroundColor = range.color;
      value2Ref.current.parentElement.style.backgroundColor = range.color;
      value2Ref.current.parentElement.style.left = `calc(${percentage}% - 23px)`;

      ref.attributes["data-value"].value = value;
      if (value) {
        ref.style.display = "flex";
      } else {
        ref.style.display = "none";
      }
    },
    [ranges]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: containerRef.current,
      getValue: (ref) => {
        return ref.attributes["data-value"].value;
      },
      clearValue: (ref) => {
        ref.attributes["data-value"].value = undefined;
      },
      setValue: (ref, value) => {
        onSetValue(ref, value);
      },
    });
  }, [defaultValue, fieldName, registerField, onSetValue]);

  useEffect(() => {
    if (containerRef.current) {
      onSetValue(containerRef.current, defaultValue);
    }
  }, [containerRef, defaultValue, onSetValue]);

  return (
    <Container ref={containerRef} data-value={0}>
      <Row>
        <Col>
          <Circle>
            <Value ref={value1Ref}>{""}</Value>
            <Value style={{ fontSize: 16 }}>{unit}</Value>
          </Circle>
        </Col>
        <Col>
          <Title>{title}</Title>
          <Status ref={statusRef}></Status>
          <Description ref={descriptionRef}></Description>
        </Col>
      </Row>
      <RangeContainer>
        <Circle
          style={{
            position: "absolute",
            top: -23,
            left: 0,
            width: 46,
            height: 46,
            zIndex: 1000,
          }}
        >
          <Value style={{ fontSize: 15 }} ref={value2Ref}></Value>
        </Circle>
        {ranges.map((range, index) => (
          <Range
            key={index}
            color={range.color}
            size={range.max - range.min}
            borderRadiusLeft={index === 0 ? 1.5 : 0}
            borderRadiusRight={index === ranges.length - 1 ? 1.5 : 0}
          >
            <Label style={{ color: range.color }}>{range.status}</Label>
            <Hint style={{ left: -6 }}>{range.min}</Hint>
            {index === ranges.length - 1 && (
              <Hint style={{ right: -6 }}>{range.max}</Hint>
            )}
          </Range>
        ))}
      </RangeContainer>
    </Container>
  );
}

Classification.defaultProps = {
  title: "",
  unit: "",
  name: "",
  ranges: [],
};

Classification.propTypes = {
  title: PropTypes.string,
  unit: PropTypes.string,
  name: PropTypes.string,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      color: PropTypes.string,
      status: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default Classification;
