import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

import GraviolaSoftMedium from "../../theme/fonts/Graviola-Soft-Medium.otf";
import GraviolaSoftBold from "../../theme/fonts/Graviola-Soft-Bold.otf";
import GraviolaSoftLight from "../../theme/fonts/Graviola-Soft-Light.otf";

Font.register({ family: "font-medium", src: GraviolaSoftMedium });
Font.register({ family: "font-bold", src: GraviolaSoftBold });
Font.register({ family: "font-light", src: GraviolaSoftLight });

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    padding: 30,
    paddingTop: 60,
    paddingBottom: 60,
    fontFamily: "font-medium",
  },
  logo: {
    width: 85,
    height: 19,
  },
  title: {
    textAlign: "center",
    fontSize: 16,
    color: "#624E92",
    fontFamily: "font-bold",
  },
  subtitle: {
    textAlign: "center",
    fontSize: 14,
    color: "#624E92",
  },
  item: {
    fontSize: 12,
    fontFamily: "font-bold",
  },
  content: {
    fontSize: 10,
    fontFamily: "font-medium",
  },
  center: {
    flexDirection: "row",
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: 15,
    paddingBottom: 15,
  },
});

const HauxHeader = () => (
  <View style={styles.center}>
    <Image src={"/logo.png"} style={styles.logo} />
  </View>
);

interface ExerciseItem {
  exercise: string;
  minutes: number;
}

interface Exercise {
  [key: string]: ExerciseItem[];
}

interface IImage {
  image: string;
  date: string;
}

interface Measure {
  weight: string;
  imc: string;
  bodyFatPercentage: string;
  waistAbdominal: string;
  handGrip: string;
  muscleMass: string;
  date: string;
}

interface IResumePDFDocument {
  name: string;
  period: string;
  exercises: Exercise[];
  measures: Measure[];
  images: IImage[];
  graphs: String[];
  observations: {
    title: string;
    observations: string;
  }[];
}

export const ResumePDFLink: React.FC<IResumePDFDocument> = (data) => {
  return (
    <PDFDownloadLink
      document={<ResumePDFDocument {...data} />}
      fileName={`Resumo ${data.name}.pdf`}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
    >
      {({ loading }) => (loading ? "Preparando documento..." : "Baixar Resumo")}
    </PDFDownloadLink>
  );
};

const ResumePDFDocument: React.FC<IResumePDFDocument> = ({
  name,
  period,
  observations,
  exercises,
  measures,
  graphs,
  images,
}) => {
  let exercisesMap: { date: string; exercises: string }[] = [];
  Object.keys(exercises).forEach((date) => {
    let items: ExerciseItem[] = exercises[date];
    let exerciseString = items.reduce(
      (final, item) =>
        `${final}, ${item.exercise} (${item.minutes.toFixed(0)}min)`,
      ""
    );
    exercisesMap.push({ date, exercises: exerciseString.substring(2) });
  });

  const [resume, ...graphImages] = graphs;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <HauxHeader />
        <View style={styles.section}>
          <Text style={styles.title}>Resumo</Text>
          <Text style={styles.subtitle}>{name}</Text>
          <Text style={[styles.subtitle, styles.content]}>{period}</Text>
        </View>
        <View style={[styles.center, { marginBottom: 10, gap: 8 }]}>
          {images.map((i) => (
            <View style={{ alignItems: "center" }}>
              <Image
                src={{
                  uri: i.image,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
                style={{ height: 130 }}
              />
              <Text
                style={[
                  styles.center,
                  {
                    fontSize: 7,
                  },
                ]}
              >
                {i.date}
              </Text>
            </View>
          ))}
        </View>
        <View style={[styles.row, { marginBottom: 10 }]}>
          <Image
            src={{
              uri: resume,
            }}
            style={{ width: "70%", marginHorizontal: 12 }}
          />
        </View>
        <View
          style={[
            styles.center,
            { marginBottom: 30, gap: 8, flexWrap: "wrap" },
          ]}
        >
          {graphImages.map((graph) => (
            <View style={{ alignItems: "center" }}>
              <Image
                src={{
                  uri: graph,
                }}
                style={{ height: 180 }}
              />
            </View>
          ))}
        </View>
        {graphs.length == 0 && (
          <View style={styles.section}>
            <Text style={styles.item}>Medidas:</Text>
            {measures.map(
              ({
                date,
                bodyFatPercentage,
                weight,
                imc,
                waistAbdominal,
                handGrip,
                muscleMass,
              }) => (
                <View
                  style={[
                    styles.row,
                    styles.content,
                    { paddingLeft: 10, fontSize: 6 },
                  ]}
                >
                  <Text>
                    - {date}:{"    "}
                  </Text>
                  {weight && (
                    <Text style={[{ fontFamily: "font-bold" }]}>Peso: </Text>
                  )}
                  {weight && (
                    <Text>
                      {weight}
                      {`   /  `}
                    </Text>
                  )}
                  {imc && (
                    <Text style={[{ fontFamily: "font-bold" }]}>IMC: </Text>
                  )}
                  {imc && (
                    <Text>
                      {imc}
                      {`   /  `}
                    </Text>
                  )}
                  {bodyFatPercentage && (
                    <Text style={[{ fontFamily: "font-bold" }]}>
                      Percentual de Gordura:{" "}
                    </Text>
                  )}
                  {bodyFatPercentage && (
                    <Text>
                      {bodyFatPercentage}
                      {`   /  `}
                    </Text>
                  )}
                  {waistAbdominal && (
                    <Text style={[{ fontFamily: "font-bold" }]}>
                      Circunferência abdominal:{" "}
                    </Text>
                  )}
                  {waistAbdominal && (
                    <Text>
                      {waistAbdominal}
                      {`   /  `}
                    </Text>
                  )}
                  {handGrip && (
                    <Text style={[{ fontFamily: "font-bold" }]}>
                      Força muscular:{" "}
                    </Text>
                  )}
                  {handGrip && (
                    <Text>
                      {handGrip}
                      {`   /  `}
                    </Text>
                  )}
                  {muscleMass && (
                    <Text style={[{ fontFamily: "font-bold" }]}>
                      Massa muscular:{" "}
                    </Text>
                  )}
                  {muscleMass && <Text>{muscleMass}</Text>}
                </View>
              )
            )}
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.item}>Exercícios:</Text>
          {exercisesMap.length == 0 && (
            <Text style={[styles.content, { paddingLeft: 10, fontSize: 8 }]}>
              - Nenhum exercício registrado
            </Text>
          )}
          {exercisesMap.map(({ date, exercises }) => (
            <Text style={[styles.content, { paddingLeft: 10, fontSize: 8 }]}>
              - {date}: {exercises}
            </Text>
          ))}
        </View>
        {observations.length === 0 && (
          <Text style={styles.content}>
            Não houveram registros no mês selecionado
          </Text>
        )}
        {observations.length > 0 &&
          observations.map((item) => (
            <View style={styles.section}>
              <Text style={styles.item}>{item.title}</Text>
              <Text style={styles.content}>{item.observations}</Text>
            </View>
          ))}
      </Page>
    </Document>
  );
};

export default ResumePDFDocument;
