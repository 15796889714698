import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Form } from "@unform/web";
import _ from "lodash";
import { H1, Options, Loading, UploadFiles } from "../../components";
import { Row, Col } from "reactstrap";
import { Container, FooterContainer, SaveButton } from "./styles";
import { selectToggled } from "../../redux/ducks/Client/selectors";
import ParquizService from "../../services/Parquiz";
import { useParams } from "react-router-dom";

const QUESTIONS = [
  {
    key: "heartProblem",
    question:
      "Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionado por profissionais de saúde?",
  },
  {
    key: "chestPains",
    question: "Você sente dores no peito quando pratica atividades físicas?",
  },
  {
    key: "chestPainsInTheLastMonth",
    question:
      "No último mês, você sentiu dores no peito quando praticou atividade física?",
  },
  {
    key: "haveImbalance",
    question:
      "Você apresenta desequilíbrio devido à tontura e/ou perda de consciência?",
  },
  {
    key: "boneOrJointProblem",
    question:
      "Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física",
  },
  {
    key: "takeSomeMedicine",
    question:
      "Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?",
  },
  {
    key: "otherReason",
    question:
      "Sabe de alguma outra razão pela qual você não deve praticar atividade física?",
  },
];

const ParQuiz = () => {
  const [loading, setLoading] = useState(false);
  const clientToggled = useSelector(selectToggled);
  const { clientId } = useParams();
  const formRef = useRef(null);
  const [touched, setTouched] = useState(false);
  const [currentParquiz, setCurrentParquiz] = useState({});

  const getData = useCallback(async () => {
    const response = await ParquizService.getAll(clientId);
    const first = _.first(_.get(response, ["data", "data", "items"]));
    setCurrentParquiz(first);
  }, [clientId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      formRef.current.setErrors({});
      const values = {
        client: clientId,
        responsibleProfessional: "5f523484e912ff4a31221602",
        ...data,
      };

      await ParquizService.insert(values);
      setTouched(false);
    } catch (err) {}
    setLoading(false);
  };

  const renderForm = () => (
    <>
      <Row>
        <Col>
          <H1>
            Checando o ParQ - Questionário de Aptidão para Atividade Física
          </H1>
        </Col>
      </Row>

      {QUESTIONS.map((question) => (
        <div key={question.key}>
          <Row>
            <Col>
              <Options
                name={question.key}
                label={question.question}
                multiple={false}
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                onChange={() => {
                  setTouched(true);
                  setCurrentParquiz(formRef.current.getData());
                }}
              />
            </Col>
          </Row>
          {_.get(currentParquiz, question.key) && (
            <Row>
              <Col>
                <UploadFiles
                  name={`files.${question.key}`}
                  label="Faça o upload do atestado"
                  files={[
                    {
                      name: question.key,
                      label: "Atestado",
                      valid: () => true,
                    },
                  ]}
                />
              </Col>
            </Row>
          )}
        </div>
      ))}
    </>
  );

  const renderSave = () => (
    <FooterContainer visible={touched} clientToggled={clientToggled}>
      VOCÊ FEZ ALTERAÇÕES NA FICHA
      <SaveButton type="submit">
        {loading && <Loading />}
        {loading ? "Salvando" : "Salvar"} Alterações
      </SaveButton>
    </FooterContainer>
  );

  return (
    <Form
      key={(currentParquiz || {})._id}
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        heartProblem: false,
        chestPains: false,
        chestPainsInTheLastMonth: false,
        haveImbalance: false,
        boneOrJointProblem: false,
        takeSomeMedicine: false,
        otherReason: false,
        ...currentParquiz,
      }}
      r
      onChange={() => setTouched(true)}
    >
      <Container>{renderForm()}</Container>
      {renderSave()}
    </Form>
  );
};

export default ParQuiz;
