import axios from "axios";

const getAuthHeader = () =>
  !!localStorage.getItem("TOKEN_KEY_V3")
    ? `Bearer ${localStorage.getItem("TOKEN_KEY_V3")}`
    : null;

axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
// axios.defaults.baseURL = "http://localhost:3001/api/v1";
// "http://apiv2teste-env.eba-r8aekwcg.us-east-1.elasticbeanstalk.com/api/v1";
// "http://apiv2-env-prod.eba-ipts2vhz.us-east-1.elasticbeanstalk.com/api/v1";
// "https://haux-new-api.herokuapp.com/api/v1";

axios.defaults.headers.common.Authorization = getAuthHeader();

const reloadAxiosInstance = () => {
  axios.defaults.headers.common.Authorization = getAuthHeader();
};

export { reloadAxiosInstance };
export default axios;
