import React, { useContext, useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/ducks/Auth/selectors";
import _ from "lodash";
import moment from "moment";
import {
  ProfileContainer,
  ClientName,
  BackIcon,
  ProfileImage,
  ProfilePlanTitle,
  ProfilePlanSubtitle,
  ValueContainer,
  Value,
  Label,
} from "./styles";
import ClientContext from "../../../context/ClientContext";
import AnamnesisService from "../../../services/AnamnesisService";
import ClientService from "services/ClientService";
import ProductService from "services/ProductService";

interface IProfile {
  visible: boolean;
}

interface IBodyComposition {
  weight?: number;
  height?: number;
}

interface IPacote {
  id: string;
  Nome: string;
  Descricao: string;
  ListaTratamento: [{
    Descricao: string;
    Especialidade: {Id: number; Descricao: string;}
    Id: number;
    IdProfissionalPadrao: number;
    ListaSessao: [{
      DataAgendamento?: string;
      Id: number;
      IdAgendamento: number;
      IdProfissional: number;
      NomeProfissional: string;
      Status: string;
      TipoAgendamento: string;
    }]
  NomeProfissionalPadrao: string;
  }]
}
interface IInfo {
  pacotes?: IPacote[];
}

interface IClientPlan {
  plans?: { name: string; description: string; }[];
  meetings?: { total: number; totalSchedule: number; specialty: { id: number, description: string; }}[];
}

const Profile: React.FC<IProfile> = ({ visible }) => {
  const user = useSelector(selectUser);
  const { client, goal, setGoal, setMaisMulher } = useContext(ClientContext);
  const [bodyComposition, setBodyComposition] = useState<IBodyComposition>({});
  const [plan, setPlan] = useState<IClientPlan>({});
  const [age, setAge] = useState(0);
  const history = useHistory();

  const getLastBodyComposition = useCallback(async () => {
    try {
      const response = await AnamnesisService.getFinishedAnamnesis(
        "tech",
        client._id
      );
      var items = _.get(response, ["data", "data", "items"], []).map((a) => ({
        ...a,
        valueOf: moment(a.consultationDate, "DD/MM/YYYY").valueOf(),
      }));
      items = items.sort(function(a, b) {
        //@ts-ignore
        return a.valueOf - b.valueOf;
      });
      items = items.filter((a) => a.finish)
      const last = _.last(items);
      //@ts-ignore
      setBodyComposition(last.bodyComposition || {});
    } catch (e) {
      setBodyComposition({});
    }
  }, [client, setBodyComposition]);


  const getPlan = useCallback(async () => {
    try {
      if (client.externalId === 0) {
        const packs = await ProductService.getAllPacks(client._id);
        const newClientPlan: IClientPlan = {
          plans: [{ name: 'Medical', description: 'Medical' }],
          meetings: []
        }

        packs.forEach((pack) => {
          pack.products?.forEach((p, index) => {
            newClientPlan.meetings?.push({
              total: p.quantity,
              totalSchedule: (p.finished?.length ?? 0) + (p.uses?.length ?? 0),
              specialty: {
                id: index,
                description: p.product.title ?? ""
              }
            })
          })
          
        })

        setPlan(newClientPlan)
      } else {
        const response = await ClientService.plan(client._id);
        const data = _.get(response, ["data", "data"], {});
        //@ts-ignore
        setPlan(data || {});
      }
    } catch (e) {
      setPlan({});
    }
  }, [client, setPlan]);

  useEffect(() => {
    getLastBodyComposition();
    getPlan();
  }, [getLastBodyComposition, getPlan]);

  useEffect(() => {
    let hasMaisMulher = false;
    plan.plans?.forEach((p) => {
      if (
        p.name.toUpperCase().includes("MAIS MULHER") ||
        p.name.toUpperCase().includes("+ MULHER")
      ) {
        hasMaisMulher = true;
      }
    });
    setMaisMulher(hasMaisMulher);
  }, [plan, setMaisMulher]);

  useEffect(() => {
    if (!client) return;
    setAge(moment().diff(moment(client.birthDate, "DD/MM/YYYY"), "years"));
  }, [client, setAge]);

  if (!client) {
    return <></>;
  }

  return (
    <ProfileContainer visible={visible}>
      <ClientName>
        <BackIcon
          onClick={() => {
            history.push("/client");
          }}
        />
        {_.get(client, ["name"])}
      </ClientName>
      <ProfileImage />
      
      {plan.plans?.map((plan) => (
        <ProfilePlanTitle key={plan.name}>{plan.name}</ProfilePlanTitle>
      ))}
      {plan.meetings?.map((meeting) => (
        <ProfilePlanSubtitle key={meeting.specialty.id}>
          {meeting.specialty.description}: &nbsp;
          <small>{Math.max(meeting.total - meeting.totalSchedule, 0)} restantes</small>
        </ProfilePlanSubtitle>
      ))}

      <div style={{marginBottom: 10}}></div>

      <ValueContainer style={{ marginTop: 30 }}>
        <Label>Senha do App</Label>
        <Value>{client._id.substring(client._id.length - 5)}</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Peso atual</Label>
        <Value>{bodyComposition.weight} kg</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Meta de peso</Label>
        <Value>{_.get(goal, ["weight"])} kg</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Gênero Biológico</Label>
        <Value>
          {client.biologicalSex.toUpperCase() === "M" ? "HOMEM" : "MULHER"}
        </Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Altura</Label>
        <Value>{bodyComposition.height}m</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Idade</Label>
        <Value>{age}</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Data de nascimento</Label>
        <Value>{client.birthDate}</Value>
      </ValueContainer>
      {!_.isEmpty(goal) && (
        <ValueContainer>
          <Label>Meta Calórica</Label>
          <Value>
            <select
              key={goal._id}
              defaultValue={[
                _.get(goal, "minCalories"),
                _.get(goal, "maxCalories"),
              ]}
              style={{ color: "white", backgroundColor: 'transparent', border: 'none' }}
              onChange={(e: { target: HTMLSelectElement }) => {
                //@ts-ignore
                const [minCalories, maxCalories] = e.target.value.split(",");
                const newGoal = {
                  ...goal,
                  minCalories: Number(minCalories),
                  maxCalories: Number(maxCalories),
                };
                setGoal(newGoal);
              }}
            >
              {[
                [1000, 1200],
                [1300, 1500],
                [1600, 1800],
                [1900, 2100],
                [2200, 2400],
                [2500, 2700],
                [2800, 3000],
                [3100, 3300],
                [3400, 3600],
                [3700, 3900],
              ].map(([min, max], index) => (
                <option
                  key={`${index}`}
                  value={`${[min, max]}`}
                >{`${min}-${max} KCal`}</option>
              ))}
            </select>
          </Value>
        </ValueContainer>
      )}
      <ValueContainer style={{ marginTop: 30 }}>
        <Label>CPF</Label>
        <Value>{client.cpf}</Value>
      </ValueContainer>
      <ValueContainer>
        <Label>Endereço</Label>
      </ValueContainer>
      <Value style={{lineHeight: '18px', width: '100%', overflowY: 'scroll'}}>
        <small>
        {`RUA: ${client.address.street}`}
        <br />
        {`BAIRRO: ${client.address.district}`}
        <br />
        {`CIDADE: ${client.address.city}`}
        <br />
        {`ESTADO: ${client.address.state}`}
        <br />
        {`CEP: ${client.address.cep?.replace(/\D/g, '')
          .replace(/(\d{5})(\d)/, '$1-$2')
          .replace(/(-\d{3})\d+?$/, '$1')}
          \n
        `}
        <br />
        COMPLEMENTO:
        {client.address.complement ? (
          <>
            ,&nbsp;{client.address.complement}
          </>
          ) : ''}
        ;
        </small>
      </Value>
      {((user.profileType.includes("Medical") || (user.franchisesExternal.filter((f) => f > 1000000).length > 0)) && client.externalId === 0) && 
      <div className="mt-4">
        <button
          className="w-full mb-4 transition-all bg-transparent hover:bg-purple-800 text-purple-300 font-semibold hover:text-white py-2 px-4 border border-purple-300 hover:border-transparent rounded"
          onClick={() => history.push(`/client/form/${client._id}`)}
        >
          Editar cliente
        </button>
        <button
          className="w-full mb-4 transition-all bg-transparent hover:bg-purple-800 text-purple-300 font-semibold hover:text-white py-2 px-4 border border-purple-300 hover:border-transparent rounded"
          onClick={() => history.push(`/client/products/${client._id}`)}
        >
          Produtos contratados
        </button>
      </div>
      }
    </ProfileContainer>
  );
};

export default Profile;
