import styled from "styled-components";
import HauxSVG from "../../assets/icons/haux.svg";
import ClinicJPG from "../../assets/images/clinic.jpg";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
`;

export const HauxIcon = styled.img.attrs({
  src: HauxSVG,
})`
  width: 90px;
`;

export const FormContainer = styled.div`
  width: 43%;
  height: 100%;
  padding: 55px 74px;

  h1 {
    font-family: var(--font-heavy);
    font-size: 30px;
    letter-spacing: 0em;
    line-height: 34px;
    text-align: left;
    color: var(--purple-light);
    margin-top: 100px;
    margin-bottom: 0px;
  }

  h2 {
    font-family: var(--font-regular);
    font-size: 18px;
    letter-spacing: -0.01em;
    line-height: 24px;
    text-align: left;
    color: var(--text);
    margin-bottom: 36px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 57%;
  height: 100%;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  background-color: var(--purple-light);
  width: 100%;
  height: 100%;
  opacity: 0.6;
`;

export const Image = styled.img.attrs({
  src: ClinicJPG,
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Label = styled.label`
  width: 100%;
  font-family: var(--font-heavy);
  font-size: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  text-transform: uppercase;
  color: var(--purple-light);
  margin-bottom: 0;
`;

export const Input = styled.input`
  width: 100%;
  font-family: var(--font-heavy);
  color: var(--purple);
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-color: var(--text);
  line-height: 36px;
  transition: all 0.3s ease;
  margin-bottom: 40px;

  :hover,
  :focus {
    border-color: var(--pink-light);
  }

  ::placeholder {
    font-family: var(--font-regular);
    color: var(--text);
  }
`;

export const Button = styled.button`
  position: relative;
  height: 60px;
  width: 100%;
  font-family: var(--font-heavy);
  font-size: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  background-color: var(--purple-light);
  border-radius: 8px;
  transition: all 0.3s ease;

  :hover {
    background-color: var(--purple);
  }

  :disabled {
    background-color: var(--text);
  }
`;
