import axios from "../middlewares/axios";

class ReportService {
  static getAll(clientId, type) {
    const url = `/reports?client=${clientId}&type=${type}&version=1`;
    return axios.get(url);
  }

  static insert(data) {
    const url = `/reports`;
    return axios.post(url, data);
  }

  static delete(reportId) {
    const url = `/reports/${reportId}`;
    return axios.delete(url);
  }

  static insertItems(reportId, data) {
    const url = `/reports/${reportId}`;
    return axios.post(url, data);
  }

  static getItems(reportId) {
    const url = `/reports/${reportId}`;
    return axios.get(url);
  }

  static generateReportUrl(reportId, type) {
    const url = `${axios.defaults.baseURL}/reports/${reportId}/download`;
    return url
  }
}

export default ReportService