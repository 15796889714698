import ANAMNESIS from "./types";
import Immutable from "seamless-immutable";

export const INITIAL_STATE = Immutable({
  loading: undefined,
  parquiz: {},
  anamnesis: [{}],
  anamnesisIndex: 0,
});

export const saveRequest = (state) => state.merge({ loading: true });

export const saveSuccess = (state) => state.merge({ loading: false });

export const saveFail = (state, { error }) =>
  state.merge({ loading: undefined, error });

export const setAnamnesisIndex = (state, { anamnesisIndex }) =>
  state.merge({ anamnesisIndex });

export const getAllAnamnesisSuccess = (state, { list }) =>
  state.merge({ anamnesis: list });

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANAMNESIS.SAVE:
      return saveRequest(state, action);
    case ANAMNESIS.SAVE_SUCCESS:
      return saveSuccess(state, action);
    case ANAMNESIS.SAVE_FAIL:
      return saveFail(state, action);
    case ANAMNESIS.SET_ANAMNESIS_INDEX:
      return setAnamnesisIndex(state, action);
    case ANAMNESIS.GET_ALL_SUCCESS:
      return getAllAnamnesisSuccess(state, action);
    default:
      return state;
  }
};

export default AuthReducer;
