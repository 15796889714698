import ANAMNESIS from "./types";

export const getAllAnamnesis = (anamnesis, clientId) => ({
  type: ANAMNESIS.GET_ALL,
  anamnesis,
  clientId,
});

export const getAllAnamnesisSuccess = (list) => ({
  type: ANAMNESIS.GET_ALL_SUCCESS,
  list,
});

export const saveAnamnesis = (anamnesis, data) => ({
  type: ANAMNESIS.SAVE,
  anamnesis,
  data,
});

export const saveAnamnesisSuccess = () => ({
  type: ANAMNESIS.SAVE_SUCCESS,
});

export const saveAnamnesisFail = () => ({
  type: ANAMNESIS.SAVE_FAIL,
});

export const setAnamnesisIndex = (anamnesisIndex) => ({
  type: ANAMNESIS.SET_ANAMNESIS_INDEX,
  anamnesisIndex,
});
