import * as Yup from 'yup';

const requiredMessage = 'Campo obrigatório';

interface IBodyComposition {
  bloodPressure?: string;
  weight: number;
  height: number;
  visceralFatLevel?: number;
  basalMetabolicRate: number;
  imc: string;
  bodyWater?: number;
  freeMass: number;
  fatMass: number;
  bodyFatPercentage: number;
  muscleMass: number;
  muscleMassIndex?: number;
  bicepLeft?: number;
  bicepRight?: number;
  midThighLeft?: number;
  midThighRight?: number;
  calfLeft?: number;
  calfRight?: number;
  torax?: number;
  waistAbdominal?: number;
  waistAbdominalStyku?: number;
  hip?: number;
  handGrip?: number;
  references: {
    bodyWater?: {
      min?: number;
      max?: number;
    };
    freeMass: {
      min: number;
      max: number;
    };
    bodyFatPercentage: {
      min: number;
      max: number;
    };
    muscleMass?: string;
  };
}

interface IStykuMeasure {
  body_right?: string;
  body_left?: string;
  body_front?: string;
  silhouette_front?: string;
  body_back?: string;
  silhouette_left?: string;
}

export interface ITech {
  consultationDate: string;
  observations?: string;
  finish?: boolean;
  bodyComposition?: IBodyComposition;
  stykuMeasure?: IStykuMeasure;
}

const schema: Yup.ObjectSchema<ITech> =  Yup.object().shape({
  consultationDate: Yup.string().required(requiredMessage).min(10),
  observations: Yup.string(),
  hauxApproach: Yup.string(),
  finish: Yup.boolean(),
  goals: Yup.object().shape({
    weight: Yup.number(),
    suggestionWeight: Yup.number(),
    imc: Yup.number(),
    caloriesNih: Yup.number(),
    minCalories: Yup.number(),
    maxCalories: Yup.number(),
    unachievable: Yup.boolean(),
    date: Yup.date(),
    goalDate: Yup.string(),
    caloriesRange: Yup.string(),
  }).optional(),
  bodyComposition: Yup.object().shape({
    bloodPressure: Yup.string().optional(),
    weight: Yup.number().required(requiredMessage).moreThan(0),
    height: Yup.number().required(requiredMessage).moreThan(0),
    visceralFatLevel: Yup.number().optional(),
    basalMetabolicRate: Yup.number().required(requiredMessage).moreThan(0),
    imc: Yup.string().required(requiredMessage),
    bodyWater: Yup.number().moreThan(0),
    freeMass: Yup.number().required(requiredMessage).moreThan(0),
    fatMass: Yup.number().required(requiredMessage).moreThan(0),
    bodyFatPercentage: Yup.number().required(requiredMessage).moreThan(0),
    muscleMass: Yup.number().required(requiredMessage).moreThan(0),
    muscleMassIndex: Yup.number().optional(),
    bicepLeft: Yup.number().optional(),
    bicepRight: Yup.number().optional(),
    midThighLeft: Yup.number().optional(),
    midThighRight: Yup.number().optional(),
    calfLeft: Yup.number().optional(),
    calfRight: Yup.number().optional(),
    torax: Yup.number().optional(),
    waistAbdominal: Yup.number().optional(),
    waistAbdominalStyku: Yup.number().optional(),
    hip: Yup.number().optional(),
    handGrip: Yup.number().optional(),
    references: Yup.object().shape({
      bodyWater: Yup.object().shape({
        min: Yup.number(),
        max: Yup.number(),
      }),
      freeMass: Yup.object().shape({
        min: Yup.number(),
        max: Yup.number(),
      }),
      bodyFatPercentage: Yup.object().shape({
        min: Yup.number().required(requiredMessage),
        max: Yup.number().required(requiredMessage),
      }),
      muscleMass: Yup.string().optional(),
    }),
  }),
  stykuMeasure: Yup.object().shape({
    body_right: Yup.string(),
    body_left: Yup.string(),
    body_front: Yup.string(),
    silhouette_front: Yup.string(),
    body_back: Yup.string(),
    silhouette_left: Yup.string(),
  }).optional(),
});

export default schema;

