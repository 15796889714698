import axios from "../middlewares/axios";

class NutritionalPlanService {
  static getClientPlans(clientId) {
    const url = `/plans/nutritional?client=${clientId}`;
    return axios.get(url);
  }
  static getAllPlans() {
    const url = `/plans/nutritional`;
    return axios.get(url);
  }
  static getPlan(planId: string) {
    const url = `/plans/nutritional/${planId}`;
    return axios.get(url);
  }
  static insertPlan(
    data,
    fromId: string | undefined = undefined
  ) {
    const url = `/plans/nutritional`;
    return axios.post(url, { 
      ...data,
      fromId,
    });
  }
  static getMeal(planId, clientId) {
    var url = `/plans/nutritional/meal?client=${clientId}`;
    if (planId && planId.length > 0) {
      url = url + `&plan=${planId}`
    }
    return axios.get(url);
  }
  static patchMeal(data) {
    const url = '/plans/nutritional/meal';
    return axios.patch(url, data);
  }
  static deleteMeal(id) {
    const url = `/plans/nutritional/meal/${id}`;
    return axios.delete(url);
  }
  static patchFood(mealId,data) {
    const url = `/plans/nutritional/meal/${mealId}/food`;
    return axios.patch(url, data);
  }
  static deleteFood(mealId,foodId) {
    const url = `/plans/nutritional/meal/${mealId}/food/${foodId}`;
    return axios.delete(url);
  }
  static download(planId, username) {
    const url = `${axios.defaults.baseURL}/plans/nutritional/${planId}/download?username=${username}`;
    return url
  }
}

export default NutritionalPlanService;
