import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Container, Label, Input } from "./styles";

function TextInput({
  label,
  compact,
  renderInContainer,
  containerStyle,
  inputStyle,
  ...props
}) {
  const [field] = useField(props);
  const inputRef = useRef(null);

  return (
    <Container
      error={false}
      onMouseEnter={() => {
        inputRef.current.focus();
      }}
      style={{ ...containerStyle }}
    >
      {label && <Label compact={compact}>{label}</Label>}
      <Input
        compact={compact}
        ref={inputRef}
        style={{ ...inputStyle }}
        {...field}
        {...props}
      />
      {renderInContainer && renderInContainer()}
    </Container>
  );
}

TextInput.defaultProps = {
  compact: false,
};

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  compact: PropTypes.bool,
};

export default TextInput;
