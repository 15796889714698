import styled from "styled-components";

export const Input = styled.input`
  font-family: var(--font-heavy);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--purple);
  outline: none;

  ::placeholder {
    color: var(--text-light);
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-family: var(--font-heavy);
  font-size: 12px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  margin-top: 13px;
`;

export const CloseButton = styled.img`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 12px;
`;
