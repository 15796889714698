//@ts-nocheck
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";
import FileService from "../../services/FileService";
import _ from "lodash";
import UploadSVG from "../../assets/icons/upload.svg";

function UploadFiles({ name, label, files }) {
  const { control } = useFormContext();
  const filesRef = useRef();
  const valuesRef = useRef({});

  const getFileSettingsWithName = (fileToUpload) => {
    let result = null;
    files.reverse().forEach((file) => {
      if (file.valid(fileToUpload.name)) {
        result = file;
      }
    });

    return result;
  };

  const uploadFiles = async (field) => {
    const filesToUpload = [...filesRef.current.files];
    filesRef.current.value = "";
    await filesToUpload.forEach(async (file) => {
      const fileSetting = getFileSettingsWithName(file);
      if (fileSetting) {
        const formData = new FormData();
        formData.append("image", file);
        try {
          const response = await FileService.uploadImage(formData);

          const link = response.data.data.file.url;

          valuesRef.current[fileSetting.name] = link;
        } catch (e) {}
      }

      field.onChange(valuesRef.current);
    });
  };

  return (
    <Controller
      render={({ field }) => (
        <div
          className="pr-5 pl-5 font-bold cursor-pointer"
        >
          <input
            ref={filesRef}
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={() => {
              uploadFiles(field);
            }}
          />
          <div
            className="flex flex-col"
            onClick={() => {
              filesRef.current.click();
            }}
          >
            <img src={UploadSVG} className="mt-12 mb-12 self-center" alt="upload" />
            Clique nessa área para fazer upload
          </div>
          <div className="flex flex-wrap space-x-6">
            {files.map((file: any, index: number) => (
              <div
                key={file.name}
                className={`${!_.isEmpty(
                  _.get(field, ["value", file.name])
                ) ? "text-teal-600" : ""}`}
                onClick={() => {
                  if (!_.isEmpty(_.get(field, ["value", file.name]))) {
                    window.open(_.get(field, ["value", file.name]), "_blank");
                  }
                }}
              >
                {index + 1} - {file.label}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-6">
            {files.map((file: any, index: number) => {
              if (_.isEmpty(_.get(field, ["value", file.name]))) {
                return null
              }
              
              return (
                <img 
                  key={file.name}
                  alt={file.name}
                  src={_.get(field, ["value", file.name])}
                  style={{ cursor: "pointer"}}
                  onClick={() => {
                    if (!_.isEmpty(_.get(field, ["value", file.name]))) {
                      window.open(_.get(field, ["value", file.name]), "_blank");
                    }
                  }}
                />
              )
            })}
          </div>
        </div>
      )}
      control={control}
      name={name}
    />
  );
}

UploadFiles.defaultProps = {
  name: "",
  files: [],
};

UploadFiles.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      valid: PropTypes.func,
    })
  ),
};

export default UploadFiles;
