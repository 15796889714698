import styled from "styled-components";
import MuscleSVG from "../../assets/images/muscles.svg";
import MuscleAchesSVG from "../../assets/images/muscleAches.svg";
import ResumoSVG from "../../assets/icons/resumo.svg";
import ResumoHighlightSVG from "../../assets/icons/resumo-highlight.svg";
import TechSVG from "../../assets/icons/tech.svg";
import TechHighlightSVG from "../../assets/icons/tech-highlight.svg";
import MedSVG from "../../assets/icons/med.svg";
import MedHighlightSVG from "../../assets/icons/med-highlight.svg";
import NutriSVG from "../../assets/icons/nutri.svg";
import NutriHighlightSVG from "../../assets/icons/nutri-highlight.svg";
import TrainingSVG from "../../assets/icons/training.svg";
import TrainingHighlightSVG from "../../assets/icons/training-highlight.svg";
import PsicoSVG from "../../assets/icons/mais-vida.svg";
import PsicoHighlightSVG from "../../assets/icons/mais-vida-highlight.svg";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import RemoveSVG from "../../assets/icons/remove.svg";

export const Container = styled.div`
  padding-bottom: 80px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 49px;
`;

export const CurrentEncontroContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Arrow = styled.img.attrs({
  src: ArrowDownIcon,
})`
  width: 18px;
  margin-left: 13px;
  transition: all 0.5s ease, padding 0.5s linear;
  transform: ${({ toggled }) => (toggled ? "rotateZ(180deg)" : "rotateZ(0)")};
`;

export const EncontroText = styled.span`
  font-family: var(--font-heavy);
  font-size: 11px;
  text-transform: uppercase;
  color: var(--text);
`;

export const NavigationContainer = styled.div`
  padding: 17px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
`;

export const ResumoIcon = styled.div`
  background: ${({ selected }) =>
    selected ? `url("${ResumoHighlightSVG}")` : `url("${ResumoSVG}")`};
  width: 16px;
  height: 16px;
  margin-right: 8px;
  transition: all 0.3s ease, padding 0.8s linear;
`;

export const TechIcon = styled(ResumoIcon)`
  background: ${({ selected }) =>
    selected ? `url("${TechHighlightSVG}")` : `url("${TechSVG}")`};
`;

export const MedIcon = styled(ResumoIcon)`
  background: ${({ selected }) =>
    selected ? `url("${MedHighlightSVG}")` : `url("${MedSVG}")`};
`;

export const NutriIcon = styled(ResumoIcon)`
  background: ${({ selected }) =>
    selected ? `url("${NutriHighlightSVG}")` : `url("${NutriSVG}")`};
`;

export const TrainingIcon = styled(ResumoIcon)`
  background: ${({ selected }) =>
    selected ? `url("${TrainingHighlightSVG}")` : `url("${TrainingSVG}")`};
`;

export const PsicoIcon = styled(ResumoIcon)`
  background: ${({ selected }) =>
    selected ? `url("${PsicoHighlightSVG}")` : `url("${PsicoSVG}")`};
`;

export const MenuItem = styled.a`
  display: flex;
  align-items: center;
  font-family: var(--font-heavy);
  font-size: 13px;
  text-transform: uppercase;
  margin-right: 40px;
  min-width: 60px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.8s ease, padding 0.8s linear;
  color: ${({ selected, color }) => (selected ? color : "var(--text)")};
  background-color: ${({ selected }) =>
    selected ? "var(--hover-background)" : "transparent"};
  cursor: pointer;

  :hover {
    color: ${({ color }) => color};
    text-decoration: none;

    & ${ResumoIcon} {
      background: url("${ResumoHighlightSVG}");
    }

    & ${TechIcon} {
      background: url("${TechHighlightSVG}");
    }

    & ${MedIcon} {
      background: url("${MedHighlightSVG}");
    }

    & ${NutriIcon} {
      background: url("${NutriHighlightSVG}");
    }

    & ${TrainingIcon} {
      background: url("${TrainingHighlightSVG}");
    }

    & ${PsicoIcon} {
      background: url("${PsicoHighlightSVG}");
    }
  }
`;

export const MuscleAchesImage = styled.img.attrs({
  src: MuscleAchesSVG,
})`
  max-width: 80vw;
`;

export const MuscleImage = styled.img.attrs({
  src: MuscleSVG,
})`
  max-width: 100%;
`;

export const SaveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-heavy);
  font-size: 15px;
  background-color: var(--success);
  color: #fff;
  padding: 5px 24px;
  min-height: 50px;
  border-radius: 8px;
  text-transform: uppercase;

  :hover {
    background-color: var(--success-light);
  }
`;

export const ListContainer = styled.div`
  margin: 0 -10px;
  margin-top: 30px;
  max-height: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  :hover {
    background-color: var(--hover-background);
  }
`;

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: ${({ clientToggled }) => (clientToggled ? 430 : 80)}px;
  right: 0;
  height: 69px;
  background-color: var(--card-background);
  font-family: var(--font-heavy);
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 88px;
  transition: all 0.3s ease, padding 0.5s linear;
  transform: ${({ visible }) =>
    visible ? "translateY(0)" : "translateY(69px)"};
`;

export const EncontrosContainer = styled.div`
  position: relative;
  padding-top: ${({ toggled }) => (toggled ? 30 : 0)}px;
  background-color: var(--card-background);
  height: ${({ toggled }) => (toggled ? 114 : 0)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease, padding 0.5s linear;
  overflow-y: hidden;
`;

export const EncontroLine = styled.div`
  height: 5px;
  width: 68px;
  background-color: ${({ disabled }) =>
    disabled ? "var(--text-light)" : "var(--pink)"};
`;

export const Encontro = styled.button`
  font-family: var(--font-heavy);
  color: white;
  background-color: var(--pink);
  height: ${({ selected }) => (selected ? 50 : 38)}px;
  width: ${({ selected }) => (selected ? 50 : 38)}px;
  border-radius: ${({ selected }) => (selected ? 8 : 19)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease, padding 0.8s linear;

  :disabled {
    background-color: var(--text-light);
  }
`;

export const Title = styled.h1`
  font-family: var(--font-heavy);
  font-size: 14px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: var(--purple-dark);
`;

export const Subtitle = styled.h2`
  font-family: var(--font-medium);
  font-size: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);

  strong {
    font-family: var(--font-heavy);
  }
`;

export const SearchInput = styled.input`
  height: 47px;
  width: 100%;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-light);
  color: var(--text);
  background-color: var(--card-background);
  padding: 0 19px;

  ::placeholder {
    color: var(--text-light);
  }
`;

export const RemoveIcon = styled.img.attrs({
  src: RemoveSVG,
})`
  cursor: pointer;
`;
