const muscleAches = [
  "Não",
  "Lombar",
  "Torácica",
  "Cervical",
  "Cotovelo",
  "Tornozelo",
  "Ombro",
  "Tronco",
  "Joelho",
  "Outro",
];

export default muscleAches;
