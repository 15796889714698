
import moment from "moment";

const HOURS_OF_MEALS = [];
new Array(24).fill().forEach((_, index) => {
    HOURS_OF_MEALS.push(moment( {hour: index} ).format('HH:mm'));
    HOURS_OF_MEALS.push(moment({ hour: index, minute: 30 }).format('HH:mm'));
})

const TYPE_OF_MEALS = [
    'Café da manhã',
    'Colação',
    'Almoço',
    'Lanche da tarde',
    'Pré-treino',
    'Pós-treino',
    'Jantar',
    'Ceia',
]

export { HOURS_OF_MEALS, TYPE_OF_MEALS };